import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Toolbar: React.FC = ({ children }) => {
  const focusSearchBox = (e: KeyboardEvent) => {
    const filterBox = document.getElementById('filterBox');

    if (e.ctrlKey && e.key.toLowerCase() === 'f' && filterBox) {
      filterBox.focus();
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', focusSearchBox);
    return () => {
      document.removeEventListener('keydown', focusSearchBox);
    };
  }, []);

  const container = document.getElementById('toolbarControls');
  if (container) {
    return ReactDOM.createPortal(children, container);
  }
  return null;
};

export default Toolbar;
