import { Component } from 'react';
import Accordion from '../../controls/Accordion/Accordion';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { TeamDTO } from '../../../common/api/dtos/Team';
import { deleteTeam } from '../../../common/api/endpoints/team';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import TeamPlanRow from './TeamPlanRow';
import { WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  data: TeamDTO | null;
  fetchTeam?: () => Promise<void>;
  status: TRequestStatus;
}

interface State {
  deleteStatus: TRequestStatus;
  canceled: string;
}
export class TeamPlan extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      deleteStatus: 'idle',
      canceled: this.props.data?.subscription.cancelAt || '',
    };
  }

  handleSubmit = async () => {
    if (this.props.data) {
      try {
        this.setState({
          deleteStatus: 'loading',
        });

        await deleteTeam(this.props.data?.id);

        setTimeout(() => {
          this.props.fetchTeam && this.props.fetchTeam();

          this.setState({
            deleteStatus: 'success',
            canceled: 'canceled',
          });
        }, 300);
      } catch (err) {
        this.setState({
          deleteStatus: 'error',
        });
        console.error(err);
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="flex-row fill">
        <div className="column py-0">
          <Accordion
            accordionSlug="plan-and-billing"
            isOpen={true}
            iconClasses="far fa-coins icon"
            title={t('planBillingTitle')}
            subheading={t('manageSubscriptionSubheading')}
            disabled={true}
            softDisabled={true}
            hideArrow={true}
            loading={this.props.status === 'loading'}
          >
            <div className="accordion-row py-sm">
              <TeamPlanRow
                canceled={this.state.canceled}
                data={this.props.data}
                status={this.state.deleteStatus}
              />
            </div>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('teamPlan')(TeamPlan);
