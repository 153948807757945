import React, { PureComponent } from 'react';
import { CLEAR_EDITOR_COMMAND, LexicalEditor } from 'lexical';
import ConduitBridge from '../../../controls/LexicalControl/themes/LexicalTheme';
import LexicalControl from '../../../controls/LexicalControl/LexicalControl';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import CustomPlugins from '../../../controls/LexicalControl/plugins/CustomPlugins';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import Button from '../../../controls/Button/Button';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { unite } from '../../../../common/helpers/unite';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  disabled?: boolean;
  boardCard: BoardCard;
  newCard: boolean;
  onDescriptionChange: (value: string) => void;
  descriptionEditor: LexicalEditor | null;
  setDescriptionEditor: (editor: LexicalEditor | null) => void;
  showUpdatingBtns: boolean;
  setShowUpdatingBtns: (value: boolean) => void;
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];

  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface State {
  editor: LexicalEditor | null;
  status: TRequestStatus;
  showLexical: boolean;
  updateDescription: boolean;
  cancelDescription: boolean;
}

class AddCardDescription extends PureComponent<Props, State> {
  initialEditorConfig: InitialConfigType;
  lexicalRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.lexicalRef = React.createRef();
    this.initialEditorConfig = {
      // The editor theme
      editorState: () => null,
      editable: false,
      theme: ConduitBridge,
      namespace: 'UserComment',
      // Handling of errors during update
      onError(error: Error) {
        throw error;
      },
      // Any custom nodes go here
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
      ],
    };
    this.state = {
      editor: null,
      status: 'idle',
      showLexical: false,
      updateDescription: false,
      cancelDescription: false,
    };
  }

  componentDidUpdate(): void {
    if (this.props.newCard) {
      this.setState({
        showLexical: false,
      });
    }
  }

  setShowLexical = (value: boolean) => {
    this.setState(
      {
        showLexical: value,
      },
      () => {
        if (value === false) {
          this.state.editor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
          this.setState({
            status: 'idle',
          });
        }
      },
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="flex-row fill">
          <div className="column pb-xs">
            {this.props.boardCard.description.length === 0 &&
            !this.state.showLexical ? (
              <div
                className={unite(
                  'lexical-button-trigger',
                  `add-description-${this.props.boardCard.id}`,
                  { hidden: this.state.showLexical },
                )}
                style={{
                  display: `${this.state.showLexical ? 'none' : 'block'}`,
                }}
              >
                {!this.props.disabled ? (
                  <>
                    <Button
                      className="secondary-button fill text-left flex-h-start"
                      disabled={this.props.disabled}
                      onClick={() => {
                        this.setShowLexical(true);
                        this.props.setShowUpdatingBtns(true);
                      }}
                    >
                      <span className="faint-text text-sm py-2xs">
                        {t('typeDescription')}
                      </span>
                    </Button>
                  </>
                ) : (
                  <>
                    <div className="card opaque">
                      <span className="text-xs faint-text">
                        {t('noDescription')}
                      </span>
                    </div>
                  </>
                )}
              </div>
            ) : null}
            {this.props.boardCard.description.length > 0 &&
            !this.state.showLexical ? (
              <div>
                <div className="pb-xs">
                  <LexicalComposer initialConfig={this.initialEditorConfig}>
                    <div
                      className="lexical-control card opaque"
                      style={{
                        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='100'%3E%3Crect width='100%25' height='100%25' fill='none'/%3E%3Ctext x='50%25' y='25%25' font-size='12' font-weight='700' font-family='Arial,Helvetica' fill='white' opacity='.1' text-anchor='middle' dominant-baseline='middle' transform='rotate(-45, 100, 100)'%3E⚠️LEGACY%3C/text%3E%3C/svg%3E")`,
                      }}
                    >
                      <div className="editor-inner">
                        <RichTextPlugin
                          contentEditable={
                            <ContentEditable className="editor-input soft-disabled no-border" />
                          }
                          placeholder={
                            <div className="editor-placeholder">
                              Description
                            </div>
                          }
                          ErrorBoundary={LexicalErrorBoundary}
                        />
                        <CustomPlugins
                          card={this.props.boardCard}
                          value={this.props.boardCard.description}
                          updateTrigger={this.props.boardCard.description}
                        />
                      </div>
                    </div>
                  </LexicalComposer>
                </div>
                {!this.props.disabled ? (
                  <Button
                    className="secondary-button lexical-button-trigger"
                    onClick={() => {
                      this.setShowLexical(true);
                      this.props.setShowUpdatingBtns(true);
                    }}
                  >
                    <span className="text">{t('edit')}</span>
                  </Button>
                ) : null}
              </div>
            ) : null}
            <div className={`${this.state.showLexical ? '' : 'hidden'}`}>
              <LexicalControl
                disabled={Boolean(this.props.disabled)}
                placeholder={t('typeDescription')}
                card={this.props.boardCard}
                handleChange={this.props.onDescriptionChange}
                namespace={`description-${this.props.boardCard.id}`}
                editor={this.props.descriptionEditor}
                setEditor={this.props.setDescriptionEditor}
                value={this.props.boardCard.description}
                updateTrigger={this.props.boardCard.description}
                updateDescription={this.state.updateDescription}
                cancelDescription={this.state.cancelDescription}
                setShowUpdatingBtns={this.props.setShowUpdatingBtns}
                showLexical={this.state.showLexical}
                setShowLexical={this.setShowLexical}
                buttonTriggered={true}
              />
              <div
                className={`flex-row flex-v-center ${
                  this.props.showUpdatingBtns ? 'my-xs' : null
                }`}
              >
                {this.props.showUpdatingBtns && !this.props.disabled ? (
                  <>
                    <Button
                      className="primary-button mx-xs"
                      onClick={() => {
                        this.setState(
                          {
                            updateDescription: true,
                          },
                          () => {
                            this.setState({
                              updateDescription: false,
                            });
                          },
                        );
                        this.props.setShowUpdatingBtns(false);
                        this.setShowLexical(false);
                      }}
                    >
                      <span className="text">{t('updateDescription')}</span>
                    </Button>
                    <Button
                      className="secondary-button mr-xs"
                      onClick={() => {
                        this.setState(
                          {
                            cancelDescription: true,
                          },
                          () => {
                            this.setState({
                              cancelDescription: false,
                            });
                          },
                        );
                        this.props.setShowUpdatingBtns(false);
                        this.setShowLexical(false);
                      }}
                    >
                      <span className="text">{t('cancel')}</span>
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('addCardDescription')(AddCardDescription);
