import React, { ChangeEvent } from 'react';
import Button from '../../../controls/Button/Button';
import ContextMenu from '../../../controls/ContextMenu/ContextMenu';
import MemberMenu from '../../../menus/Member/MemberMenu';
import TextArea from '../../../controls/TextArea/TextArea';
import { addCard } from '../../../../common/api/endpoints/card';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import { AddCardDTO } from '../../../../common/api/dtos/Card';
import { TContextMenu } from '../../../../common/types/ContextMenu';
import PriorityContext from '../../../menus/Priority/PriorityContext';
import TagContext from '../../../menus/Tag/TagContext';
import AppContext, {
  IAppContext,
} from '../../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../../common/contexts/NotificationsContext';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import BoardContext from '../../../../common/contexts/BoardContext';
import { withContextAdapters } from '../../ContextAdapter/withContextAdapter';
import RequestStatus from '../../RequestStatus/RequestStatus';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import selectTutorial from '../../../../common/helpers/selectTutorial';
import { IClientData } from '../../../../common/interfaces/ClientData';
import { UserDTO } from '../../../../common/api/dtos/User';
import { updateTutorialStep } from '../../../../common/helpers/tutorialHelper';
import {
  IBoardContext,
  ICard,
} from '../../../../common/interfaces/BoardContext';

const contextMenuStyles: React.CSSProperties = {
  width: '290.5px',
};

interface FormData {
  cardTitle: string;
}

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  loggedUser?: UserDTO;
  updateClientData: (clientData: Partial<IClientData>) => void;
}
interface BoardContextProps {
  members: (MemberDTO | InviteeDTO)[];
  tags: TagDTO[];
  priorities: PriorityDTO[];
}
interface ExternalProps {
  addCardForm: (operation: 'open' | 'close') => void;
  boardColumn: ColumnDTO;
  setNewCard: (columnId: string, card: ICard) => void;
}
interface Props
  extends AppContextProps,
    BoardContextProps,
    ExternalProps,
    WithTranslation {}

interface State {
  formData: FormData;
  showSecondMenu: boolean;
  selectedPriority: PriorityDTO | null;
  selectedMembers: string[];
  selectedTags: string[];
  dueDate: Date;
  selectedContext: TContextMenu;
  status: TRequestStatus;
}

class BoardCardPlaceholder extends React.Component<Props, State> {
  cardRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.cardRef = React.createRef();

    this.state = {
      selectedPriority: null,
      formData: {
        cardTitle: '',
      },
      showSecondMenu: false,
      selectedMembers: [],
      selectedTags: [],
      dueDate: new Date(),
      selectedContext: 'priorities',
      status: 'idle',
    };
  }

  componentDidMount() {
    // stop propagation of the horizontal drag
    const textArea = this.cardRef.current?.getElementsByTagName('textarea')[0];
    if (textArea) {
      textArea.addEventListener('mousedown', (e) => {
        if (document.activeElement === textArea) {
          e.stopPropagation();
        }
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.priorities !== this.props.priorities) {
      const updatedPriority = this.props.priorities.find(
        (priority: PriorityDTO) =>
          priority.id === this.state.selectedPriority?.id,
      );
      if (updatedPriority) {
        this.setSelectedPriority(updatedPriority);
      }
    }

    if (prevProps.tags !== this.props.tags) {
      const updatedSelectedTags = this.state.selectedTags.filter((tagId) => {
        return this.props.tags.some((tag: TagDTO) => tag.id === tagId);
      });

      this.setSelectedTags(updatedSelectedTags);
    }

    if (prevProps.members !== this.props.members) {
      const updatedSelectedMembers = this.state.selectedMembers.filter(
        (memberId) => {
          return this.props.members.some(
            (member: MemberDTO | InviteeDTO) => member.id === memberId,
          );
        },
      );

      this.setSelectedMembers(updatedSelectedMembers);
    }
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      },
    });
  }

  setCardTitle = (ev: ChangeEvent<HTMLTextAreaElement>) =>
    this.updateForm('cardTitle', ev.target.value);

  setSelectedPriority = (priority: PriorityDTO | null): void => {
    this.setState({
      selectedPriority: priority,
    });
  };

  setSelectedMembers = (members: string[]) => {
    this.setState({
      selectedMembers: members,
    });
  };

  setSelectedTags = (tags: string[]) => {
    this.setState({
      selectedTags: tags,
    });
  };

  tutorialAction_createCard = async () => {
    const { loggedUser } = this.props;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      const stepId =
        userType === 'solo'
          ? 'add_cards'
          : userType === 'teamOwner'
            ? 'create_cards'
            : 'create_card';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  handleAddCard = async () => {
    this.setState({
      status: 'loading',
    });

    const addCardData: AddCardDTO = {
      title: this.state.formData.cardTitle,
      tagIds: this.state.selectedTags,
      priorityId: this.state.selectedPriority
        ? this.state.selectedPriority.id
        : null,
      assigneeIds: this.state.selectedMembers,
      description: '',
    };

    try {
      const card = await addCard(this.props.boardColumn.id, addCardData);
      this.props.setNewCard(this.props.boardColumn.id, card as ICard);
      this.props.addCardForm('close');

      this.setState({
        status: 'success',
      });

      // Update progress based on tutorial type
      this.tutorialAction_createCard();
    } catch (error) {
      console.debug(error);
      showErrorNotifications(error, this.props.setMessages);
    } finally {
      this.setState({
        status: 'idle',
      });
    }
  };

  handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      if (
        this.state.status !== 'loading' &&
        this.state.formData.cardTitle.length > 0
      ) {
        this.handleAddCard();
      }
    }
  };

  setSelectedContext = (context: TContextMenu) => {
    this.setState({
      selectedContext: context,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="form-group tight">
        <div
          className="card add-card-form"
          ref={this.cardRef}
        >
          <TextArea
            id="cardTitle"
            name="cardTitle"
            value={this.state.formData.cardTitle}
            onChange={this.setCardTitle}
            noMargin={true}
            autofocus={true}
            onKeyDown={this.handleKeyDown}
            maxLength={255}
            showCounter={true}
            disabled={this.state.status === 'loading'}
            noResize
          />
          <div className="flex-row fill">
            <div className="column pb-2xs">
              <ul className="icon-list-component">
                <li>
                  <PriorityContext
                    disabled={this.state.status === 'loading'}
                    contextMenuClassName="align-v-start"
                    handleSelectPriority={this.setSelectedPriority}
                    selectedPriority={this.state.selectedPriority}
                    triggerClassDefault="secondary-button"
                  />
                </li>
                <li>
                  <ContextMenu
                    dept={0}
                    contextMenuClassName="align-v-start"
                    triggerClassDefault="secondary-button"
                    contextMenuStyles={contextMenuStyles}
                    title={t('assignUsers')}
                    isDisabled={this.state.status === 'loading'}
                    triggerContent={
                      <>
                        <span className="text">
                          {this.state.selectedMembers.length}
                        </span>
                        <span className="fas fa-user-plus icon"></span>
                      </>
                    }
                  >
                    <MemberMenu
                      manage={false}
                      selected={this.state.selectedMembers}
                      setSelectedMembers={this.setSelectedMembers}
                    />
                  </ContextMenu>
                </li>
                <li>
                  <TagContext
                    disabled={this.state.status === 'loading'}
                    selectedTags={this.state.selectedTags}
                    setSelectedTags={this.setSelectedTags}
                    amount={this.state.selectedTags.length}
                    triggerClassDefault="secondary-button"
                    triggerClassActive="secondary-button"
                    contextMenuClassName="align-v-start"
                    action="create"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-row fill">
            <div className="column pb-0">
              <ul className="control-list-component">
                <li>
                  <Button
                    className="primary-button"
                    onClick={this.handleAddCard}
                    disabled={
                      this.state.status === 'loading' ||
                      this.state.formData.cardTitle.trim().length === 0
                    }
                  >
                    <RequestStatus status={this.state.status} />
                    <span className="text">{t('submit')}</span>
                  </Button>
                </li>
                <li>
                  <Button
                    className="ghost-button"
                    onClick={() => this.props.addCardForm('close')}
                    disabled={this.state.status === 'loading'}
                  >
                    <span className="fas fa-times"></span>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      updateClientData:
        ctx.updateClientData ||
        (() => {
          return false;
        }),
      setMessages:
        ctx.notifications.setMessages ||
        (() => {
          return false;
        }),
      loggedUser: ctx.loggedUser,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      priorities: ctx.board.priorities,
      tags: ctx.board.tags,
      members: ctx.board.members,
    };
  },
};
export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('boardCardPlaceholder')(BoardCardPlaceholder),
  AppContextAdapter,
  BoardContextAdapter,
);
