import React from 'react';
import BoardContext from '../../../../../../common/contexts/BoardContext';
import { setCardTag } from '../../../../../../common/api/endpoints/card';
import { showErrorNotifications } from '../../../../../../common/helpers/showNotifications';
import { withContextAdapters } from '../../../../ContextAdapter/withContextAdapter';
import AppContext, {
  IAppContext,
} from '../../../../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../../../../common/contexts/NotificationsContext';
import { withStyledTranslation } from '../../../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import { IBoardContext } from '../../../../../../common/interfaces/BoardContext';

interface ExternalProps {
  cardId: string;
  tagId: string;
  tagName?: string;
}
interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  unassignTag: (cardId: string, tagId: string) => void;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

class RemoveTagMenu extends React.Component<Props> {
  unassignTag = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await setCardTag(String(this.props.cardId), this.props.tagId, 'untag');
      this.props.unassignTag(this.props.cardId, this.props.tagId);
    } catch (err) {
      console.debug(err);
      showErrorNotifications(err, this.props.setMessages);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <li>
        <button
          className="ghost-button"
          onClick={this.unassignTag}
        >
          {t('remove')} {this.props.tagName ? `"${this.props.tagName}"` : 'tag'}
        </button>
      </li>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages:
        ctx.notifications.setMessages ??
        (() => {
          return false;
        }),
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      unassignTag: ctx.unassignTag,
    };
  },
};
export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('removeTagMenu')(RemoveTagMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
