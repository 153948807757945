//Touched by Copilot
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';

interface VideoPlayerProps extends WithTranslation {
  src: string; // Image source URL
  alt?: string; // Alternative text for the image
  goToPrevious: () => void;
  goToNext: () => void;
  introDirection?: 'prev' | 'next' | null;
}

class VideoPlayer extends React.Component<VideoPlayerProps> {
  private videoRef = React.createRef<HTMLVideoElement>();
  private fullScreenRef = React.createRef<HTMLDivElement>();
  private containerRef = React.createRef<HTMLDivElement>();
  private animationFrameId: number | null = null;
  private containerResizeObserver: ResizeObserver | null = null;

  constructor(props: VideoPlayerProps) {
    super(props);
  }

  componentDidMount(): void {
    window.addEventListener('keydown', this.handleSpacePress);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleSpacePress); // Add keydown listener
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    if (this.containerResizeObserver && this.containerRef.current) {
      this.containerResizeObserver.unobserve(this.containerRef.current);
    }
  }

  private handleSpacePress = (event: KeyboardEvent): void => {
    if (event.code === 'Space') {
      event.preventDefault(); // Prevent the default action of the space bar
    }
  };

  private onWheel = (event: React.WheelEvent<HTMLDivElement>): void => {
    if (event.shiftKey) {
      event.preventDefault(); // Prevent zooming or any other default action

      // Determine the scroll direction
      const { deltaY } = event;
      if (deltaY < 0) {
        // Scroll up, go to previous
        this.props.goToPrevious();
      } else if (deltaY > 0) {
        // Scroll down, go to next
        this.props.goToNext();
      }
      return; // Exit the method early since we handled the shift-scroll case
    }
    event.preventDefault();
  };

  private adjustForNewScaleBoundaries = (
    scale: number,
    left: number,
    top: number,
  ) => {
    const image = this.videoRef.current;
    const container = this.containerRef.current;
    if (!image || !container) return { left, top };

    // Assuming container and image dimensions are available
    const containerRect = container.getBoundingClientRect();
    const imageWidth = image.width * scale;
    const imageHeight = image.height * scale;

    // Adjust left and top to ensure the image stays within bounds
    const maxLeft = 0;
    const maxTop = 0;
    const minLeft = containerRect.width - imageWidth;
    const minTop = containerRect.height - imageHeight;

    const adjustedLeft = Math.max(minLeft, Math.min(left, maxLeft));
    const adjustedTop = Math.max(minTop, Math.min(top, maxTop));

    return {
      left:
        imageWidth > containerRect.width
          ? adjustedLeft
          : (containerRect.width - imageWidth) / 2,
      top:
        imageHeight > containerRect.height
          ? adjustedTop
          : (containerRect.height - imageHeight) / 2,
    };
  };

  private introAnimation = (): string => {
    if (this.props.introDirection === null) {
      return '';
    }
    return this.props.introDirection === 'prev'
      ? 'reveal-right-1'
      : 'reveal-left-1';
  };

  toggleFullscreen = () => {
    // Assuming containerRef is the ref to the element you want in fullscreen
    const element = this.fullScreenRef.current;

    if (!element) return; // Guard clause if the element or ref is not available

    if (!document.fullscreenElement) {
      // Enter fullscreen
      if (element.requestFullscreen) {
        element.requestFullscreen().catch((err) => {
          console.error(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
          );
        });
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((err) => {
          console.error(
            `Error attempting to disable fullscreen mode: ${err.message} (${err.name})`,
          );
        });
      }
    }
  };

  isFullscreen = (): boolean => {
    return document.fullscreenElement != null;
  };

  render() {
    const { src } = this.props;

    const containerStyle: React.CSSProperties = {
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      height: '100%',
    };

    const videoStyle: React.CSSProperties = {
      cursor: 'grab',
      position: 'absolute',
      transform: `translate(-50%, -50%)`,
      left: '50%',
      top: '50%',
      width: 'auto',
      height: 'auto',
      maxWidth: 'calc(100% - 16px)',
      maxHeight: 'calc(100% - 16px)',
      zIndex: 1,
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
      border: '1px solid rgba(100, 100, 100, 0.33)',
      backgroundColor: 'black',
    };

    return (
      <div
        className="showcased-image-wrapper"
        ref={this.fullScreenRef}
      >
        <div
          ref={this.containerRef}
          className={`${this.introAnimation()}`}
          style={containerStyle}
          onWheel={this.onWheel}
        >
          <video
            ref={this.videoRef}
            controls
            src={src}
            style={videoStyle}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('imageZoomer')(VideoPlayer);
