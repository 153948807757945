import React, { Component, createRef, ReactNode } from 'react';

interface StepperProps {
  steps: { [key: string]: ReactNode };
  initialStep?: string;
  currentStep: string;
  reverseFirstAnimation?: boolean;
}

interface StepperState {
  currentStep: string;
  prevStep: string;
  height: number | undefined;
}

class Stepper extends Component<StepperProps, StepperState> {
  contentRef: React.RefObject<HTMLDivElement>;
  resizeObserver: ResizeObserver;

  constructor(props: StepperProps) {
    super(props);
    this.state = {
      currentStep: props.initialStep || Object.keys(props.steps)[0],
      prevStep: props.initialStep || Object.keys(props.steps)[0],
      height: undefined,
    };
    this.contentRef = createRef();
    this.resizeObserver = new ResizeObserver(() => false);
  }

  componentDidMount() {
    this.updateContentHeight();
    this.setupResizeObserver();
  }

  componentWillUnmount() {
    this.cleanupResizeObserver();
  }

  componentDidUpdate(prevProps: StepperProps, prevState: StepperState) {
    if (prevState.currentStep !== this.state.currentStep) {
      this.setState({ prevStep: prevState.currentStep });
      this.updateContentHeight();
    }

    if (prevProps.currentStep !== this.props.currentStep) {
      this.setState({ currentStep: this.props.currentStep });
    }
  }

  setupResizeObserver = () => {
    this.resizeObserver = new ResizeObserver(this.updateContentHeight);
    if (this.contentRef.current) {
      this.resizeObserver.observe(this.contentRef.current);
    }
  };

  cleanupResizeObserver = () => {
    if (this.resizeObserver && this.contentRef.current) {
      this.resizeObserver.unobserve(this.contentRef.current);
    }
  };

  updateContentHeight = () => {
    if (this.contentRef.current) {
      const element = this.contentRef.current;
      const parentElement = element.parentElement;
      if (parentElement) {
        let totalHeight = element.clientHeight;
        const parentComputedStyle = window.getComputedStyle(parentElement);
        totalHeight +=
          parseInt(parentComputedStyle.borderTopWidth, 10) +
          parseInt(parentComputedStyle.borderBottomWidth, 10);
        totalHeight +=
          parseInt(parentComputedStyle.paddingTop, 10) +
          parseInt(parentComputedStyle.paddingBottom, 10);
        this.setState({ height: totalHeight });
      }
    }
  };

  render() {
    const { steps } = this.props;
    const { currentStep, prevStep, height } = this.state;
    let animationClass =
      Object.keys(steps).indexOf(currentStep) >
      Object.keys(steps).indexOf(prevStep)
        ? 'slide-to-left'
        : 'slide-to-right';
    if (
      this.props.reverseFirstAnimation &&
      Object.keys(steps).indexOf(currentStep) == 0
    ) {
      animationClass = 'slide-to-left';
    }

    return (
      <div className="flex-row fill">
        <div className="column">
          <div
            className="special-card fade-in-1 flex-v-center oobe-content"
            style={{ height }}
          >
            <div
              className="fill"
              style={{ overflow: 'hidden' }}
              ref={this.contentRef}
            >
              <div
                key={currentStep}
                className={'stepper ' + animationClass}
              >
                {steps[currentStep]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stepper;
