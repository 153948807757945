import { dynamicFavicon } from './dynamicFavicon';

export function getBoardColor(id: string) {
  return localStorage.getItem(`board-${id}-color`);
}

export function setBoardFavicon(id: string) {
  const color = String(getBoardColor(id));
  const favicon: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  if (favicon?.href) {
    favicon.href = dynamicFavicon(color);
  }
}

export function setLocalStorageBoardColor(id: string, color: string) {
  localStorage.setItem(`board-${id}-color`, color);
  setBoardFavicon(id);
}
