import { Component } from 'react';
import PageMessage from '../PageMessage/PageMessage';
import { getTeam } from '../../../common/api/endpoints/team';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { TeamDTO } from '../../../common/api/dtos/Team';
import { TRequestStatus } from '../../../common/types/RequestStatus';

interface RouteParams {
  id: string;
}

interface State {
  status: TRequestStatus;
  team: TeamDTO | null;
  userInvited: boolean;
}

export type Props = RouteComponentProps<RouteParams>;

export default class TeamInvitation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      status: 'idle',
      team: null,
      userInvited: false,
    };
  }

  componentDidMount(): void {
    this.fetchTeam();
  }

  fetchTeam = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      const data = await getTeam(this.props.match.params.id);

      this.setState({
        status: 'success',
        team: data,
      });
    } catch (err) {
      const errors = Array.isArray(err) ? err : [err];
      console.debug(errors);
    }
  };

  render() {
    if (!this.state.userInvited) {
      return (
        <PageMessage>
          <h1 className="primary-title normalcase pb-xs">
            Something went wrong
          </h1>
          <br />
          <p>An unknown error has occurred.</p>
          <ul className="control-list-component flex-h-center">
            <li>
              <NavLink
                to="/"
                className={'secondary-button'}
              >
                Return to homepage
              </NavLink>
            </li>
          </ul>
        </PageMessage>
      );
    }
  }
}
