import React, { ChangeEvent, Component } from 'react';
import AppContext from '../../../common/contexts/AppContext';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import TextBox from '../../controls/TextBox/TextBox';
import RequestStatus from '../../partials/RequestStatus/RequestStatus';
import Joi from 'joi';
import { processJoiError } from '../../../common/helpers/processJoiError';
import Button from '../../controls/Button/Button';
import { FormErrorMsgs } from '../../../common/configs/FormErrors';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import errorKeyFormatter from '../../../common/helpers/errorKeyFormatter';
import { ErrorNotificationPayload } from '../../../common/helpers/errorNotificationPayload';

interface FormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface RequestPayload {
  statusCode: number;
  message: string;
}

interface State {
  formData: FormData;
  formStatus: TRequestStatus;
  invalidPasswordMatch: boolean;
  formPayload: RequestPayload;
  serverErrors: string[];
  formErrors: FormErrors;
}

interface Props extends WithTranslation {
  editUserId?: number;
  enforced?: boolean;
  callback?: () => void;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

class PasswordForm extends Component<Props, State> {
  formSchema = Joi.object({
    currentPassword: Joi.string().required().messages(FormErrorMsgs.string),
    newPassword: Joi.string()
      .required()
      .pattern(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~])[A-Za-z\d/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~]{8,}/,
      )
      .invalid(Joi.ref('currentPassword'))
      .messages({
        ...FormErrorMsgs.string,
        'any.invalid':
          'New password cannot be the same as the current password.',
      }),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref('newPassword'))
      .messages(FormErrorMsgs.confirmPassword),
  });
  constructor(props: Props) {
    super(props);
    this.state = {
      formData: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      invalidPasswordMatch: false,
      formStatus: 'idle',
      formPayload: {
        statusCode: -1,
        message: '',
      },
      serverErrors: [],
      formErrors: {},
    };
  }

  updateEntity = async (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    ev.preventDefault();

    this.setState({
      formStatus: 'loading',
      serverErrors: [],
    });

    try {
      this.setState({
        formStatus: 'success',
        formData: {
          confirmPassword: '',
          currentPassword: '',
          newPassword: '',
        },
      });
    } catch (err) {
      const serverErrors = this.state.serverErrors.slice();
      serverErrors.push(
        errorKeyFormatter((err as ErrorNotificationPayload[])[0]),
      );
      this.setState({
        formStatus: 'error',
        serverErrors,
      });
    }
  };

  handleSubmit = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.updateEntity(ev);
    }
  };

  setInvalidPasswordMatch = () => {
    if (
      this.state.formData.newPassword === this.state.formData.confirmPassword
    ) {
      this.setState({
        invalidPasswordMatch: false,
      });
    } else {
      this.setState({
        invalidPasswordMatch: true,
      });
    }
  };

  validateFormField = <K extends keyof FormData>(field: K) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      for (const error of result.error.details) {
        const key = error.path[0];

        // ignore errors for other fields; this approach is chosen over extracting a sub-schema
        // because we use a joi ref for the confirm password
        if (key === field) {
          errorMessage = error.message;
        }
      }
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState(
      (prevState) => {
        return {
          formErrors: {
            ...prevState.formErrors,
            [field]: value,
          },
        };
      },
      () => {
        this.setInvalidPasswordMatch();
      },
    );
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          [field]: value,
        },
      },
      () => {
        this.setInvalidPasswordMatch();
      },
    );

    this.setState({
      formStatus: 'idle',
    });
  }

  setCurrentPassword = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('currentPassword', ev.target.value);
  setNewPassword = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('newPassword', ev.target.value);
  setConfirmPassword = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('confirmPassword', ev.target.value);

  render() {
    const { t } = this.props;

    return (
      <form autoComplete="off">
        <div className="flex-row">
          <div className="column large">
            {this.props.enforced && (
              <TextBox
                label={t('currentPasswordLabel')}
                type="password"
                name="currentPassword"
                id="currentPassword"
                value={this.state.formData.currentPassword}
                onChange={this.setCurrentPassword}
                autoComplete="new-password"
                br={true}
                required={true}
                disabled={this.state.formStatus == 'loading'}
                onBlur={(ev) =>
                  this.validateFormField(ev.target.name as keyof FormData)
                }
                error={this.state.formErrors.currentPassword}
              />
            )}
            <TextBox
              label={t('newPasswordLabel')}
              type="password"
              name="newPassword"
              id="newPassword"
              value={this.state.formData.newPassword}
              onChange={this.setNewPassword}
              autoComplete="new-password"
              br={true}
              required={true}
              disabled={this.state.formStatus == 'loading'}
              onBlur={(ev) =>
                this.validateFormField(ev.target.name as keyof FormData)
              }
              error={this.state.formErrors.newPassword}
            />
            <TextBox
              label={t('confirmPasswordLabel')}
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              onChange={this.setConfirmPassword}
              autoComplete="new-password"
              value={this.state.formData.confirmPassword}
              br={true}
              required={true}
              disabled={this.state.formStatus == 'loading'}
              onBlur={(ev) =>
                this.validateFormField(ev.target.name as keyof FormData)
              }
              error={this.state.formErrors.confirmPassword}
            />
            <Button
              className="primary-button"
              disabled={this.state.formStatus === 'loading'}
              onClick={(
                ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
              ) => {
                this.handleSubmit(ev);
              }}
            >
              <RequestStatus status={this.state.formStatus} />
              <span className="text">{t('updatePasswordButton')}</span>
            </Button>
            {this.state.formStatus == 'success' && (
              <div className="mt-sm reveal-up-1">
                <p className="positive-text">
                  <small className="fas fa-check-circle"></small>
                  <span>{t('passwordUpdateSuccess')}</span>
                </p>
              </div>
            )}
            {this.state.serverErrors.length > 0 && (
              <ul className="error-list light">
                {this.state.serverErrors.map((err: string) => {
                  return (
                    <li
                      key={err}
                      className="flex-v-center"
                    >
                      <span className="text">{t('app:' + err + '.pass')}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default withStyledTranslation('passwordForm')(PasswordForm);

PasswordForm.contextType = AppContext;
