import requests from './baseRequest';

interface CheckoutSessionParams {
  name: string;
  seats: number;
  trial: boolean;
}

export async function createCheckoutSession(
  params: CheckoutSessionParams,
): Promise<{ clientSecret: string }> {
  const result = await requests.post('payment/checkout/team', params);
  return await result.json();
}
