import { ChangeEvent, Component, FormEvent } from 'react';
import AppContext from '../../../../common/contexts/AppContext';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import TextBox from '../../../controls/TextBox/TextBox';
import Joi from 'joi';
import Button from '../../../controls/Button/Button';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import NumberControl from '../../../controls/NumberControl/NumberControl';
import Breadcrumb from '../../../partials/Breadcrumb/Breadcrumb';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { createCheckoutSession } from '../../../../common/api/endpoints/checkout';
import ElementScrollbar from '../../../partials/Scrollbars/ElementScrollbar';
import MessageBar from '../../../controls/MessageBar/MessageBar';

interface FormData {
  name: string;
  seats: number;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

type InputValue = ChangeEvent<HTMLInputElement> | number;

type Props = RouteComponentProps;

interface State {
  formStatus: TRequestStatus;
  formData: FormData;
  formErrors: FormErrors;
  stripePromise: Promise<Stripe | null> | null;
}

class TeamAdd extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string()
      .pattern(/^[a-zA-Z0-9 ]+$/)
      .required()
      .trim(true)
      .messages({
        'string.pattern.base': FormErrorMsgs.teamName['string.pattern.base'],
        'string.empty': FormErrorMsgs.string['string.empty'],
        'any.required': FormErrorMsgs.string['any.required'],
      }),
    seats: Joi.number()
      .required()
      .min(2)
      .max(99)
      .messages(FormErrorMsgs.number),
  });

  constructor(props: Props) {
    super(props);

    this.state = {
      formStatus: 'idle',
      formData: {
        name: '',
        seats: 2,
      },
      formErrors: {},
      stripePromise: null,
    };
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          [field]: value,
        },
      },
      () => {
        this.validateFormField(field);
      },
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('name', ev.target.value);

  setSeats = (value: InputValue) => {
    const seatsValue =
      typeof value === 'number' ? value : Number(value.target.value);
    this.updateForm('seats', seatsValue);
  };

  fetchClientSecret = () => {
    const { name, seats } = this.state.formData;
    const payload = {
      name,
      seats,
      trial: false,
    };

    return createCheckoutSession(payload).then((data) => {
      console.log('Checkout session response:', data);
      return data.clientSecret;
    });
  };

  handleCheckoutClick = () => {
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        stripePromise: loadStripe(
          'pk_test_51OZWwyG9K8OEhwM3whHM3pr3BPC1HFhKdhpHOyaaN70NWlo2KyzNO14tl2aUnNi7yCwgsQb5Fs8tP9qWijO4FnKP003DymCJrr',
        ),
      });
    }
  };

  handleSubmit = async (e: FormEvent) => {
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      try {
        this.setState({
          formStatus: 'success',
        });
      } catch (err) {
        this.setState(
          {
            formStatus: 'error',
          },
          () => {
            showErrorNotifications(err, this.context.notifications.setMessages);
          },
        );
      }
    } else {
      e.preventDefault();
    }
  };

  renderSuccess = () => {
    return (
      <MessageBar
        type="success"
        icon="fal fa-check-circle"
      >
        <p>
          {' '}
          Your team has been created successfully. You can now invite your team
          members to join, create your first board or create another team.
        </p>
        <ul className="control-list-component">
          <li>
            <Button
              className="primary-button"
              onClick={() => this.props.history.push('/team')}
            >
              <span className="fas fa-users icon"></span>
              <span className="text">Manage Team</span>
            </Button>
          </li>
          <li>
            <Button
              className="secondary-button translucent"
              onClick={() => this.setState({ formStatus: 'idle' })}
            >
              <span className="fas fa-plus icon"></span>
              <span className="text">Create Another Team</span>
            </Button>
          </li>
          <li>
            <Button
              className="secondary-button translucent"
              onClick={() => this.props.history.push('/')}
            >
              <span className="fas fa-layer-plus icon"></span>
              <span className="text">Create Board</span>
            </Button>
          </li>
        </ul>
      </MessageBar>
    );
  };

  render() {
    const options = {
      fetchClientSecret: this.fetchClientSecret,
      onComplete: () =>
        this.setState({ stripePromise: null, formStatus: 'success' }),
    };

    return (
      <div className="flex-row pt-0">
        <div className="column py-0 small">
          <div style={{ position: 'sticky', top: '46px' }}>
            <div className="flex-row fill py-0">
              <div className="column navigation-component px-0">
                <ul className="nav-list">
                  <li className="nav-li">
                    <NavLink
                      to="/board-add"
                      className="nav-link"
                    >
                      <div className="content-wrapper py-3xs">
                        <span className="fal fa-layer-plus icon"></span>
                        <span className="link-text">Create board</span>
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-li">
                    <NavLink
                      to="/team-add"
                      className="nav-link"
                    >
                      <div className="content-wrapper py-3xs">
                        <span className="fal fa-plus-square icon"></span>
                        <span className="link-text">Create team</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="column py-0 largest">
          <div
            className="fill"
            style={{
              maxWidth: '1024px',
              width: '100%',
            }}
          >
            <div className="flex-row fill">
              <div className="column pb-0">
                <Breadcrumb
                  items={[
                    { label: 'Home', to: '/' },
                    {
                      label: (
                        <>
                          Creating team{' '}
                          <strong>{this.state.formData.name || ''}</strong>
                        </>
                      ),
                      isCurrent: true,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex-row fill">
              <div className="column pb-xs">
                <div className="card translucent mb-sm">
                  <div className="flex-row">
                    <div className="column">
                      {this.state.formStatus === 'success' ? (
                        this.renderSuccess()
                      ) : (
                        <form
                          onSubmit={(e) => this.handleSubmit(e)}
                          autoComplete="off"
                          noValidate={true}
                          method="POST"
                          action={`${process.env.REACT_APP_API_BASE_URL}/team`}
                        >
                          <TextBox
                            label="Name"
                            type="text"
                            name="name"
                            id="name"
                            maxLength={255}
                            disabled={
                              this.state.formStatus === 'loading' ||
                              this.state.stripePromise !== null
                            }
                            onChange={this.setName}
                            value={this.state.formData.name}
                            required={true}
                            br={true}
                            placeholder="Choose a team name..."
                            onBlur={(ev) =>
                              this.validateFormField(
                                ev.target.name as keyof FormData,
                              )
                            }
                            error={this.state.formErrors.name}
                          />
                          <NumberControl
                            label="Seats (min. 2)"
                            name="seats"
                            id="seats"
                            minValue={2}
                            maxValue={99}
                            placeholder="Number of seats"
                            disabled={
                              this.state.formStatus === 'loading' ||
                              this.state.stripePromise !== null
                            }
                            onChange={this.setSeats}
                            onBlur={(ev) =>
                              this.validateFormField(
                                ev.target.name as keyof FormData,
                              )
                            }
                            value={this.state.formData.seats}
                            required={true}
                            br={true}
                            error={this.state.formErrors.seats}
                          />
                          {!this.state.stripePromise && (
                            <Button
                              className="primary-button"
                              type="button"
                              onClick={this.handleCheckoutClick}
                              disabled={this.state.formData.name == ''}
                            >
                              <span className="fas fa-shopping-bag icon"></span>
                              <span className="text">Checkout</span>
                            </Button>
                          )}
                          {this.state.stripePromise && (
                            <Button
                              className="secondary-button"
                              onClick={() =>
                                this.setState({
                                  stripePromise: null,
                                  formData: { name: '', seats: 2 },
                                })
                              }
                            >
                              <span className="fas fa-redo icon"></span>
                              <span className="text">Start Over</span>
                            </Button>
                          )}
                        </form>
                      )}
                    </div>
                    <div className="column large">
                      {(this.state.stripePromise ||
                        this.state.formStatus === 'success') && (
                        <div className="card opaque">
                          <div
                            style={{
                              position: 'relative',
                              margin: '-16px',
                            }}
                          >
                            <div
                              style={{
                                overflow: 'scroll',
                                borderRadius: '8px',
                              }}
                            >
                              <ElementScrollbar absolute={true} />
                              <div
                                className="px-sm py-sm"
                                style={{
                                  background: 'white',
                                }}
                              >
                                <EmbeddedCheckoutProvider
                                  stripe={this.state.stripePromise}
                                  options={options}
                                >
                                  <EmbeddedCheckout />
                                </EmbeddedCheckoutProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.formStatus !== 'success' && (
                  <div className="flex-v-center">
                    <span className="pr-2xs text-xs">Secured by </span>
                    <span className="fab fa-stripe text-3xl"></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamAdd;
TeamAdd.contextType = AppContext;
