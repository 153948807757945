import { ChangeEvent, Component } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import PageMessage from '../../../partials/PageMessage/PageMessage';
import { confirmAccount } from '../../../../common/api/endpoints/register';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import AppContext from '../../../../common/contexts/AppContext';

interface State {
  status: TRequestStatus;
  resendStatus: TRequestStatus;
  formData: FormData;
  isContextReady: boolean;
}

interface FormData {
  email: string;
}

interface RouteParams {
  token: string;
}

export type Props = RouteComponentProps<RouteParams>;

class AccountConfirmation extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'loading',
      formData: {
        email: '',
      },
      resendStatus: 'idle',
      isContextReady: false, // New state to track context readiness
    };
  }

  componentDidMount(): void {
    if (this.context.loggedUser) {
      this.setState({ isContextReady: true }, this.handleSubmit);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevState.isContextReady && this.context.loggedUser) {
      this.setState({ isContextReady: true });
    }
  }

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = this.state.formData;

    this.setState({
      formData: {
        ...formData,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmit = async () => {
    try {
      await confirmAccount(this.props.match.params.token);
      this.setState({
        status: 'success',
      });
    } catch (err) {
      this.setState({
        status: 'error',
      });
    }
  };

  renderPageMessageContent = () => {
    if (this.state.status === 'loading') {
      return (
        <>
          <p>
            <svg
              className="spinner"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="circle"
                fill="none"
                strokeWidth="12"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="27"
              ></circle>
            </svg>
          </p>
        </>
      );
    }

    if (
      this.state.status === 'success' ||
      (this.state.isContextReady && this.context.loggedUser.confirmed)
    ) {
      return (
        <>
          <span className="far fa-3x faint-text fa-check-circle"></span>
          <br />
          <h1 className="primary-title normalcase pb-xs mt-sm">
            Account confirmed
          </h1>
          <p className="">Your email account was successfully confirmed!</p>
          <br />
          <ul className="control-list-component flex-h-center">
            <li>
              <NavLink
                to="/"
                className={'primary-button'}
              >
                <span className="text">Open Dashboard</span>
              </NavLink>
            </li>
          </ul>
        </>
      );
    }

    if (this.state.status === 'error') {
      return (
        <>
          <span className="far fa-3x faint-text fa-exclamation-circle"></span>
          <br />
          <h1 className="primary-title normalcase pb-xs mt-sm">
            Something went wrong...
          </h1>
          <p>The link either expired or has already been used.</p>
          <br />
        </>
      );
    }
  };

  render() {
    // Render nothing or a loader if the context is not ready
    if (!this.state.isContextReady) {
      return <PageMessage>Loading...</PageMessage>;
    }

    return <PageMessage>{this.renderPageMessageContent()}</PageMessage>;
  }
}

export default AccountConfirmation;
