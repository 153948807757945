import React, { Component } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import ComboBox from '../ComboBox/ComboBox';

interface TabData {
  id: string;
  label: string;
  content: JSX.Element;
  animatedOnIcon?: JSX.Element;
  animatedOffIcon?: JSX.Element;
  icon?: string;
  disabled?: boolean;
}

interface Props extends Partial<RouteComponentProps> {
  tabs: TabData[];
  vertical?: boolean;
  defaultTab?: string;
  setActiveTab?: (tab: string) => void;
  parentUrl?: string;
}

interface State {
  activeTab: string;
  prevIndex: number | undefined;
  currentIndex: number;
}

class MenuTabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: props.defaultTab || props.tabs[0].id,
      prevIndex: undefined,
      currentIndex: props.tabs.findIndex(
        (tab: TabData) => tab.id === props.defaultTab,
      ),
    };
  }

  componentDidMount(): void {
    if (this.props.location?.pathname === this.props.parentUrl) {
      this.setActiveTabUrl();
    } else {
      let found = false;
      this.props.tabs.forEach((tab) => {
        // Create a regex that matches the tab.id exactly as a folder pattern
        // It checks if tab.id is followed by a slash (/) or is at the end of the string
        const regex = new RegExp(`/${tab.id}(/|$)`);
        if (
          this.props.location &&
          this.props.location.pathname &&
          regex.test(this.props.location.pathname)
        ) {
          this.setState({
            activeTab: tab.id,
          });
          found = true;
        }
      });
      if ((found as boolean) === true) {
        return;
      }
    }
  }

  componentDidUpdate() {
    if (this.props.location?.pathname === this.props.parentUrl) {
      this.setActiveTabUrl();
    }

    if (
      this.props.history &&
      this.props.history.location.pathname !==
        this.props.parentUrl + '/' + this.state.activeTab
    ) {
      const parts = this.props.history.location.pathname.split('/');
      const part = parts.pop();

      if (this.props.tabs.some((tab) => tab.id === part)) {
        this.setState({
          activeTab: part || this.state.activeTab,
        });
      }
    }
  }

  setActiveTabUrl = () => {
    this.props.history?.replace(
      `${this.props.parentUrl}/${this.state.activeTab}`,
    );
  };

  setActiveTab = (newActiveTab: string, redirect?: boolean) => {
    if (this.props.parentUrl) {
      if (redirect) {
        this.props.history?.replace(`${this.props.parentUrl}/${newActiveTab}`);
      } else {
        this.props.history?.push(`${this.props.parentUrl}/${newActiveTab}`);
      }
    }

    this.setState(
      (prevState: State) => {
        return {
          activeTab: newActiveTab,
          prevIndex: prevState.currentIndex,
          currentIndex: this.props.tabs.findIndex(
            (tab: TabData) => tab.id === newActiveTab,
          ),
        };
      },
      () => {
        if (this.props.setActiveTab) {
          this.props.setActiveTab(this.state.activeTab);
        }
      },
    );
  };

  setActiveTabMobile = (
    ev: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<Element>,
    value: unknown,
  ) => {
    this.setActiveTab(value as string);
  };

  tabAnimation = (id: string): string => {
    return this.props.location?.pathname.includes(id) ||
      (!this.props.parentUrl && this.state.activeTab === id)
      ? this.state.prevIndex !== undefined
        ? this.state.prevIndex < this.state.currentIndex
          ? 'active'
          : 'active reverse'
        : 'active'
      : '';
  };

  panelAnimation = (): string => {
    if (this.props.vertical) {
      return this.state.prevIndex !== undefined
        ? this.state.prevIndex < this.state.currentIndex
          ? 'reveal-up-1-no-stack'
          : 'reveal-down-1-no-stack'
        : '';
    } else {
      return this.state.prevIndex !== undefined
        ? this.state.prevIndex < this.state.currentIndex
          ? 'reveal-left-1'
          : 'reveal-right-1'
        : '';
    }
  };

  renderTabs = () => {
    return (
      <>
        <ul className="nav-list hidden-mobile">
          {this.props.tabs.map((tab) => (
            <li
              className="nav-li"
              key={tab.id}
            >
              <button
                className={`nav-link ${this.tabAnimation(tab.id)}`}
                onClick={() => this.setActiveTab(tab.id)}
                disabled={tab.disabled || !!this.tabAnimation(tab.id)}
              >
                <div
                  className={`content-wrapper ${
                    this.props.vertical ? 'py-3xs' : ''
                  }`}
                >
                  {tab.animatedOnIcon && tab.animatedOffIcon ? (
                    <>
                      {this.tabAnimation(tab.id)
                        ? tab.animatedOnIcon
                        : tab.animatedOffIcon}
                    </>
                  ) : (
                    <>
                      {' '}
                      <span className={`${tab.icon} icon`}></span>
                    </>
                  )}

                  <span className="link-text">{tab.label}</span>
                </div>
              </button>
            </li>
          ))}
        </ul>
        <div className="flex-row visible-mobile">
          <div className="column pl-sm pt-2xs pb-0">
            <ComboBox
              id="tabNav"
              value={this.state.activeTab}
              disabled={false}
              options={this.props.tabs}
              onChange={this.setActiveTabMobile}
              getValue={(op) => op?.id}
              getLabel={(op) => op?.label}
              formGroupClassname="mb-0"
              title={'Navigation'}
              fullWidth={true}
            />
          </div>
        </div>
      </>
    );
  };

  renderTabContent = (tab: TabData) => {
    return tab.content;
  };

  render() {
    return (
      <>
        {this.props.vertical ? (
          <div className="flex-row fill">
            <div className="column pt-0 pl-0 menu-tabs-structure">
              <div
                className={`navigation-component`}
                style={{ position: 'sticky', top: '60px' }}
              >
                {this.renderTabs()}
              </div>
            </div>
            <div className="column pt-0 large">
              {this.props.tabs.map((tab) => (
                <React.Fragment key={tab.id}>
                  {this.props.parentUrl ? (
                    <Route
                      key={tab.id}
                      path={`${this.props.parentUrl}/${tab.id}`}
                    >
                      <div className={this.panelAnimation()}>
                        {this.renderTabContent(tab)}
                      </div>
                    </Route>
                  ) : (
                    this.state.activeTab === tab.id && (
                      <div className={this.panelAnimation()}>
                        {this.renderTabContent(tab)}
                      </div>
                    )
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="flex-row fill">
              <div className="column pb-0">
                <div className={`navigation-component horizontal`}>
                  {this.renderTabs()}
                </div>
              </div>
            </div>
            {this.props.tabs.map((tab) => (
              <React.Fragment key={tab.id}>
                {this.props.parentUrl ? (
                  <Route
                    key={tab.id}
                    path={`${this.props.parentUrl}/${tab.id}`}
                  >
                    <div className={this.panelAnimation()}>
                      {this.renderTabContent(tab)}
                    </div>
                  </Route>
                ) : (
                  this.state.activeTab === tab.id && (
                    <div className={this.panelAnimation()}>
                      {this.renderTabContent(tab)}
                    </div>
                  )
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  }
}

export default MenuTabs;
