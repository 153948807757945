export function relativeParentOffset(element: HTMLElement): number {
  let found = false;
  let inspectedElement: HTMLElement | null = element.parentElement;

  do {
    inspectedElement = inspectedElement ? inspectedElement.parentElement : null;
    if (inspectedElement !== null) {
      if (
        window.getComputedStyle(inspectedElement).transform !== 'none' ||
        window.getComputedStyle(inspectedElement).perspective !== 'none' ||
        window.getComputedStyle(inspectedElement).filter !== 'none'
      ) {
        found = true;
      }
    }
  } while (!found && inspectedElement !== null);

  return found ? -Number(inspectedElement!.getBoundingClientRect().top) : 0;
}
