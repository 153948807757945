import { TColor } from '../types/Color';

export const PRIORITY_COLORS: TColor[] = [
  {
    id: 'pink-blocker',
    color: 'pink',
    label: 'Pink - Blocker',
    symbol: 'fas fa-ban',
  },
  {
    id: 'pink-critical',
    color: 'pink',
    label: 'Pink - Critical',
    symbol: 'fas fa-exclamation-circle',
  },
  {
    id: 'pink-very-high',
    color: 'pink',
    label: 'Pink - Very High',
    symbol: 'fas fa-chevron-double-up',
  },
  {
    id: 'pink-high',
    color: 'pink',
    label: 'Pink - High',
    symbol: 'fas fa-arrow-up',
  },
  {
    id: 'pink-medium',
    color: 'pink',
    label: 'Pink - Medium',
    symbol: 'fas fa-equals',
  },
  {
    id: 'pink-low',
    color: 'pink',
    label: 'Pink - Low',
    symbol: 'fas fa-arrow-down',
  },
  {
    id: 'pink-very-low',
    color: 'pink',
    label: 'Pink - Very Low',
    symbol: 'fas fa-chevron-double-down',
  },
  {
    id: 'pink-none',
    color: 'pink',
    label: 'Pink - None',
    symbol: 'fas fa-minus-circle',
  },
  {
    id: 'red-blocker',
    color: 'red',
    label: 'Red - Blocker',
    symbol: 'fas fa-ban',
  },
  {
    id: 'red-critical',
    color: 'red',
    label: 'Red - Critical',
    symbol: 'fas fa-exclamation-circle',
  },
  {
    id: 'red-very-high',
    color: 'red',
    label: 'Red - Very High',
    symbol: 'fas fa-chevron-double-up',
  },
  {
    id: 'red-high',
    color: 'red',
    label: 'Red - High',
    symbol: 'fas fa-arrow-up',
  },
  {
    id: 'red-medium',
    color: 'red',
    label: 'Red - Medium',
    symbol: 'fas fa-equals',
  },
  {
    id: 'red-low',
    color: 'red',
    label: 'Red - Low',
    symbol: 'fas fa-arrow-down',
  },
  {
    id: 'red-very-low',
    color: 'red',
    label: 'Red - Very Low',
    symbol: 'fas fa-chevron-double-down',
  },
  {
    id: 'red-none',
    color: 'red',
    label: 'Red - None',
    symbol: 'fas fa-minus-circle',
  },
  {
    id: 'orange-blocker',
    color: 'orange',
    label: 'Orange - Blocker',
    symbol: 'fas fa-ban',
  },
  {
    id: 'orange-critical',
    color: 'orange',
    label: 'Orange - Critical',
    symbol: 'fas fa-exclamation-circle',
  },
  {
    id: 'orange-very-high',
    color: 'orange',
    label: 'Orange - Very High',
    symbol: 'fas fa-chevron-double-up',
  },
  {
    id: 'orange-high',
    color: 'orange',
    label: 'Orange - High',
    symbol: 'fas fa-arrow-up',
  },
  {
    id: 'orange-medium',
    color: 'orange',
    label: 'Orange - Medium',
    symbol: 'fas fa-equals',
  },
  {
    id: 'orange-low',
    color: 'orange',
    label: 'Orange - Low',
    symbol: 'fas fa-arrow-down',
  },
  {
    id: 'orange-very-low',
    color: 'orange',
    label: 'Orange - Very Low',
    symbol: 'fas fa-chevron-double-down',
  },
  {
    id: 'orange-none',
    color: 'orange',
    label: 'Orange - None',
    symbol: 'fas fa-minus-circle',
  },
  {
    id: 'yellow-blocker',
    color: 'yellow',
    label: 'Yellow - Blocker',
    symbol: 'fas fa-ban',
  },
  {
    id: 'yellow-critical',
    color: 'yellow',
    label: 'Yellow - Critical',
    symbol: 'fas fa-exclamation-circle',
  },
  {
    id: 'yellow-very-high',
    color: 'yellow',
    label: 'Yellow - Very High',
    symbol: 'fas fa-chevron-double-up',
  },
  {
    id: 'yellow-high',
    color: 'yellow',
    label: 'Yellow - High',
    symbol: 'fas fa-arrow-up',
  },
  {
    id: 'yellow-medium',
    color: 'yellow',
    label: 'Yellow - Medium',
    symbol: 'fas fa-equals',
  },
  {
    id: 'yellow-low',
    color: 'yellow',
    label: 'Yellow - Low',
    symbol: 'fas fa-arrow-down',
  },
  {
    id: 'yellow-very-low',
    color: 'yellow',
    label: 'Yellow - Very Low',
    symbol: 'fas fa-chevron-double-down',
  },
  {
    id: 'yellow-none',
    color: 'yellow',
    label: 'Yellow - None',
    symbol: 'fas fa-minus-circle',
  },
  {
    id: 'green-blocker',
    color: 'green',
    label: 'Green - Blocker',
    symbol: 'fas fa-ban',
  },
  {
    id: 'green-critical',
    color: 'green',
    label: 'Green - Critical',
    symbol: 'fas fa-exclamation-circle',
  },
  {
    id: 'green-very-high',
    color: 'green',
    label: 'Green - Very High',
    symbol: 'fas fa-chevron-double-up',
  },
  {
    id: 'green-high',
    color: 'green',
    label: 'Green - High',
    symbol: 'fas fa-arrow-up',
  },
  {
    id: 'green-medium',
    color: 'green',
    label: 'Green - Medium',
    symbol: 'fas fa-equals',
  },
  {
    id: 'green-low',
    color: 'green',
    label: 'Green - Low',
    symbol: 'fas fa-arrow-down',
  },
  {
    id: 'green-very-low',
    color: 'green',
    label: 'Green - Very Low',
    symbol: 'fas fa-chevron-double-down',
  },
  {
    id: 'green-none',
    color: 'green',
    label: 'Green - None',
    symbol: 'fas fa-minus-circle',
  },
  {
    id: 'cyan-blocker',
    color: 'cyan',
    label: 'Cyan - Blocker',
    symbol: 'fas fa-ban',
  },
  {
    id: 'cyan-critical',
    color: 'cyan',
    label: 'Cyan - Critical',
    symbol: 'fas fa-exclamation-circle',
  },
  {
    id: 'cyan-very-high',
    color: 'cyan',
    label: 'Cyan - Very High',
    symbol: 'fas fa-chevron-double-up',
  },
  {
    id: 'cyan-high',
    color: 'cyan',
    label: 'Cyan - High',
    symbol: 'fas fa-arrow-up',
  },
  {
    id: 'cyan-medium',
    color: 'cyan',
    label: 'Cyan - Medium',
    symbol: 'fas fa-equals',
  },
  {
    id: 'cyan-low',
    color: 'cyan',
    label: 'Cyan - Low',
    symbol: 'fas fa-arrow-down',
  },
  {
    id: 'cyan-very-low',
    color: 'cyan',
    label: 'Cyan - Very Low',
    symbol: 'fas fa-chevron-double-down',
  },
  {
    id: 'cyan-none',
    color: 'cyan',
    label: 'Cyan - None',
    symbol: 'fas fa-minus-circle',
  },
  {
    id: 'blue-blocker',
    color: 'blue',
    label: 'Blue - Blocker',
    symbol: 'fas fa-ban',
  },
  {
    id: 'blue-critical',
    color: 'blue',
    label: 'Blue - Critical',
    symbol: 'fas fa-exclamation-circle',
  },
  {
    id: 'blue-very-high',
    color: 'blue',
    label: 'Blue - Very High',
    symbol: 'fas fa-chevron-double-up',
  },
  {
    id: 'blue-high',
    color: 'blue',
    label: 'Blue - High',
    symbol: 'fas fa-arrow-up',
  },
  {
    id: 'blue-medium',
    color: 'blue',
    label: 'Blue - Medium',
    symbol: 'fas fa-equals',
  },
  {
    id: 'blue-low',
    color: 'blue',
    label: 'Blue - Low',
    symbol: 'fas fa-arrow-down',
  },
  {
    id: 'blue-very-low',
    color: 'blue',
    label: 'Blue - Very Low',
    symbol: 'fas fa-chevron-double-down',
  },
  {
    id: 'blue-none',
    color: 'blue',
    label: 'Blue - None',
    symbol: 'fas fa-minus-circle',
  },
  {
    id: 'purple-blocker',
    color: 'purple',
    label: 'Purple - Blocker',
    symbol: 'fas fa-ban',
  },
  {
    id: 'purple-critical',
    color: 'purple',
    label: 'Purple - Critical',
    symbol: 'fas fa-exclamation-circle',
  },
  {
    id: 'purple-very-high',
    color: 'purple',
    label: 'Purple - Very High',
    symbol: 'fas fa-chevron-double-up',
  },
  {
    id: 'purple-high',
    color: 'purple',
    label: 'Purple - High',
    symbol: 'fas fa-arrow-up',
  },
  {
    id: 'purple-medium',
    color: 'purple',
    label: 'Purple - Medium',
    symbol: 'fas fa-equals',
  },
  {
    id: 'purple-low',
    color: 'purple',
    label: 'Purple - Low',
    symbol: 'fas fa-arrow-down',
  },
  {
    id: 'purple-very-low',
    color: 'purple',
    label: 'Purple - Very Low',
    symbol: 'fas fa-chevron-double-down',
  },
  {
    id: 'purple-none',
    color: 'purple',
    label: 'Purple - None',
    symbol: 'fas fa-minus-circle',
  },
];
