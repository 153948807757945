import React, { Component } from 'react';

interface Props {
  errors: string[];
}

class ErrorList extends Component<Props> {
  render() {
    return (
      <>
        {this.props.errors.length > 0 && (
          <ul className="error-list">
            {this.props.errors.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </ul>
        )}
      </>
    );
  }
}

export default ErrorList;
