import React from 'react';
import { ICard } from '../../../../common/interfaces/BoardContext';

import { TCardData } from '../../../../common/types/CardData';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from '../../../../vendors/wavemyth/react-beautiful-dnd/src';
import BoardCard from '../BoardCard/BoardCard';

interface BoardColumnCardProps {
  index: number;
  isLast: boolean;
  cardNrPrefix: string;
  boardCard: ICard;
  selected: boolean;
  boardColumnId: string;
  selectedCardData: TCardData[];
  tags: TagDTO[];
  priorities: PriorityDTO[];
  members: (MemberDTO | InviteeDTO)[];
  selectCard: (columnId: string | null, cardId: string) => void;
  setCardRef: (
    cardId: string,
    cardRef: React.RefObject<HTMLDivElement>,
  ) => void;
  renderAdd: () => React.ReactNode;
  onLoad: () => void;
}

export class BoardColumnCard extends React.PureComponent<BoardColumnCardProps> {
  componentDidMount(): void {
    this.props.onLoad();
  }
  render() {
    const boardCard = this.props.boardCard;
    return (
      <React.Fragment>
        <Draggable
          key={boardCard.id}
          draggableId={boardCard.id}
          index={this.props.index}
        >
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
            <BoardCard
              key={this.props.index}
              disabled={false}
              selected={this.props.selected}
              cardNrPrefix={this.props.cardNrPrefix}
              boardCard={boardCard}
              columnId={this.props.boardColumnId}
              selectedCardData={this.props.selectedCardData}
              tags={this.props.tags}
              priorities={this.props.priorities}
              members={this.props.members}
              selectCard={this.props.selectCard}
              setCardRef={this.props.setCardRef}
              provided={provided}
              snapshot={snapshot}
              innerRef={provided.innerRef}
            />
          )}
        </Draggable>
      </React.Fragment>
    );
  }
}
