import React, { useEffect, useMemo } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from '../../../../vendors/wavemyth/react-beautiful-dnd/src';
import { CardDTO } from '../../../../common/api/dtos/Card';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import { getDropStyle } from '../../../../common/helpers/getDropStyle';
import Button from '../../../controls/Button/Button';
import BoardColumnHeader from '../BoardColumn/BoardColumnLayout/BoardColumnHeader';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';

export interface ColumnInfo extends ColumnDTO {
  // All cards in the column
  cards: CardDTO[];

  // Visible cards on the board
  visibleCards: CardDTO[];
}

interface Props extends WithTranslation {
  boardColumn: ColumnInfo;
  index: number;
  handleExpandColumn: (columnId: string) => void;
  boardFilterValue?: string | null;
  columnCardLimitState: 'disabled' | 'enabled' | 'enforced';
}

const BoardColumnCollapsed: React.FC<Props> = ({
  boardColumn,
  index,
  handleExpandColumn,
  boardFilterValue,
  columnCardLimitState,
  t,
}) => {
  useEffect(() => {
    // Stop propagation of horizontal drag
    const columnHead = document.querySelector(
      `.card-board-list[data-rfd-draggable-id='${boardColumn.id}'] .list-drag-head`,
    ) as HTMLDivElement;

    const handleMouseDown = (e: MouseEvent) => e.stopPropagation();

    if (columnHead) {
      columnHead.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      if (columnHead) {
        columnHead.removeEventListener('mousedown', handleMouseDown);
      }
    };
  }, [boardColumn.id]);

  const handleExpand = () => handleExpandColumn(boardColumn.id);

  const renderColumnCount = useMemo(() => {
    const { cardLimit, cards, visibleCards } = boardColumn;
    const totalCards = cards.length;
    const visible = visibleCards.length;
    const hasFilter = !!boardFilterValue;
    const hasLimit = columnCardLimitState !== 'disabled' && cardLimit;

    return (
      <sup className="pe-none no-wrap text-sm">
        <small>
          {hasFilter ? (
            <>
              {visible}
              {totalCards > 0 && (
                <>
                  {' ('}
                  {totalCards}
                  {hasLimit && `/${cardLimit}`}
                  {')'}
                </>
              )}
            </>
          ) : hasLimit ? (
            <>
              {totalCards}/{cardLimit}
            </>
          ) : (
            <>{totalCards}</>
          )}
        </small>
      </sup>
    );
  }, [boardColumn, boardFilterValue, columnCardLimitState]);

  return (
    <Draggable
      draggableId={boardColumn.id}
      index={index}
    >
      {(
        draggableProvided: DraggableProvided,
        draggableSnapshot: DraggableStateSnapshot,
      ) => (
        <Droppable droppableId={boardColumn.id}>
          {(
            droppableProvided: DroppableProvided,
            droppableSnapshot: DroppableStateSnapshot,
          ) => (
            <div
              className={['column', 'card-board-list', 'collapsed-list'].join(
                ' ',
              )}
              {...draggableProvided.draggableProps}
              ref={draggableProvided.innerRef}
              style={getDropStyle(
                draggableProvided.draggableProps.style,
                draggableSnapshot,
              )}
            >
              <div
                className={[
                  'list-drag-helper',
                  draggableSnapshot.isDragging ? 'dragging' : '',
                  columnCardLimitState !== 'disabled' &&
                  boardColumn.cardLimit &&
                  boardColumn.cardLimit < boardColumn.cards.length
                    ? 'static-border-danger'
                    : '',
                ].join(' ')}
              >
                <BoardColumnHeader provided={draggableProvided}>
                  <div className="column pt-2xs large">
                    <div
                      className="collapsed-handle"
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      <small className="fas fa-grip-lines icon"></small>
                      <Button
                        className={
                          droppableSnapshot.isDraggingOver
                            ? 'primary-button'
                            : 'ghost-button'
                        }
                        title={t('expandColumn', {
                          columnTitle: boardColumn.title,
                        })}
                        onClick={handleExpand}
                      >
                        <span className="pe-none card-board-list-title">
                          {boardColumn.title}
                        </span>
                      </Button>
                      &nbsp;
                      <span className="inline-block mt-xs">
                        {renderColumnCount}
                      </span>
                    </div>
                  </div>
                </BoardColumnHeader>
              </div>
            </div>
          )}
        </Droppable>
      )}
    </Draggable>
  );
};

export default withStyledTranslation('boardColumnCollapsed')(
  BoardColumnCollapsed,
);
