/**
 * Default values for user experiments.
 */
export function getDefaultExperiments() {
  return {
    detachableFlyout: false,
    showTranslucency: true,
    showLanguage: false,
  };
}
