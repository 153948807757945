import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateBoardButton from './CreateBoardButton';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import MessageBar from '../../../controls/MessageBar/MessageBar'; // Import MessageBar

interface NoBoardsMessageProps {
  disabled?: boolean;
  team?: TeamDTO;
}

const NoBoardsMessage: React.FC<NoBoardsMessageProps> = ({
  disabled,
  team,
}) => {
  const { t } = useTranslation('home');
  return (
    <div className="flex-row pt-0 fill fade-in-grow">
      <div className="column px-2xs pt-xs pb-0">
        <MessageBar
          type="info"
          icon="fa-info-circle"
          rightContent={
            !disabled && (
              <CreateBoardButton
                disabled={false}
                team={team}
              />
            )
          }
        >
          {team
            ? team?.role === 'owner'
              ? t('noBoardsTeamOwners')
              : t('noBoardsTeam')
            : t('noBoards')}
        </MessageBar>
      </div>
    </div>
  );
};

export default NoBoardsMessage;
