import { Component } from 'react';
import Button from '../../../controls/Button/Button';
import Dialog from '../../../controls/Dialog/Dialog';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import SubscriptionStatus from './SubscriptionStatus';

interface Props {
  hiddenTeams: string[];
  teams: TeamDTO[];
  clearHiddenTeams: (teamId: string) => void;
  hideShelf: (teamId: string) => void;
  t: (key: string) => string;
}

interface State {
  clearHiddenTeamsDialog: boolean;
}

class ShelfManager extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clearHiddenTeamsDialog: false,
    };
  }

  render() {
    const { hiddenTeams, teams, clearHiddenTeams, t } = this.props;
    const { clearHiddenTeamsDialog } = this.state;

    return (
      <>
        {hiddenTeams.length > 0 && (
          <>
            {clearHiddenTeamsDialog && (
              <Dialog
                title={t('hiddenTeams')}
                size="large"
                onCancel={() =>
                  this.setState({
                    clearHiddenTeamsDialog: false,
                  })
                }
                onConfirm={() => {
                  return;
                }}
                cancelText={t('done')}
                message={
                  <>
                    {teams
                      .filter((team) => hiddenTeams.includes(team.id))
                      .map((team) => (
                        <div
                          className="flex-row fill"
                          key={team.id}
                        >
                          <div className="column pt-0 pb-2xs">
                            <div className="card ">
                              <div className="flex-row">
                                <div className="column py-0 flex-v-center large">
                                  <h3 className="text-sm">{team.name}</h3>
                                </div>
                                <div className="column py-0 flex-h-end flex-v-center">
                                  <Button
                                    className="secondary-button"
                                    onClick={() => clearHiddenTeams(team.id)}
                                  >
                                    {t('restore')}
                                  </Button>
                                </div>
                              </div>
                              <SubscriptionStatus team={team} />
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                }
              />
            )}
            <Button
              className="secondary-button no-wrap"
              onClick={() =>
                this.setState({
                  clearHiddenTeamsDialog: true,
                })
              }
            >
              <span className="text">{t('hiddenTeams')}</span>
              <span className="ml-xs badge-text">{hiddenTeams.length}</span>
            </Button>
          </>
        )}
      </>
    );
  }
}

export default ShelfManager;
