import React from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { BoardItemDTO } from '../../../../common/api/dtos/Board';
import QuickAccessDroppable from '../../../partials/QuickAccess/QuickAccessDroppable';
import { useTranslation } from 'react-i18next';
import ContentLoader from '../../../partials/ContentLoader/ContentLoader';

interface QuickAccessSectionProps {
  statusQuickAccess: TRequestStatus;
  quickAccessBoards: BoardItemDTO[] | undefined;
}

const QuickAccessSection: React.FC<QuickAccessSectionProps> = ({
  statusQuickAccess,
  quickAccessBoards,
}) => {
  const { t } = useTranslation('home');

  return (
    <div
      style={{
        position: 'sticky',
        top: '46px',
        maxHeight: 'calc(100dvh - 140px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <div className="flex-row fill flex-v-center flex-h-spread">
        <div className="column pt-xs pb-0">
          <h2 className="primary-title h3 normalcase">
            <img
              alt="starred boards icon"
              className="icon"
              src={`${process.env.REACT_APP_ICONS_PATH}/starred-boards.svg`}
            />{' '}
            <span>{t('quickAccess')}</span>
          </h2>
        </div>
      </div>
      <div className="flex-row card-list-component fill">
        {statusQuickAccess === 'loading' ? (
          <div className="column px-xs pt-xs pb-0">
            <ContentLoader height="66px" />
          </div>
        ) : typeof quickAccessBoards === 'undefined' ? (
          <div className="column flex-v-stretch">
            <div className="card">
              <div className="content py-xs">
                <p>
                  <span className="fas fa-exclamation-triangle negative-text"></span>{' '}
                  <span className="faint-text text-sm">
                    {t('quickAccessUnknownError')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : quickAccessBoards.length ? (
          <div className="column px-xs pt-0 pb-0">
            <QuickAccessDroppable elements={quickAccessBoards} />
          </div>
        ) : (
          <div className="column flex-v-stretch">
            <div className="card">
              <div className="content py-xs">
                <p>{t('quickAccessNoBoards')}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickAccessSection;
