import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $getNodeByKey,
  RangeSelection,
  NodeSelection,
  LexicalEditor,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  BaseSelection,
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $wrapNodes, $isAtNodeEnd } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
  INSERT_CHECK_LIST_COMMAND,
} from '@lexical/list';
import { createPortal } from 'react-dom';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from '@lexical/rich-text';
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages,
} from '@lexical/code';
import ContextMenu from '../../ContextMenu/ContextMenu';
import Button from '../../Button/Button';
import ComboBox from '../../ComboBox/ComboBox';
import { t } from 'i18next';
import TextBox from '../../TextBox/TextBox';

const LowPriority = 1;

type BlockType =
  | 'paragraph'
  | 'quote'
  | 'code'
  | 'h1'
  | 'h2'
  | 'ul'
  | 'ol'
  | 'check';

const supportedBlockTypes = new Set([
  'paragraph',
  'quote',
  'code',
  'h1',
  'h2',
  'ul',
  'ol',
  'check',
]);

const blockTypeToBlockName = {
  code: 'Code Block',
  h1: 'Large Heading',
  h2: 'Small Heading',
  h3: 'Heading',
  h4: 'Heading',
  h5: 'Heading',
  ol: 'Numbered List',
  ul: 'Bulleted List',
  check: 'Check List',
  paragraph: 'Normal',
  quote: 'Quote',
};

function Divider() {
  return <div className="divider" />;
}

function positionEditorElement(
  editor: HTMLDivElement,
  rect: DOMRect | null,
  positionHelper?: { top: number; left: number; right: number },
) {
  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
  } else {
    editor.style.position = 'absolute';
    editor.style.opacity = '1';
    editor.style.zIndex = '99999';
    if (positionHelper) {
      editor.style.top = `${positionHelper.top + rect.height + 10}px`;
      editor.style.left = `${positionHelper.left}`;
      editor.style.right = `${positionHelper.right}`;
    }
  }
}

function FloatingLinkEditor({ editor }: { editor: LexicalEditor }) {
  const editorRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(true);
  const [lastSelection, setLastSelection] = useState<
    null | RangeSelection | NodeSelection | BaseSelection
  >(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection: Selection | null = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      const editorRect = (
        editorRef.current?.parentNode as HTMLDivElement
      ).getBoundingClientRect();

      if (nativeSelection.anchorNode === rootElement) {
        let inner: Element = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      const willRenderOutsideLexical =
        editorRect && rect.left + 320 > editorRect.left + editorRect.width;

      if (!mouseDownRef.current) {
        const positionHelper: {
          top: number;
          left: number;
          right: number;
        } = {
          top: editorRect && rect.top - editorRect.top,
          left: !willRenderOutsideLexical
            ? editorRect && rect.left - editorRect.left
            : 0,
          right: !willRenderOutsideLexical ? 0 : editorRect.right - rect.right,
        };
        positionEditorElement(editorElem, rect, positionHelper);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority,
      ),
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div
      ref={editorRef}
      className="link-editor card opaque py-xs px-xs"
      style={{
        width: '100dvw',
        maxWidth: '306px',
      }}
    >
      <ul className="control-list-component flex-h-spread">
        {isEditMode ? (
          <li>
            <TextBox
              autofocus={true}
              label="Link URL"
              type="url"
              id="link-url"
              name="link-url"
              placeholder="Type a valid URL..."
              inputref={inputRef}
              className="link-input"
              value={linkUrl}
              srOnly={true}
              formGroupClassNames="mb-0"
              onChange={(event) => {
                setLinkUrl(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  if (lastSelection !== null) {
                    if (linkUrl !== '') {
                      editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                    }
                    setEditMode(false);
                  }
                } else if (event.key === 'Escape') {
                  event.preventDefault();
                  setEditMode(false);
                }
              }}
            />
          </li>
        ) : (
          <li>
            <div className="link-input pl-xs mr-2xs">
              <a
                href={linkUrl}
                className="link-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text">{linkUrl}</span>
              </a>
            </div>
          </li>
        )}
        {editor.isEditable() && (
          <li>
            <div
              className={`${
                isEditMode ? 'primary-button' : 'secondary-button'
              }`}
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                if (isEditMode) {
                  if (lastSelection !== null) {
                    if (linkUrl !== '') {
                      editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                    }
                    setEditMode(false);
                  }
                } else {
                  setEditMode(true);
                }
              }}
            >
              {isEditMode ? (
                <>
                  <span className="text">Save</span>
                </>
              ) : (
                <>
                  <span className="text">Edit</span>
                </>
              )}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
  setBlockType,
}: {
  editor: LexicalEditor;
  blockType: string;
  toolbarRef: React.RefObject<HTMLDivElement>;
  setShowBlockOptionsDropDown: (val: boolean) => void;
  setBlockType: (val: BlockType) => void;
}) {
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatList = (listType: 'number' | 'bullet' | 'check') => {
    if (listType === 'number' && blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType('ol');
    } else if (listType === 'bullet' && blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('ul');
    } else if (listType === 'check' && blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
      setBlockType('check');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <>
      <li>
        <button
          className="ghost-button"
          onClick={formatParagraph}
        >
          <span className="icon fas fa-text-size" />
          <span className="text">Normal</span>
          {blockType === 'paragraph' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={formatLargeHeading}
        >
          <span className="icon fas fa-h1" />
          <span className="text">Large Heading</span>
          {blockType === 'h1' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={formatSmallHeading}
        >
          <span className="icon fas fa-h2" />
          <span className="text">Small Heading</span>
          {blockType === 'h2' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={() => formatList('bullet')}
        >
          <span className="icon fas fa-list" />
          <span className="text">Bullet List</span>
          {blockType === 'ul' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={() => formatList('number')}
        >
          <span className="icon fas fa-list-ol" />
          <span className="text">Numbered List</span>
          {blockType === 'ol' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={() => formatList('check')}
        >
          <span className="icon fas fa-check-square" />
          <span className="text">Check List</span>
          {blockType === 'check' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={formatQuote}
        >
          <span className="icon fas fa-quote-right" />
          <span className="text">Quote</span>
          {blockType === 'quote' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={formatCode}
        >
          <span className="icon fas fa-code" />
          <span className="text">Code Block</span>
          {blockType === 'code' && <span className="active" />}
        </button>
      </li>
    </>
  );
}

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(
    null,
  );
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [codeLanguage, setCodeLanguage] = useState('');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [isNativeSelection, setIsNativeSelection] = useState(false);
  const [isLeft, setIsLeft] = useState(false);
  const [isCenter, setIsCenter] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const [isJustify, setIsJustify] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = (
            $isHeadingNode(element) ? element.getTag() : element.getType()
          ) as BlockType;
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsCode(selection.hasFormat('code'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      const parentObj = parent?.exportJSON();

      if (parentObj?.format === 'left') {
        setIsLeft(!isLeft);
      } else {
        setIsLeft(false);
      }

      if (parentObj?.format === 'center') {
        setIsCenter(!isCenter);
      } else {
        setIsCenter(false);
      }

      if (parentObj?.format === 'right') {
        setIsRight(!isRight);
      } else {
        setIsRight(false);
      }

      if (parentObj?.format === 'justify') {
        setIsJustify(!isJustify);
      } else {
        setIsJustify(false);
      }

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }

    const nativeSelection = window.getSelection();
    if (nativeSelection && nativeSelection.toString().length > 0) {
      setIsNativeSelection(true);
    } else {
      setIsNativeSelection(false);
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    (e: unknown, value: number | string | undefined) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value?.toString() || '');
          }
        }
      });
    },
    [editor, selectedElementKey],
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <div
      className="card opaque py-2xs px-2xs"
      style={{
        position: 'relative',
        zIndex: 1,
      }}
      ref={toolbarRef}
    >
      <ul className="control-list-component">
        <li>
          <Button
            disabled={!canUndo}
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
            className="secondary-button spaced"
            aria-label="Undo"
          >
            <i className="fas fa-undo" />
          </Button>
        </li>
        <li>
          <Button
            disabled={!canRedo}
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined);
            }}
            className="secondary-button"
            aria-label="Redo"
          >
            <i className="fas fa-redo" />
          </Button>
        </li>
        <li>
          {supportedBlockTypes.has(blockType) && (
            <>
              <ContextMenu
                dept={0}
                contextMenuClassName="align-v-start"
                triggerClassDefault="secondary-button"
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                showBlockOptionsDropDown={showBlockOptionsDropDown}
                triggerContent={
                  <>
                    <span className="text">
                      {blockTypeToBlockName[blockType]}
                    </span>
                    <span className="icon fas fa-angle-down"></span>
                  </>
                }
              >
                <BlockOptionsDropdownList
                  editor={editor}
                  blockType={blockType}
                  toolbarRef={toolbarRef}
                  setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                  setBlockType={setBlockType}
                />
              </ContextMenu>
              <Divider />
            </>
          )}
        </li>
        {blockType === 'code' ? (
          <>
            <li>
              <ComboBox
                id="code-language"
                formGroupClassname="pb-0 mb-0"
                label={t('codeLanguage')}
                title={t('codeLanguage')}
                classes="secondary-button code-language"
                onChange={onCodeLanguageSelect}
                options={codeLanguges}
                value={codeLanguage}
                getValue={(option) => option}
                getLabel={(option) => option}
                srOnly={true}
              />
              <i className="chevron-down inside" />
            </li>
          </>
        ) : (
          <>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
                className={
                  'secondary-button spaced ' + (isBold ? 'active-border' : '')
                }
                aria-label="Format Bold"
              >
                <i className={`fas fa-bold`} />
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
                className={
                  'secondary-button spaced ' + (isItalic ? 'active-border' : '')
                }
                aria-label="Format Italics"
              >
                <i className={`fas fa-italic`} />
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
                className={
                  'secondary-button spaced ' +
                  (isUnderline ? 'active-border' : '')
                }
                aria-label="Format Underline"
              >
                <i className={`fas fa-underline`} />
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
                }}
                className={
                  'secondary-button spaced ' +
                  (isStrikethrough ? 'active-border' : '')
                }
                aria-label="Format Strikethrough"
              >
                <i className={`fas fa-strikethrough`}></i>
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
                }}
                className={
                  'secondary-button spaced ' + (isCode ? 'active-border' : '')
                }
                aria-label="Insert Code"
              >
                <i className="fas fa-code" />
              </Button>
            </li>
            <li>
              <Button
                onClick={insertLink}
                className={
                  'secondary-button spaced ' + (isLink ? 'active-border' : '')
                }
                aria-label="Insert Link"
                disabled={!isNativeSelection}
              >
                <i className="fas fa-link" />
              </Button>
            </li>
            {isLink &&
              createPortal(
                <FloatingLinkEditor editor={editor} />,
                toolbarRef.current?.parentNode as HTMLDivElement,
              )}
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
                }}
                className={
                  'secondary-button spaced ' + (isLeft ? 'active-border' : '')
                }
                aria-label="Left Align"
              >
                <i className="fas fa-align-left" />
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
                }}
                className={
                  'secondary-button spaced ' + (isCenter ? 'active-border' : '')
                }
                aria-label="Center Align"
              >
                <i className="fas fa-align-center" />
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
                }}
                className={
                  'secondary-button spaced ' + (isRight ? 'active-border' : '')
                }
                aria-label="Right Align"
              >
                <i className="fas fa-align-right" />
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
                }}
                className={
                  'secondary-button spaced ' +
                  (isJustify ? 'active-border' : '')
                }
                aria-label="Justify Align"
              >
                <i className="fas fa-align-justify" />
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
                }}
                className="secondary-button spaced"
                aria-label="Indent"
              >
                <i className="fas fa-indent" />
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
                }}
                className="secondary-button spaced"
                aria-label="Outdent"
              >
                <i className="fas fa-outdent" />
              </Button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
