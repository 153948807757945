import {
  ChecklistItem,
  getChecklistItems,
} from '../../components/partials/Onboarding/Tutorial/tutorialData';
import { UserDTO } from '../api/dtos/User';
import { patchClientData } from '../api/endpoints/user';
import { IClientData } from '../interfaces/ClientData';
import { ErrorNotificationPayload } from './errorNotificationPayload';
import { showErrorNotifications } from './showNotifications';

export const updateTutorialStep = async (
  loggedUser: UserDTO | undefined,
  userType: string,
  stepId: string,
  setMessages: (messages: ErrorNotificationPayload[]) => void,
  updateClientData: (data: Partial<IClientData>) => void,
) => {
  if (!loggedUser) return;

  // Retrieve current progress data
  const clientData: IClientData = loggedUser.clientData;

  const tutorialKey = `tutorial_${userType}`;
  const tutorialData = clientData[tutorialKey as keyof IClientData] || {
    steps: {},
  };
  const tutorialSteps = tutorialData.steps;

  // Initialize step if not present
  let step = tutorialSteps[stepId];
  if (!step) {
    const stepConfig = getChecklistItems(userType).find(
      (step: ChecklistItem) => step.id === stepId,
    );

    if (stepConfig) {
      let initialQuantity = stepConfig.quantity;

      // Handle specific cases
      if (
        stepConfig.id === 'create_first_board' ||
        stepConfig.id === 'setup_team_board'
      ) {
        initialQuantity = loggedUser.onboarding.boardName ? 1 : 0;
      } else if (
        stepConfig.id === 'create_cards' ||
        stepConfig.id === 'add_cards'
      ) {
        initialQuantity = loggedUser.onboarding.cardNames
          ? loggedUser.onboarding.cardNames.length
          : 0;
      }

      step = {
        quantity: initialQuantity,
        completed: initialQuantity >= stepConfig.requiredQuantity,
      };
      tutorialSteps[stepId] = step;
    }
  }

  const stepConfig = getChecklistItems(userType).find(
    (step: ChecklistItem) => step.id === stepId,
  );

  // Check for step existence
  if (!step || !stepConfig) {
    console.error(`Step with ID ${stepId} not found in tutorial steps.`);
    return;
  }

  if (step.completed) {
    return;
  }

  // Increment step quantity and update completion status
  if (step.quantity < stepConfig.requiredQuantity) {
    step.quantity += 1;
    step.completed = step.quantity >= stepConfig.requiredQuantity;
  }

  // Update client data
  const updatedClientData: Partial<IClientData> = {
    ...loggedUser.clientData,
    [tutorialKey]: {
      ...loggedUser.clientData[tutorialKey],
      steps: {
        ...loggedUser.clientData[tutorialKey]?.steps,
        [stepId]: step,
      },
    },
  };

  // Patch client data
  try {
    await patchClientData(updatedClientData);

    // Update context with the new client data
    updateClientData(updatedClientData);
  } catch (error) {
    console.error('Failed to update client data', error);
    showErrorNotifications(error, setMessages);
  }
};
