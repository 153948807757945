import React, { Component } from 'react';

interface PassErrors {
  message: string;
  isValid: boolean;
}

interface Props {
  label: string;
  type: string;
  name: string;
  id: string;

  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  ariaAutocomplete?: 'none' | 'inline' | 'list' | 'both';
  br?: boolean;
  placeholder?: string;
  srOnly?: boolean;
  value?: string | number;
  disabled?: boolean;
  required?: boolean;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  maxLength?: number;
  className?: string;
  tight?: boolean;
  autofocus?: boolean;
  formGroupClassNames?: string;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  showCounter?: boolean;
  inputIcon?: string;
  onIconClick?: (value: boolean) => void;
  togglePass?: boolean;
  passErrors?: PassErrors[];
  role?: string;
  defaultValue?: string;
  inputref?: React.RefObject<HTMLInputElement>;
}

interface State {
  focused: boolean;
}

class TextBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      focused: false,
    });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    return (
      <div
        className={[
          'form-group',
          this.props.formGroupClassNames,
          this.props.tight ? 'tight' : '',
        ].join(' ')}
      >
        <label
          className={`${this.props.srOnly ? 'sr-only' : ''}`}
          htmlFor={this.props.id}
        >
          {this.props.label}
          {this.props.required ? '*' : ''}
        </label>
        {this.props.br && <br />}
        <div className="input-wrapper">
          <input
            type={this.props.type}
            name={this.props.name}
            id={this.props.id}
            defaultValue={this.props.defaultValue}
            autoComplete={this.props.autoComplete}
            aria-autocomplete={this.props.ariaAutocomplete}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            value={this.props.value}
            required={this.props.required}
            onBlur={this.onBlur}
            maxLength={this.props.maxLength ? this.props.maxLength : 255}
            aria-invalid={`${!!this.props.error}`}
            autoFocus={this.props.autofocus}
            className={this.props.className}
            onKeyDown={this.props.onKeyDown}
            role={this.props.role}
            onFocus={(ev) => {
              this.onFocus();
              this.props.onFocus && this.props.onFocus(ev);
            }}
          />
          {this.props.inputIcon && (
            <button
              className="input-icon"
              onClick={() => this.props.onIconClick!(!this.props.togglePass)}
            >
              <span className={this.props.inputIcon}></span>
            </button>
          )}
        </div>
        {this.props.showCounter &&
          this.state.focused &&
          typeof this.props.value === 'string' && (
            <span className="textarea-counter flag-text">
              {this.props.value.length}/
              {this.props.maxLength ? this.props.maxLength : 255}
            </span>
          )}
        {this.props.error ? (
          <ul className="error-list light">
            <li className="flex-v-center">
              <span className="fas fa-exclamation-circle mr-2xs text-sm"></span>
              <span className="text">{this.props.error}</span>
            </li>
          </ul>
        ) : this.props.passErrors ? (
          <ul className="error-list light">
            {this.props.passErrors.map((error) => {
              return (
                <li
                  key={error.message}
                  className={`flex-v-center ${
                    error.isValid ? 'success' : 'error'
                  }`}
                >
                  <span
                    className={`${
                      error.isValid
                        ? 'fas fa-check-circle mr-2xs text-sm'
                        : 'fas fa-exclamation-circle mr-2xs text-sm'
                    }`}
                  ></span>
                  <span className="text">{error.message}</span>
                </li>
              );
            })}
          </ul>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default TextBox;
