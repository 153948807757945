export const EXPERIMENTS = [
  {
    id: 'locales',
    name: 'Translations',
    description:
      'Enables translations and language selectors across the entire app',
    icon: 'fal fa-language',
  },
  {
    id: 'boardFlashcards',
    name: 'Board Flashcards',
    description:
      'A flashcard will be shown for hovered Board cards on Homepage',
    icon: 'fal fa-sticky-note',
  },
];
