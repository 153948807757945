import React from 'react';

const UserOnIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className="icon"
    >
      <path
        d="M6 20c0-3 4-5 6-5s6 2 6 5"
        stroke="currentColor"
        strokeWidth="2"
        fill="#ffffff00"
        className="user_on_line"
        strokeDasharray="30"
        strokeDashoffset="30"
      />
      <circle
        cx="12"
        cy="8"
        r="4"
        stroke="currentColor"
        strokeWidth="2"
        fill="#ffffff00"
        className="user_on_circle"
        strokeDasharray="30"
        strokeDashoffset="30"
      />
      <style>
        {`
          .user_on_circle {
          fill: transparent;
            stroke-dashoffset: 30;
            animation: fillCircle 1s ease forwards, 0.6s drawCircle 0.1s ease forwards;
          }

          .user_on_line {
            fill: transparent;
            stroke-dashoffset: 30;
            animation: fillLine 1s ease forwards, 0.6s drawLine 0.1s ease forwards;
          }

          @keyframes fillCircle {
            to {
              fill: currentColor;
            }
          }

          @keyframes drawCircle {
            to {
              stroke-dashoffset: 0;
            }
          }

          @keyframes drawLine {
            to {
              stroke-dashoffset: 0;
            }
          }
          
          @keyframes fillLine {
            to {
              fill: currentColor;
            }
          }
        `}
      </style>
    </svg>
  );
};

export default UserOnIcon;
