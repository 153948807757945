import React, { useState, useEffect } from 'react';

const CoinsOn: React.FC = () => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <svg
      // style={{
      //   transform: 'scale(20) translate(40%,50%)',
      // }}
      key={key}
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className="icon"
    >
      <rect
        width="100"
        height="100"
        fill="transparent"
      />
      <defs>
        <g id="coin_edge">
          <path
            d="
            M -30,0 a 30,10 0 1,0 60,0 l 0,5 a 30,10 0 1,1 -60,0 l 0,-5 Z"
            className="coin_on_side"
            stroke="transparent"
            strokeWidth="2"
          />
        </g>

        <g id="coin_face">
          <ellipse
            cx="0"
            cy="0"
            rx="30"
            ry="10"
            stroke="transparent"
            className="coin_on_top"
            strokeWidth="2"
          />
        </g>
      </defs>

      <defs>
        <g id="rendered_coin">
          <use
            y="0"
            href="#coin_edge"
            fill="white"
            style={{
              filter: 'brightness(0.5)',
            }}
          />
          <use
            y={-5}
            href="#coin_edge"
            fill="white"
            style={{
              filter: 'brightness(0.5)',
            }}
          />
          <use
            y={-5}
            href="#coin_face"
            fill="white"
          />
        </g>
      </defs>

      <g>
        <use
          href="#rendered_coin"
          clipPath="url(#coin_mask_1)"
          x="45"
          y="70" // final position
        />
        <use
          href="#rendered_coin"
          x="50"
          y="55" // final position
        />
        <use
          href="#rendered_coin"
          x="45"
          y="35" // final position
        />
      </g>
    </svg>
  );
};

export default CoinsOn;
