import React from 'react';
import { DroppableProvided } from '../../../vendors/wavemyth/react-beautiful-dnd/src';
interface Props {
  className?: string;

  // the below is for drag n drop functionality only
  // do not add anything below if it's not for drag n drop (for easier readability)
  provided?: DroppableProvided;
}

export default class QuickAccessDraggable extends React.Component<Props> {
  scrollContentRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.scrollContentRef = React.createRef();
  }

  render() {
    return (
      <div ref={this.scrollContentRef}>
        <div
          ref={this.props.provided?.innerRef}
          {...this.props.provided?.droppableProps}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
