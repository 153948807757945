import { ChangeEvent, Component, FormEvent, KeyboardEvent } from 'react';
import AppContext from '../../../../common/contexts/AppContext';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import TextBox from '../../../controls/TextBox/TextBox';
import Joi from 'joi';
import TextArea from '../../../controls/TextArea/TextArea';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import Button from '../../../controls/Button/Button';
import { addBoard, listBoards } from '../../../../common/api/endpoints/board';
import { COLORS } from '../../../../common/configs/Colors';
import { TColor } from '../../../../common/types/Color';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { THUMBNAILS } from '../../../../common/configs/Thumbnails';
import { TThumbnails } from '../../../../common/types/Thumbnails';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import ComboBox from '../../../controls/ComboBox/ComboBox';
import Radio from '../../../controls/Radio/Radio';
import {
  createTeamBoard,
  listTeams,
} from '../../../../common/api/endpoints/team';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import Thumbnail from '../../../partials/Thumbnail/Thumbnail';
import { unite } from '../../../../common/helpers/unite';
import Breadcrumb from '../../../partials/Breadcrumb/Breadcrumb';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import { IBoardListQuery } from '../../../../common/interfaces/BoardListQuery';
import { BoardDTO } from '../../../../common/api/dtos/Board';
import MessageBar from '../../../controls/MessageBar/MessageBar';

interface FormData {
  name: string;
  description: string;
  cardNrPrefix: string;
  color: string;
  thumbnail: string; // Changed from pattern to thumbnail
}

interface RouteParams {
  id: string;
  name: string;
}

type ExternalProps = RouteComponentProps<RouteParams>;

type FormErrors = {
  [key in keyof FormData]?: string;
};

type Props = ExternalProps;

interface State {
  formStatus: TRequestStatus;
  formData: FormData;
  formErrors: FormErrors;
  status: {
    freeBoards: TRequestStatus;
    teams: TRequestStatus;
  };
  hasFreeBoards: boolean;
  teams: TeamDTO[];
  space: string;
}

class BoardAdd extends Component<Props, State> {
  formSchema = Joi.object({
    name: Joi.string().required().trim(true).messages(FormErrorMsgs.string),
    description: Joi.string().trim(true).allow(null, ''),
    cardNrPrefix: Joi.string().trim(true).allow(null, ''),
    color: Joi.string().required().trim(true).messages(FormErrorMsgs.string),
    thumbnail: Joi.string()
      .trim(true)
      .messages(FormErrorMsgs.string)
      .allow(null, ''), // Changed from pattern to thumbnail
  });

  constructor(props: Props) {
    super(props);

    this.state = {
      formStatus: 'idle',
      formData: {
        name: '',
        description: '',
        cardNrPrefix: '',
        color: 'green',
        thumbnail: '', // Changed from pattern to thumbnail
      },
      hasFreeBoards: true,
      status: {
        freeBoards: 'idle',
        teams: 'idle',
      },
      teams: [],
      space: this.props.match.params.id
        ? this.props.match.params.id
        : 'personal',
      formErrors: {},
    };
  }

  componentDidMount() {
    this.fetchAllTeams();

    const hasPro: boolean =
      this.evalSubscriptionStatus() === 'subscribed' ||
      this.evalSubscriptionStatus() === 'willCancel'
        ? true
        : false;
    if (!hasPro) {
      this.fetchBoardsForContext(); // Fetch personal boards
    }
  }

  fetchBoardsForContext = async () => {
    const query: IBoardListQuery = {
      name: 'personal',
      params: [{ param: 'archived', value: false }],
    };

    const queryArchived: IBoardListQuery = {
      name: 'personal',
      params: [{ param: 'archived', value: true }],
    };

    // Set loading status for the specific context
    this.setState((prevState) => ({
      status: {
        ...prevState.status,
        freeBoards: 'loading',
      },
    }));

    try {
      const { boards } = await listBoards(query);
      const { boards: archivedBoards } = await listBoards(queryArchived);

      this.setState((prevState) => ({
        hasFreeBoards:
          boards.some((board: BoardDTO) => board.paid === false) &&
          archivedBoards.some((board: BoardDTO) => board.paid === false),
        status: {
          ...prevState.status,
          freeBoards: 'success',
        },
      }));
    } catch (err) {
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          freeBoards: 'error',
        },
      }));
    }
  };

  // Updated fetchAllTeams function
  fetchAllTeams = async () => {
    this.setState({
      status: {
        ...this.state.status,
        teams: 'loading',
      },
    });

    try {
      let allTeams: TeamDTO[] = [];
      let nextPage: string | null = null;

      do {
        const {
          teams,
          nextPage: newNextPage,
        }: { teams: TeamDTO[]; nextPage: string | null } = await listTeams(
          '100',
          nextPage,
        ); // Fetch 100 teams per request
        allTeams = [...allTeams, ...teams];
        nextPage = newNextPage;
      } while (nextPage);

      this.setState({
        teams: allTeams.filter((team) => team.subscription.deleteAt == null),
        status: {
          ...this.state.status,
          teams: 'success',
        },
      });

      document
        .getElementsByTagName('html')[0]
        .classList.add('has-board-context');
    } catch (err) {
      this.setState({
        status: {
          ...this.state.status,
          teams: 'error',
        },
      });
      console.error(err);
    }
  };

  componentWillUnmount(): void {
    this.previewAccent('green');
    document
      .getElementsByTagName('html')[0]
      .classList.remove('has-board-context');
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          [field]: value,
        },
      },
      () => {
        this.validateFormField(field);
      },
    );
  }

  setName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('name', ev.target.value);
  setDescription = (ev: ChangeEvent<HTMLTextAreaElement>) =>
    this.updateForm('description', ev.target.value);
  setPrefix = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('cardNrPrefix', ev.target.value);
  setColor = (
    ev:
      | React.MouseEvent<HTMLLIElement>
      | KeyboardEvent<Element>
      | ChangeEvent<HTMLInputElement>,
    color: number | string | TColor | undefined,
  ) => {
    this.updateForm('color', String(color));
    this.previewAccent(String(color));
  };
  setThumbnail = (
    ev:
      | React.MouseEvent<HTMLLIElement>
      | KeyboardEvent<Element>
      | ChangeEvent<HTMLInputElement>,
    thumbnail: number | string | TThumbnails | undefined,
  ) => {
    this.updateForm('thumbnail', String(thumbnail)); // Changed from pattern to thumbnail
  };

  setSpace = (
    ev: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<Element>,
    value: unknown,
  ) => {
    this.setState({ space: value as string });
  };

  previewAccent = (color: number | string | TColor | undefined) => {
    document.getElementsByTagName('html')[0].classList.remove('red-accent');
    document.getElementsByTagName('html')[0].classList.remove('green-accent');
    document.getElementsByTagName('html')[0].classList.remove('yellow-accent');
    document.getElementsByTagName('html')[0].classList.remove('blue-accent');
    document.getElementsByTagName('html')[0].classList.remove('purple-accent');
    document.getElementsByTagName('html')[0].classList.remove('pink-accent');
    document.getElementsByTagName('html')[0].classList.add(color + '-accent');
    document.getElementsByTagName('html')[0].classList.add('has-board-context');
  };

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isValid = this.validateForm();
    const isTeamBoard = Boolean(this.state.space !== 'personal');

    if (isValid) {
      this.setState({
        formStatus: 'loading',
      });

      try {
        if (isTeamBoard) {
          await createTeamBoard(this.state.space, this.state.formData);
        } else {
          await addBoard(this.state.formData);
        }

        this.setState({
          formStatus: 'success',
        });

        // redirect to home page
        this.props.history.push('/');
      } catch (err) {
        this.setState(
          {
            formStatus: 'error',
          },
          () => {
            showErrorNotifications(err, this.context.notifications.setMessages);
          },
        );
      }
    }
  };

  evalSubscriptionStatus = () => {
    let subscription:
      | 'subscribed'
      | 'unsubscribed'
      | 'willCancel'
      | 'willDelete'
      | 'none' = 'none';

    if (this.context.loggedUser.subscription) {
      if (this.context.loggedUser.subscription.deleteAt) {
        subscription = 'willDelete';
      } else if (this.context.loggedUser.subscription.cancelAt) {
        subscription = 'willCancel';
      } else {
        subscription = 'subscribed';
      }
    } else {
      subscription = 'unsubscribed';
    }
    return subscription;
  };

  render() {
    const options = this.state.teams
      .filter((team) => team.role === 'owner' || team.role === 'admin')
      .map((team) => {
        return { id: team.id, label: team.name };
      })
      .concat({ id: 'personal', label: 'Private' });

    const moveTeamToFront = (id: string) => {
      const index = options.findIndex(function (team) {
        return team.id === id;
      });

      if (index !== -1) {
        const teamToMove = options.splice(index, 1)[0];
        options.unshift(teamToMove);
      }
    };

    moveTeamToFront(
      this.props.match.params.id ? this.props.match.params.id : 'personal',
    );

    return (
      <div className="flex-row pt-0">
        <div className="column py-0 small">
          <div style={{ position: 'sticky', top: '46px' }}>
            <div className="flex-row fill py-0">
              <div className="column navigation-component px-0">
                <ul className="nav-list">
                  <li className="nav-li">
                    <NavLink
                      to="/board-add"
                      className="nav-link"
                    >
                      <div className="content-wrapper py-3xs">
                        <span className="fal fa-layer-plus icon"></span>
                        <span className="link-text">Create board</span>
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-li">
                    <NavLink
                      to="/team-add"
                      className="nav-link"
                    >
                      <div className="content-wrapper py-3xs">
                        <span className="fal fa-plus-square icon"></span>
                        <span className="link-text">Create team</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="column py-0 largest">
          <div
            className="fill"
            style={{
              maxWidth: '1024px',
              width: '100%',
            }}
          >
            <div className="flex-row fill">
              <div className="column pb-0">
                <Breadcrumb
                  items={[
                    { label: 'Home', to: '/' },
                    {
                      label: (
                        <>
                          Creating board{' '}
                          <strong>{this.state.formData.name || ''}</strong>
                        </>
                      ),
                      isCurrent: true,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex-row fill">
              <div className="column pb-xs">
                <form
                  onSubmit={(e) => this.handleSubmit(e)}
                  autoComplete="off"
                  noValidate={true}
                >
                  <p className="mb-2xs">
                    <small>General Information</small>
                  </p>
                  <div className="card translucent mb-sm">
                    <div className="flex-row fill">
                      <div className="column large pt-0">
                        <TextBox
                          label="Name"
                          type="text"
                          name="name"
                          id="name"
                          maxLength={50}
                          disabled={this.state.formStatus === 'loading'}
                          onChange={this.setName}
                          value={this.state.formData.name}
                          required={true}
                          br={true}
                          onBlur={(ev) =>
                            this.validateFormField(
                              ev.target.name as keyof FormData,
                            )
                          }
                          error={this.state.formErrors.name}
                        />
                        <ComboBox
                          id="tabNav"
                          value={this.state.space}
                          disabled={false}
                          options={options}
                          onChange={this.setSpace}
                          getValue={(op) => op?.id}
                          getLabel={(op) => op?.label}
                          title={'Space'}
                          required={true}
                          label={'Space'}
                        />
                        {!this.state.hasFreeBoards &&
                          this.state.space == 'personal' && (
                            <div className="mb-xs">
                              <MessageBar
                                type="danger"
                                icon="fa-times-circle"
                                rightContent={
                                  <>
                                    <LinkButton
                                      to="/account/plans"
                                      className="secondary-button no-wrap"
                                    >
                                      <span className="fas fa-rocket icon"></span>
                                      <span>Uprade to pro</span>
                                    </LinkButton>
                                  </>
                                }
                              >
                                Your free plan allows <strong>1</strong> board
                                per private space. To create another, you may
                                upgrade to Borddo Pro for unlimited boards, or
                                archive and then delete your current free board,
                                or pick a different space.
                              </MessageBar>
                            </div>
                          )}
                        <div className="mb-sm">
                          <MessageBar
                            type="warning"
                            icon="fa-exclamation-triangle"
                          >
                            Board space cannot be changed later.
                          </MessageBar>
                        </div>
                        <TextArea
                          label="Description"
                          id="description"
                          name="description"
                          disabled={this.state.formStatus === 'loading'}
                          value={this.state.formData.description}
                          onChange={this.setDescription}
                          maxLength={255}
                          rows={3}
                          cols={30}
                          onBlur={(ev) =>
                            this.validateFormField(
                              ev.target.name as keyof FormData,
                            )
                          }
                          error={this.state.formErrors.description}
                          br={true}
                        />
                        <TextBox
                          label="Prefix"
                          type="text"
                          name="itemPrefix"
                          id="itemPrefix"
                          maxLength={8}
                          disabled={this.state.formStatus === 'loading'}
                          onChange={this.setPrefix}
                          value={this.state.formData.cardNrPrefix}
                          required={false}
                          autoComplete="off"
                          br={true}
                          onBlur={(ev) =>
                            this.validateFormField(
                              ev.target.name as keyof FormData,
                            )
                          }
                          error={this.state.formErrors.cardNrPrefix}
                        />
                        <div className="pb-xs">
                          <small>Card No. Preview: </small>{' '}
                          <span className="faint-text">
                            #
                            {this.state.formData.cardNrPrefix
                              ? this.state.formData.cardNrPrefix + '-'
                              : ''}
                            42
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mb-2xs">
                    <small>Color</small>
                  </p>
                  <div className="card translucent mb-sm">
                    <div className="flex-row fill">
                      <div className="column large py-0">
                        <ul className="control-list-component">
                          {COLORS.filter((color: TColor) => {
                            return !['orange', 'cyan'].includes(color.id);
                          }).map((color: TColor) => {
                            return (
                              <li
                                className="text-center"
                                key={color.id}
                              >
                                <label
                                  htmlFor={color.label}
                                  className={`fas fa-square accent-text-${color.id}`}
                                  style={{ fontSize: '48px' }}
                                  title={color.label}
                                ></label>
                                <br />
                                <Radio
                                  id={color.label}
                                  name={color.label}
                                  checked={
                                    color.id === this.state.formData.color
                                  }
                                  label={color.label}
                                  className={'mr-0'}
                                  srOnly={true}
                                  changeMethod={(
                                    ev: ChangeEvent<HTMLInputElement>,
                                  ) => this.setColor(ev, color.id)}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p className="mb-2xs">
                    <small>Thumbnail</small>
                  </p>
                  <div className="card translucent">
                    <div className="flex-row fill">
                      <div className="column large pt-0 pb-0">
                        <ul
                          className="control-list-component"
                          style={{ maxWidth: '724px' }}
                        >
                          {THUMBNAILS.map((thumbnail: TThumbnails) => {
                            return (
                              <li
                                className="text-center"
                                key={thumbnail.value}
                              >
                                <label
                                  htmlFor={thumbnail.label}
                                  className="thumbnail-label"
                                  title={thumbnail.label}
                                >
                                  <div
                                    className={unite(
                                      'thumbnail-frame-component',
                                      this.state.formData.color
                                        ? `accent-${this.state.formData.color}`
                                        : 'accent-green',
                                    )}
                                  >
                                    <Thumbnail
                                      thumbnailData={thumbnail.value}
                                      classes="radius size-64"
                                      title={
                                        thumbnail.label ||
                                        this.state.formData.name
                                      }
                                    />
                                  </div>
                                </label>
                                <br />
                                <Radio
                                  id={thumbnail.label}
                                  name={thumbnail.label}
                                  checked={
                                    thumbnail.value ===
                                    this.state.formData.thumbnail
                                  }
                                  label={thumbnail.label}
                                  className={'mr-0'}
                                  srOnly={true}
                                  changeMethod={(
                                    ev: ChangeEvent<HTMLInputElement>,
                                  ) => this.setThumbnail(ev, thumbnail.value)}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="primary-button mt-sm"
                    type="submit"
                    disabled={this.state.formStatus === 'loading'}
                  >
                    <RequestStatus status={this.state.formStatus} />
                    <span className="text">Create board</span>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BoardAdd;
BoardAdd.contextType = AppContext;
