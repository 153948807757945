import { Component } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import PageMessage from '../partials/PageMessage/PageMessage';
import AppContext from '../../common/contexts/AppContext';
import { TRequestStatus } from '../../common/types/RequestStatus';
import { ErrorNotificationPayload } from '../../common/helpers/errorNotificationPayload';
import {
  confirmChangeEmail,
  getLoggedUser,
} from '../../common/api/endpoints/user';
import { UserDTO } from '../../common/api/dtos/User';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  setLoggedUser: (loggedUser: UserDTO) => void;
}

interface State {
  confirmStatus: TRequestStatus;
  title: string;
  message: string;
}

class ConfirmEmailChange extends Component<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmStatus: 'loading',
      title: 'Change Email Confirmation',
      message: '',
    };
  }

  componentDidMount(): void {
    this.confirmChangeEmail();
  }

  confirmChangeEmail = async () => {
    try {
      await confirmChangeEmail(this.props.match.params.token);
      this.setState({
        message: 'Email Changed Successfully!',
        confirmStatus: 'success',
      });

      const loggedUser = await getLoggedUser();
      this.props.setLoggedUser(loggedUser.user);
    } catch (err) {
      const errors = Array.isArray(err) ? err : [err];
      if (errors[0] instanceof ErrorNotificationPayload) {
        this.setState({
          title: 'Expired Link',
          message: errors[0].validationCode
            ? (errors[0].path !== null ? errors[0].path : '') +
              errors[0].validationCode
            : errors[0].message
              ? errors[0].message
              : '',
          confirmStatus: 'error',
        });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.confirmStatus !== 'loading' && (
          <PageMessage>
            <h1 className="primary-title normalcase pb-xs">
              {this.state.title}
            </h1>
            <p>{this.state.message}</p>
            <ul className="control-list-component flex-h-center">
              <li>
                <NavLink
                  to={`/`}
                  className={'secondary-button'}
                >
                  Homepage
                </NavLink>
              </li>
            </ul>
          </PageMessage>
        )}
      </>
    );
  }
}

export default ConfirmEmailChange;
