import React, { useCallback, useState, useEffect } from 'react';
import Button from '../../../controls/Button/Button';
import { t } from 'i18next';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createCheckoutSession } from '../../../../common/api/endpoints/checkout';
import Stepper from '../../../controls/Stepper/Stepper';
import ElementScrollbar from '../../../partials/Scrollbars/ElementScrollbar';
import { listTeams } from '../../../../common/api/endpoints/team';

const stripePromise = loadStripe(
  'pk_test_51OZWwyG9K8OEhwM3whHM3pr3BPC1HFhKdhpHOyaaN70NWlo2KyzNO14tl2aUnNi7yCwgsQb5Fs8tP9qWijO4FnKP003DymCJrr',
);

interface Props {
  uxStep: number;
  peopleCount: number;
  onNext: () => void;
  onPrev: () => void;
}

interface CheckoutFormProps {
  name: string;
  seats: number;
  mode: string;
  priceId: string;
  onNext: () => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  name,
  seats,
  mode,
  priceId,
  onNext,
}) => {
  const fetchClientSecret = useCallback(() => {
    const payload = {
      name,
      seats,
      trial: name === 'Trial',
    };

    return createCheckoutSession(payload).then((data) => {
      console.log('Checkout session response:', data); // Add this line to inspect the response
      return data.clientSecret;
    });
  }, [name, seats, mode, priceId]);

  const options = { fetchClientSecret, onComplete: () => onNext };

  return (
    <div
      id="checkout"
      style={{
        margin: '-20px',
        borderRadius: '8px',
        overflow: 'hidden',
        maxHeight: '580px',
        overflowY: 'scroll',
      }}
    >
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <ElementScrollbar absolute={true} />
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

const StepStripe: React.FC<Props> = ({
  uxStep,
  peopleCount,
  onNext,
  onPrev,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(
    undefined,
  );
  const [innerStep, setInnerStep] = useState('stepChoice');

  useEffect(() => {
    const checkTeamExists = async () => {
      try {
        const { teams } = await listTeams('1');
        const validTeam = teams.find((team) => team.subscription !== null);

        if (validTeam) {
          onNext(); // Move to the next step
        }
      } catch (err) {
        console.debug(err);
      }
    };

    checkTeamExists();
  }, [onNext]);

  const handleCheckoutStep = (ev: React.MouseEvent<HTMLInputElement>) => {
    setSelectedPlan(ev.currentTarget.value);
    setInnerStep('stepCheckout');
  };

  const renderCheckoutForm = () => {
    if (selectedPlan === 'Trial') {
      return (
        <CheckoutForm
          key="Trial" // Add key to force re-render
          name="Trial"
          seats={peopleCount}
          mode="subscription"
          priceId="price_1OZYqaG9K8OEhwM3W67bg9k6"
          onNext={onNext}
        />
      );
    } else if (selectedPlan === 'Paid Team') {
      return (
        <CheckoutForm
          key="Paid Team" // Add key to force re-render
          name="Paid Team"
          seats={peopleCount}
          mode="payment"
          priceId="price_1OZqFnG9K8OEhwM3hwQ3FxsT"
          onNext={onNext}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-credit-card"></span>
        </div>
        <div className="column large text-left flex-v-center">
          <div className="fill">
            <div className="flex-v-center">
              <Button
                className="ghost-button mr-xs"
                onClick={() => {
                  innerStep == 'stepCheckout'
                    ? setInnerStep('stepChoice')
                    : onPrev();
                }}
              >
                <span className="fas fa-arrow-left"></span>
                <span className="sr-only">{t('stepStripe:back')}</span>
              </Button>
              <h2 className="mb-0">
                {t('stepStripe:stepStripe.title', { count: uxStep })}
              </h2>
            </div>
            <Stepper
              steps={{
                stepChoice: (
                  <>
                    {peopleCount <= 5 && (
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="plan-trial"
                          name="plan"
                          value="Trial"
                          defaultChecked={false}
                          onClick={handleCheckoutStep}
                        />
                        <label htmlFor="plan-trial">
                          <div className="flex-row no-reflow">
                            <div className="column icon-column smallest">
                              <span className="text-2xl fas fa-gift"></span>
                            </div>
                            <div className="column text-column largest flex-v-center">
                              {t('stepStripe:trialPlan')}
                            </div>
                          </div>
                        </label>
                      </div>
                    )}
                    <div className="radio-container mb-0">
                      <input
                        type="radio"
                        id="plan-paid"
                        name="plan"
                        value="Paid Team"
                        defaultChecked={false}
                        onClick={handleCheckoutStep}
                      />
                      <label htmlFor="plan-paid">
                        <div className="flex-row no-reflow">
                          <div className="column icon-column smallest">
                            <span className="text-2xl fas fa-briefcase"></span>
                          </div>
                          <div className="column text-column largest flex-v-center">
                            {t('stepStripe:paidPlan')}
                          </div>
                        </div>
                      </label>
                    </div>
                  </>
                ),
                stepCheckout: (
                  <>
                    {selectedPlan && (
                      <div className="reveal-up-1 mt-0">
                        {renderCheckoutForm()}
                      </div>
                    )}
                  </>
                ),
              }}
              currentStep={innerStep}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StepStripe;
