import React from 'react';
import LinkButton from '../../controls/LinkButton/LinkButton';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

type Props = WithTranslation;

class NewMenu extends React.Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <>
        <li>
          <LinkButton
            to="/board-add"
            className="ghost-button"
          >
            <div
              style={{ maxWidth: '250px', whiteSpace: 'normal' }}
              className="flex-row no-reflow"
            >
              <div className="column smallest text-center">
                <span className="fas fa-layer-plus"></span>
              </div>
              <div className="column largest">
                <span>{t('board')}</span> <br />
                <span className="faint-text pt-xs">
                  {t('boardDescription')}
                </span>
              </div>
            </div>
          </LinkButton>
        </li>
        <li>
          <LinkButton
            to="/team-add"
            className="ghost-button"
          >
            <div
              style={{ maxWidth: '250px', whiteSpace: 'normal' }}
              className="flex-row no-reflow"
            >
              <div className="column smallest text-center">
                <span className=" fas fa-users"></span>
              </div>
              <div className="column largest">
                <span>{t('team')}</span> <br />
                <span className="faint-text pt-xs">{t('teamDescription')}</span>
              </div>
            </div>
          </LinkButton>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('newMenu')(NewMenu);
