import React, { MouseEvent } from 'react';
import Button from '../../controls/Button/Button';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import DirectionalButton from '../../controls/DirectionalButton/DirectionalButton';
import { PRIORITY_COLORS } from '../../../common/configs/priority';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import BoardContext from '../../../common/contexts/BoardContext';
import AppContext, { IAppContext } from '../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../common/contexts/NotificationsContext';
import selectTutorial from '../../../common/helpers/selectTutorial';
import { updateTutorialStep } from '../../../common/helpers/tutorialHelper';
import { updateCard } from '../../../common/api/endpoints/card';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import { UserDTO } from '../../../common/api/dtos/User';
import { IClientData } from '../../../common/interfaces/ClientData';
import {
  IBoardContext,
  UpdateCardData,
} from '../../../common/interfaces/BoardContext';
import { TColor } from '../../../common/types/Color';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  updateClientData: (clientData: Partial<IClientData>) => void;
  loggedUser: UserDTO;
}

interface BoardContextProps {
  isMember: boolean;
  priorities: PriorityDTO[];
  updateCard: (cardId: string, data: UpdateCardData) => void;
}

interface ExternalProps {
  handleSelectPriority?: (priority: PriorityDTO | null) => void;
  cardId?: string;
  selectedPriority: string | null;
  directionalButton?: IDirectionalButton;
}
interface Props
  extends ExternalProps,
    BoardContextProps,
    AppContextProps,
    WithTranslation {}

class PriorityMenu extends React.Component<Props> {
  onPriorityClick = async (ev: MouseEvent<HTMLButtonElement>) => {
    const priorityId = ev.currentTarget.value;
    this.updatePriority(priorityId);
  };

  onPriorityClear = () => {
    this.updatePriority(null);
  };

  updatePriority = async (priorityId: string | null) => {
    // Use `handleSelectPriority` when provided
    if (this.props.handleSelectPriority) {
      const priority =
        priorityId === null
          ? null
          : this.props.priorities.find((p) => p.id === priorityId)!;
      this.props.handleSelectPriority!(priority);
      return;
    }

    // Directly update the card if one is provided to us
    if (this.props.cardId) {
      const propToUpdate = { priorityId };
      const card = await updateCard(this.props.cardId, propToUpdate);
      this.props.updateCard(card.id, propToUpdate);
      this.tutorialAction_changePriority();
      return;
    }

    throw new Error(
      'Expected `handleSelectPriority` or `card` to be present in props',
    );
  };

  tutorialAction_changePriority = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'solo') return;
      const stepId = 'assign_priority';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  render() {
    const { t } = this.props;
    const noneSelected = !this.props.selectedPriority;

    return (
      <>
        {this.props.directionalButton && (
          <>
            <li>
              <DirectionalButton
                directionalButton={{
                  text: this.props.directionalButton.text,
                  direction: this.props.directionalButton.direction,
                  onClick: this.props.directionalButton.onClick,
                }}
                className={`flex-h-spread ${
                  this.props.isMember ? 'disabled' : ''
                }`}
              />
            </li>
            <li>
              <hr />
            </li>
          </>
        )}
        <li className={[noneSelected ? 'active' : ''].join(' ')}>
          <Button
            className={[
              'ghost-button',
              noneSelected ? 'active disabled' : '',
            ].join(' ')}
            value="None"
            onClick={this.onPriorityClear}
            disabled={noneSelected}
          >
            <span className="icon fal fa-circle"></span>
            <span className="text">{t('none')}</span>
          </Button>
        </li>
        {this.props.priorities.map((dbPriority: PriorityDTO) => {
          const priority: TColor = PRIORITY_COLORS.find(
            (p) => p.id === dbPriority.color,
          ) ?? { id: '', label: '', color: '', symbol: '' };
          const isActive = this.props.selectedPriority === dbPriority.id;
          return (
            <li
              className={[isActive ? 'active' : ''].join(' ')}
              key={priority.id}
            >
              <Button
                className={[
                  'ghost-button',
                  isActive ? 'active disabled' : '',
                ].join(' ')}
                value={dbPriority.id}
                onClick={this.onPriorityClick}
                disabled={isActive}
              >
                <span className={`icon accent-text-${priority.color}`}>
                  <span className={`icon mr-0 ${priority.symbol} `}></span>
                </span>{' '}
                <span className="text">{dbPriority.name}</span>
              </Button>
            </li>
          );
        })}
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      updateClientData: ctx.updateClientData!,
      loggedUser: ctx.loggedUser!,
      setMessages: ctx.notifications.setMessages!,
    };
  },
};

const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      isMember: ctx.board.user.role === 'member',
      priorities: ctx.board.priorities,
      updateCard: ctx.updateCard,
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('priorityMenu')(PriorityMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
