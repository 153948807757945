import { BoardDTO, BoardSettingsDTO } from '../dtos/Board';
import { MemberDTO } from '../dtos/Member';
import { TeamDTO, TeamSettingsDTO } from '../dtos/Team';
import requests from './baseRequest';

export async function getTeam(id: string): Promise<TeamDTO> {
  const result = await requests.get(`team/${id}`);
  return await result.json();
}

export async function listTeams(
  pageLength: string | undefined = '10',
  nextPage: string | null = null,
): Promise<{ teams: TeamDTO[]; nextPage: string | null }> {
  const result = await requests.get(
    `team?pageLength=${pageLength}${nextPage ? `&nextPage=${nextPage}` : ''}`,
  );
  return await result.json();
}

export async function deleteTeam(id: string) {
  await requests.delete(`team/${id}`);
}

export async function leaveTeam(id: string) {
  await requests.patch(`team/${id}/leave`);
}

export async function updateTeamSettings(
  id: string,
  body: TeamSettingsDTO,
): Promise<Partial<TeamDTO>> {
  const result = await requests.patch(`team/${id}`, body);
  return result.json();
}

export async function searchTeamUsers(
  teamId: string,
  query: string,
): Promise<any> {
  const result = await requests.get(
    `team/${teamId}/inviteSearch?value=${query}`,
  );
  if (result.status === 204) {
    return { isValidHandle: true };
  }
  return await result.json();
}

export async function updateMemberRole(
  teamMemberId: string,
  role: string,
): Promise<MemberDTO> {
  const result = await requests.patch(`teamMember/${teamMemberId}`, { role });
  return await result.json();
}

export async function removeMemberFromTeam(
  teamMemberId: string,
): Promise<void> {
  await requests.delete(`teamMember/${teamMemberId}`);
}

export async function createTeamBoard(
  teamId: string,
  body: BoardSettingsDTO,
): Promise<BoardDTO | void> {
  const result = await requests.post(`team/${teamId}/board`, body);

  if (result.status === 204) {
    return;
  }
  return await result.json();
}
