import { IClientData } from '../../interfaces/ClientData';
import { LoginUserDTO, UserDTO } from '../dtos/User';
import requests from './baseRequest';

export async function getLoggedUser(): Promise<{ user: UserDTO }> {
  const result = await requests.get('self');
  return await result.json();
}

export async function updateName(name: string): Promise<{ user: UserDTO }> {
  const result = await requests.patch('self', { name });
  return await result.json();
}

export async function updateAvatar(avatar: string): Promise<UserDTO> {
  const result = await requests.patch('self', { avatar });
  return await result.json();
}

export async function onboardUser(onboarding: any): Promise<any> {
  const result = await requests.patch('self', { onboarding });
  return await result.json();
}

export async function completeOnboard(): Promise<any> {
  const result = await requests.post('self/onboard');
  return await result.json();
}

export async function login(
  body: LoginUserDTO,
): Promise<UserDTO & { expiresIn: number }> {
  const result = await requests.post('login', body);
  return await result.json();
}

export async function logout() {
  await requests.post('logout');
}

export async function forgotPassword(email: string): Promise<void> {
  await requests.post('forgotPassword', { email });
}

export async function changePassword(
  token: string,
  newPassword: string,
): Promise<void> {
  await requests.post(`changePassword`, {
    token,
    newPassword,
  });
}

export async function changeEmailRequest(email: string): Promise<void> {
  await requests.post('self/changeEmail', { newEmail: email });
}

export async function confirmChangeEmail(token: string): Promise<void> {
  await requests.patch(`self/confirmChangeEmail`, { token });
}

export async function cancelChangeEmail(): Promise<void> {
  await requests.patch('self/cancelChangeEmail');
}

export async function deleteSelf(body: {
  email: string;
  password: string;
}): Promise<void> {
  await requests.post('self/delete', body);
}

export async function confirmDeleteSelf(token: string): Promise<void> {
  await requests.post(`self/confirmDelete`, { token });
}

export async function cancelDeleteSelf(): Promise<void> {
  await requests.patch('self/cancelDelete');
}

export async function patchClientData(clientData: IClientData): Promise<void> {
  const result = await requests.patch('self', { clientData });
  if (!result.ok) {
    throw new Error('Failed to update client data');
  }
  return await result.json();
}

export async function completeTutorial(): Promise<void> {
  await requests.post('self/completeTutorial');
}
