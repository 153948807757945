import React, { useState, useEffect, useRef } from 'react';
import AppContext, { IAppContext } from '../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../common/contexts/NotificationsContext';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import BoardContext from '../../../common/contexts/BoardContext';
import { IBoardContext } from '../../../common/interfaces/BoardContext';
import { unite } from '../../../common/helpers/unite';
import { useHistory } from 'react-router-dom'; // assuming useHistory is in place

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}

interface ExternalProps {
  accordionSlug: string;
  title?: string;
  subheading?: string | JSX.Element;
  disabled?: boolean;
  isOpen?: boolean;
  iconClasses?: string;
  hideArrow?: boolean;
  softDisabled?: boolean;
  loading?: boolean;
  onToggle?: (isOpen: boolean) => void;
  translucent?: boolean;
}

interface BoardContextProps {
  updateCard: (cardId: string, data: Record<string, unknown>) => void;
}

interface Props extends ExternalProps, AppContextProps, BoardContextProps {}

const Accordion: React.FC<Props> = ({
  accordionSlug,
  title,
  subheading,
  disabled,
  isOpen: initialIsOpen = false,
  iconClasses,
  hideArrow,
  softDisabled,
  loading,
  onToggle,
  translucent,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [isPristine, setIsPristine] = useState(true);
  const accordionRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    if (window.location.href.includes(accordionSlug)) {
      updateAccordionOnId();
    }
  }, [accordionSlug]);

  const toggleAccordion = () => {
    const updatedIsOpen = !isOpen;
    if (isPristine) {
      setIsPristine(false);
    }
    setIsOpen(updatedIsOpen);
    if (onToggle) {
      onToggle(updatedIsOpen);
    }
  };

  const updateAccordionOnId = () => {
    setIsOpen(true);
    window.scrollTo({
      top: accordionRef.current?.offsetTop,
      behavior: 'smooth',
    });

    // Clear URL hash
    const { pathname } = history.location;
    history.replace(pathname);
  };

  return (
    <div
      className="form-group mb-0"
      ref={accordionRef}
      id={accordionSlug}
    >
      <div
        className={unite({
          accordion: true,
          open: isOpen && !loading,
          'initially-open': initialIsOpen && isPristine,
          'fade-in-1': loading,
        })}
      >
        <button
          className={unite({
            'accordion-button': true,
            'soft-disabled': softDisabled,
            'hide-arrow': hideArrow,
            translucent: translucent,
          })}
          disabled={disabled}
          onClick={toggleAccordion}
        >
          <div className="flex-row no-reflow squeeze flex-v-center">
            <div className="column py-0">
              <span
                className={iconClasses}
                data-testid="accordion-icon"
              ></span>
            </div>
            <div className="column py-0">
              <span>{title}</span>
              {subheading && (
                <>
                  <br />
                  <small className="faint-text">{subheading}</small>
                </>
              )}
            </div>
          </div>
          <span className="icon-helper fas fa-angle-down"></span>
        </button>
        <div className="accordion-body">
          <div
            className={unite({
              'accordion-inner': true,
              translucent: translucent,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => ({
    setMessages: ctx.notifications.setMessages!,
  }),
};

const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => ({
    updateCard: ctx.updateCard,
  }),
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(Accordion, AppContextAdapter, BoardContextAdapter);
