import React, { Component } from 'react';
import Dialog from '../../controls/Dialog/Dialog';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import AppContext from '../../../common/contexts/AppContext';
import { selectWelcome } from '../../../common/helpers/selectWelcome';
import { patchClientData } from '../../../common/api/endpoints/user';

interface Props extends WithTranslation {
  noBoards?: boolean;
  boardName?: string;
}

interface State {
  showWelcomeDialog: boolean;
}

class WelcomeDialog extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      showWelcomeDialog: false,
    };
  }

  componentDidMount(): void {
    const { loggedUser } = this.context;
    const welcomeDialogClosed = loggedUser.clientData.welcomeClosed;
    if (!welcomeDialogClosed) {
      this.setState({ showWelcomeDialog: true });
    }
  }

  onClose = async () => {
    const { loggedUser, updateClientData } = this.context;
    await patchClientData({
      ...loggedUser.clientData,
      welcomeClosed: true,
    });
    updateClientData({
      ...loggedUser.clientData,
      welcomeClosed: true,
    });
    this.setState({ showWelcomeDialog: false });
  };

  getWelcomeContent = () => {
    const { t, noBoards } = this.props;
    const { loggedUser } = this.context;
    const welcomeType = selectWelcome(loggedUser);
    const boardName =
      loggedUser.onboarding.boardName ?? this.props.boardName ?? '';
    const userName = loggedUser.name.split(' ')[0];

    let contentKey = '';
    let contentValues = {};
    let buttonText = '';

    if (welcomeType === 'invited') {
      const teamName = loggedUser.onboarding.teamName;
      const teamOwnerName = loggedUser.onboarding.teamOwnerName;

      if (noBoards === false) {
        contentKey = 'welcomeDialog:invited_message_board';
        contentValues = { teamName, teamOwnerName, boardName };
        buttonText = t('go_to_board', { boardName });
      } else {
        contentKey = 'welcomeDialog:invited_message_team';
        contentValues = { teamName, teamOwnerName };
        buttonText = t('explore_borddo');
      }
    } else {
      const { familiarity, organizingWith, goal } = welcomeType;

      if (organizingWith === 'justMe') {
        if (goal === 'personal') {
          if (familiarity === 'exploring') {
            contentKey = 'welcomeDialog:solo_personal_beginner';
            contentValues = { userName };
            buttonText = t('explore_board', { boardName });
          } else if (familiarity === 'usedBefore') {
            contentKey = 'welcomeDialog:solo_personal_intermediate';
            contentValues = { userName };
            buttonText = t('explore_board', { boardName });
          } else if (familiarity === 'expert') {
            contentKey = 'welcomeDialog:solo_personal_expert';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          }
        } else if (goal === 'work') {
          if (familiarity === 'exploring') {
            contentKey = 'welcomeDialog:solo_work_beginner';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          } else if (familiarity === 'usedBefore') {
            contentKey = 'welcomeDialog:solo_work_intermediate';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          } else if (familiarity === 'expert') {
            contentKey = 'welcomeDialog:solo_work_expert';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          }
        }
      } else if (organizingWith === 'team') {
        if (goal === 'personal') {
          if (familiarity === 'exploring') {
            contentKey = 'welcomeDialog:team_personal_beginner';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          } else if (familiarity === 'usedBefore') {
            contentKey = 'welcomeDialog:team_personal_intermediate';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          } else if (familiarity === 'expert') {
            contentKey = 'welcomeDialog:team_personal_expert';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          }
        } else if (goal === 'work') {
          if (familiarity === 'exploring') {
            contentKey = 'welcomeDialog:team_work_beginner';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          } else if (familiarity === 'usedBefore') {
            contentKey = 'welcomeDialog:team_work_intermediate';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          } else if (familiarity === 'expert') {
            contentKey = 'welcomeDialog:team_work_expert';
            contentValues = { userName, boardName };
            buttonText = t('explore_board', { boardName });
          }
        }
      }
    }

    return {
      contentHtml: t(contentKey, contentValues),
      buttonText,
      titleText: t('welcome_to_borddo', { userName }),
    };
  };

  render() {
    if (!this.state.showWelcomeDialog) {
      return null;
    }

    const { contentHtml, buttonText, titleText } = this.getWelcomeContent();

    return (
      <Dialog
        size="large"
        title={titleText}
        confirmText={buttonText}
        onCancel={() => {
          return false;
        }}
        onConfirm={this.onClose}
      >
        <div
          className="tableview-component my-sm us-none"
          dangerouslySetInnerHTML={{ __html: contentHtml }}
        />
      </Dialog>
    );
  }
}

export default withStyledTranslation('welcomeDialog')(WelcomeDialog);
