import { t } from 'i18next';
import { ErrorNotificationPayload } from './errorNotificationPayload';
import { InfoNotificationPayload } from './infoNotificationPayload';
import { SuccessNotificationPayload } from './successNotificationPayload';

export function showErrorNotifications(
  err: any,
  setMessages: (messages: ErrorNotificationPayload[]) => void,
) {
  const errors = Array.isArray(err) ? err : [err];

  const formattedErrors = errors.map((item) => {
    // Ensure path is only included if it has a value
    const path = item.path ? `.${item.path}` : '.unknownPath';

    // Ensure validationCode is valid, otherwise use a placeholder text
    const validationCode = item.validationCode
      ? `.${item.validationCode}`
      : '.unknownCode';

    // Construct the full key
    const translationKey = `msg.error${path}${validationCode}`;

    return new ErrorNotificationPayload(
      item.code,
      item.path,
      item.validationCode,
      t(translationKey),
    );
  });

  setMessages(formattedErrors);
}

export function showSuccessNotifications(
  notifications: string | string[],
  setMessages: (messages: SuccessNotificationPayload[]) => void,
) {
  const notificationsArr = Array.isArray(notifications)
    ? notifications
    : [notifications];

  const notificationList = notificationsArr.map((notification) => {
    return new SuccessNotificationPayload(notification);
  });

  setMessages(notificationList);
}

export function showInfoNotifications(
  notifications: string | string[],
  setMessages: (messages: InfoNotificationPayload[]) => void,
) {
  const notificationsArr = Array.isArray(notifications)
    ? notifications
    : [t(notifications)];

  const notificationList = notificationsArr.map((notification) => {
    return new InfoNotificationPayload(notification);
  });

  setMessages(notificationList);
}
