import { debounce } from 'lodash';
import React, { Component } from 'react';

// TODO: Move it in the form elements folder after merge (20.03.2023)

interface Props {
  name: string;
  value: string;
  label?: string;
  id?: string;
  onChange?: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
  fluidWidth?: boolean;
  disabled?: boolean;
  rows?: number;
  cols?: number;
  onBlur?: (ev: React.FocusEvent<HTMLTextAreaElement>) => void;
  error?: string;
  required?: boolean;
  placeholder?: string;
  br?: boolean;
  noMargin?: boolean;
  autofocus?: boolean;
  className?: string;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  autoHeight?: boolean;
  maxLength?: number;
  showCounter?: boolean;
  onTextAreaHeightChange?: (height: number | undefined) => void;
  noResize?: boolean;
}

interface State {
  textAreaHeight: number | undefined;
  focused: boolean;
}

class TextArea extends Component<Props, State> {
  private controlRef: React.RefObject<HTMLTextAreaElement>;
  private debouncedHandleInput: (ev: Event) => void;

  constructor(props: Props) {
    super(props);
    this.controlRef = React.createRef();
    this.state = {
      textAreaHeight: undefined,
      focused: false,
    };

    this.debouncedHandleInput = debounce(this.handleInput.bind(this), 100);
  }

  componentDidMount(): void {
    if (this.props.autoHeight) {
      this.updateTextAreaHeight();
      window.addEventListener('resize', this.handleResize);
      this.controlRef.current?.addEventListener(
        'input',
        this.debouncedHandleInput,
      );
    }
  }

  componentWillUnmount(): void {
    if (this.props.autoHeight) {
      window.removeEventListener('resize', this.handleResize);
      this.controlRef.current?.removeEventListener(
        'input',
        this.debouncedHandleInput,
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.value !== this.props.value) {
      if (this.props.autoHeight) {
        this.updateTextAreaHeight();
      }
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    return (
      nextProps.value !== this.props.value ||
      nextState.textAreaHeight !== this.state.textAreaHeight ||
      nextState.focused !== this.state.focused
    );
  }

  handleResize = () => {
    if (this.props.autoHeight) {
      this.updateTextAreaHeight();
    }
  };

  handleInput = (ev: Event) => {
    const target = ev.target as HTMLTextAreaElement;
    if (this.props.autoHeight) {
      this.updateTextAreaHeight();
    }
    if (this.props.onChange) {
      // Create a synthetic event to forward the change
      const syntheticEvent = Object.assign({}, ev, {
        target,
        currentTarget: target,
        nativeEvent: ev,
        isDefaultPrevented: () => ev.defaultPrevented,
        isPropagationStopped: () => ev.cancelBubble,
        persist: () => false,
      }) as React.ChangeEvent<HTMLTextAreaElement>;
      this.props.onChange(syntheticEvent);
    }
  };

  updateTextAreaHeight = () => {
    if (this.controlRef.current) {
      // Temporarily remove the height to measure the scroll height accurately
      this.controlRef.current.style.height = 'auto';
      const scrollHeight = this.controlRef.current.scrollHeight;
      const textAreaHeight = scrollHeight + 3; // Adding a small offset
      this.controlRef.current.style.height = textAreaHeight + 'px';
      this.setState({ textAreaHeight });
      if (this.props.onTextAreaHeightChange) {
        this.props.onTextAreaHeightChange(textAreaHeight);
      }
    }
  };

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    this.setState({
      focused: false,
    });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    return (
      <div
        className={`form-group textarea-wrapper ${
          this.props.noMargin ? 'mb-0' : ''
        }`}
      >
        {(this.props.id || this.props.label || this.props.required) && (
          <label htmlFor={this.props.id}>
            {this.props.label}
            {this.props.required && '*'}
          </label>
        )}
        {this.props.br && <br />}
        <textarea
          ref={this.controlRef}
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          rows={this.props.autoHeight ? 1 : this.props.rows}
          placeholder={this.props.placeholder}
          cols={this.props.cols}
          required={this.props.required}
          onBlur={this.onBlur}
          className={`${this.props.fluidWidth ? 'fluid-width' : ' '} ${
            this.props.className ? this.props.className : ''
          } ${this.props.noResize ? 'no-resize' : ''}`}
          aria-invalid={!!this.props.error}
          autoFocus={this.props.autofocus}
          onKeyDown={this.props.onKeyDown}
          style={{
            maxHeight: this.props.autoHeight ? 'none' : undefined,
            resize: this.props.autoHeight ? 'none' : undefined,
            height: this.props.autoHeight
              ? this.state.textAreaHeight + 'px'
              : undefined,
          }}
          maxLength={this.props.maxLength ? this.props.maxLength : 255}
          onFocus={this.onFocus}
        ></textarea>
        {this.props.showCounter && this.state.focused && (
          <span className="textarea-counter flag-text">
            {this.props.value.length}/
            {this.props.maxLength ? this.props.maxLength : 255}
          </span>
        )}
        {this.props.error ? (
          <ul className="error-list light">
            <li>{this.props.error}</li>
          </ul>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default TextArea;
