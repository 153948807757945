import { ErrorNotificationPayload } from './errorNotificationPayload';

export default function errorKeyFormatter(
  err: ErrorNotificationPayload,
): string {
  // Ensure path is only included if it has a value
  const path = err.path ? `.${err.path}` : '.unknownPath';

  // Ensure validationCode is valid, otherwise use a placeholder text
  const validationCode = err.validationCode
    ? `.${err.validationCode}`
    : '.unknownCode';

  // Construct the full key
  const translationKey = `msg.error${path}${validationCode}`;
  return translationKey;
}
