import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export const withStyledTranslation =
  (namespace?: string) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    class HOC extends React.Component<P & WithTranslation> {
      // Use generic type P combined with WithTranslation
      translate = (key: string, options: Record<string, unknown> = {}) => {
        const { t } = this.props;
        const translationOptions = {
          ...options,
          defaultValue: undefined,
          ns: namespace,
        };
        const translation = t(key, translationOptions);
        if (
          typeof translation === 'string' &&
          translation.startsWith('[missing:')
        ) {
          return (
            <span
              style={{
                color: '#ff9999',
                filter:
                  'drop-shadow(0 0 0px rgba(255, 153, 153, 0.5)) drop-shadow(0 0 2px #000000) ',
              }}
            >
              {t(key, { ...options, fallbackLng: 'en_US' })}
            </span>
          );
        }
        return translation;
      };

      render() {
        // Use the rest operator to pass through all props
        const { ...restProps } = this.props;

        return (
          <WrappedComponent
            {...(restProps as P)} // Cast restProps back to P to satisfy TypeScript
            t={this.translate}
          />
        );
      }
    }

    // Apply the namespace to the `withTranslation` HOC if specified
    return namespace ? withTranslation(namespace)(HOC) : withTranslation()(HOC);
  };
