import { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import AccountGeneral from './Account/AccountGeneral';
import { UserDTO } from '../../../common/api/dtos/User';
import MenuTabs from '../../controls/MenuTabs/MenuTabs';
import Subscriptions from './Billing/PlansAndBilling';
import { listTeams } from '../../../common/api/endpoints/team';
import { TeamDTO } from '../../../common/api/dtos/Team';
import Membership from '../../partials/Team/Membership';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import UserOnIcon from '../../animations/icons/user_on';
import UserOffIcon from '../../animations/icons/user_off';
import CircleOff from '../../animations/icons/circle_off';
import CircleOn from '../../animations/icons/circle_on';
import CoinsOn from '../../animations/icons/coins_on';
import CoinsOff from '../../animations/icons/coins_off';
import Application from './Application/Application';
import Experiments from './Experiments/Experiments';

interface RouteParams {
  id?: string;
}

interface Form {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface RequestPayload {
  statusCode: number;
  message: string;
}

interface State {
  form: Form;
  formStatus: TRequestStatus;
  invalidPasswordMatch: boolean;
  formPayload: RequestPayload;
  status: TRequestStatus;
  teams: TeamDTO[];
}

export interface Props
  extends RouteComponentProps<RouteParams>,
    WithTranslation {
  setLoggedUser: (loggedUser: UserDTO) => void;
  loggedUser: UserDTO;
  setLockedAccount: (lockedAccount: boolean) => void;
  lockedAccount: boolean;
}

class Settings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      status: 'loading',
      invalidPasswordMatch: true,
      formStatus: 'idle',
      formPayload: {
        statusCode: -1,
        message: '',
      },
      teams: [],
    };
  }

  componentDidMount() {
    this.fetchAllTeams();
  }

  fetchAllTeams = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      const data = await listTeams();

      this.setState({
        status: 'success',
        teams: data.teams,
      });
    } catch (err) {
      console.debug(err);
    }
  };

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
    localStorage.setItem(
      event.target.name,
      JSON.stringify(event.target.checked),
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="flex-row fill">
        <div className="column">
          <MenuTabs
            parentUrl={this.props.match.url}
            vertical={true}
            tabs={[
              {
                id: 'user-account',
                label: t('accountTabLabel'),
                icon: 'fal fa-circle',
                animatedOffIcon: <CircleOff />,
                animatedOnIcon: <CircleOn />,
                content: (
                  <AccountGeneral
                    setLoggedUser={this.props.setLoggedUser}
                    loggedUser={this.props.loggedUser}
                    history={this.props.history}
                    setLockedAccount={this.props.setLockedAccount}
                    lockedAccount={this.props.lockedAccount}
                  />
                ),
              },
              {
                id: 'plans',
                label: t('plansTabLabel'),
                icon: 'fal fa-coins',
                animatedOffIcon: <CoinsOff />,
                animatedOnIcon: <CoinsOn />,
                content: <Subscriptions history={this.props.history} />,
                disabled: false,
                // iconGradient: 'linear-gradient(to top left, #ffde00, #ff6600)',
              },
              {
                id: 'membership',
                label: t('membershipTabLabel'),
                icon: 'fal fa-id-badge',
                animatedOffIcon: <UserOffIcon />,
                animatedOnIcon: <UserOnIcon />,
                content: <Membership history={this.props.history} />,
              },
              {
                id: 'global-scope',
                label: t('AppSettings'),
                icon: 'fal fa-browser',
                content: <Application {...this.props} />,
              },
              {
                id: 'experiments',
                label: t('Experiments'),
                icon: 'fal fa-flask',
                content: <Experiments {...this.props} />,
              },
            ]}
            defaultTab="user-account"
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('account')(Settings);
