import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoginForm from '../../../forms/LoginForm/LoginForm';
import LegalFooter from '../../../partials/Legal/LegalFooter';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import AnimatedBackground from '../../../animations/AnimatedBackground/AnimatedBackground'; // Import the new component
import '../../../animations/AnimatedBackground/AnimatedBackground.css'; // Import the CSS

export interface Props extends RouteComponentProps, WithTranslation {
  unlockApp: () => void;
}

class Login extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <div
        className="login-component oobe-component"
        style={{ background: 'transparent' }}
      >
        <AnimatedBackground /> {/* Add the animated background */}
        <div className="login-container fill">
          <div className="flex-row fill">
            <div className="column">
              <div className="special-card p-large fade-in-1">
                <div
                  className="oobe-content card opaque px-sm py-md"
                  style={{
                    minHeight: '0',
                  }}
                >
                  <div>
                    <img
                      className="mt-sm"
                      style={{
                        filter: 'drop-shadow(rgb(136, 255, 221) 2px 2px 4px)',
                        maxWidth: '100px',
                      }}
                      src={
                        process.env.PUBLIC_URL +
                        '/' +
                        process.env.REACT_APP_LOGO
                      }
                      alt="Borddo"
                    />
                  </div>
                  <br />
                  <LoginForm
                    unlockApp={this.props.unlockApp}
                    history={this.props.history}
                    location={this.props.location}
                  />
                  <br />
                  <LinkButton
                    to="/forgot-password"
                    className="link-button"
                  >
                    <span className="text">{t('forgotPassword')}</span>
                  </LinkButton>
                  <br />
                  <br />
                  <LinkButton
                    to="/registration"
                    className="link-button"
                  >
                    <span className="text">{t('noAccount')}</span>
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row fill">
            <div className="column">
              <LegalFooter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('login')(Login);
