// common/api/endpoints/subtask.ts

import requests from './baseRequest';

// Subtask DTO
export interface SubtaskDTO {
  id: string;
  title: string;
  checked: boolean;
  createdAt: string;
}

// Create a new subtask
export async function createSubtask(
  cardId: string,
  title: string,
): Promise<SubtaskDTO> {
  const result = await requests.post(`card/${cardId}/subtask`, {
    title,
  });
  return await result.json();
}

// List subtasks for a card
export async function listSubtasks(cardId: string): Promise<SubtaskDTO[]> {
  const result = await requests.get(`card/${cardId}/subtask`);
  const jsonResponse = await result.json();
  return jsonResponse.cardSubtasks;
}

// Update a subtask
export async function updateSubtask(
  subtaskId: string,
  title: string,
  checked: boolean,
): Promise<SubtaskDTO> {
  const result = await requests.patch(`subtask/${subtaskId}`, {
    title,
    checked,
  });
  return await result.json();
}

// Move a subtask to a new index
export async function moveSubtask(
  subtaskId: string,
  newIndex: number,
): Promise<void> {
  await requests.patch(`subtask/${subtaskId}/move`, {
    index: newIndex,
  });
}

// Remove a subtask
export async function removeSubtask(subtaskId: string): Promise<void> {
  await requests.delete(`subtask/${subtaskId}`);
}
