import { useState, useEffect, useContext, useCallback } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import AppContext from '../../../common/contexts/AppContext';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import UserMenu from '../../menus/User/UserMenu';
import LegalMenu from '../../menus/Legal/LegalMenu';
import LinkButton from '../../controls/LinkButton/LinkButton';
import HelpDialog from '../Legal/HelpDialog';

export interface Props {
  handleLogout: () => void;
  lockedAccount?: boolean;
}

const Topbar: React.FC<Props> = ({ handleLogout, lockedAccount }) => {
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const context = useContext(AppContext);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const activeElement = document.activeElement;
    const isInputField =
      activeElement?.tagName.toLowerCase() === 'input' ||
      activeElement?.tagName.toLowerCase() === 'textarea' ||
      activeElement?.hasAttribute('contenteditable');

    if (!isInputField && event.key === '/') {
      event.preventDefault();
      setShowHelpDialog(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      {showHelpDialog && (
        <HelpDialog onClose={() => setShowHelpDialog(false)} />
      )}
      <div
        id="toolbarControls"
        className="toolbar-component bar-content-start"
      >
        <LinkButton
          to="/"
          className="brand-wrapper"
        >
          <div
            className={`dynamic-logo-component ${process.env.REACT_APP_FAVICON}`}
          ></div>
        </LinkButton>
      </div>
      <div className="bar-content-end">
        <nav className="navigation-component horizontal bar-content-start">
          <ul className="nav-list">
            <li className="nav-li">
              <ContextMenu
                dept={0}
                title="Legal menu"
                triggerContent={
                  <>
                    <span className="far fa-question-circle icon"></span>
                  </>
                }
                triggerClassDefault="ghost-button"
                triggerClassActive="secondary-button"
              >
                <LegalMenu onOpenHelpDialog={() => setShowHelpDialog(true)} />
              </ContextMenu>
            </li>
            <li className="nav-li">
              <Route
                render={(props: RouteComponentProps) => (
                  <ContextMenu
                    dept={0}
                    triggerContent={
                      <div className="flex-row py-0 px-0 mx-0">
                        <div className="column flex-v-center py-0 px-0 mx-0">
                          <Thumbnail
                            classes="size-24"
                            avatarData={context.loggedUser?.avatar}
                            title={context.loggedUser?.name}
                          />
                          <span
                            className="icon fas fa-angle-down"
                            style={{ height: '0.8em' }}
                          ></span>
                        </div>
                      </div>
                    }
                    triggerClassDefault="ghost-button"
                    triggerClassActive="secondary-button"
                    {...props}
                  >
                    <UserMenu
                      loggedUser={context.loggedUser}
                      handleLogout={handleLogout}
                      lockedAccount={lockedAccount}
                    />
                  </ContextMenu>
                )}
              />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Topbar;
