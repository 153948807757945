export default function isDescendant(
  parent: HTMLElement | null,
  child: HTMLElement | null,
) {
  if (parent == null || child == null) {
    return false;
  }
  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}
