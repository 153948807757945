import { BoardAccessDTO, BoardDTO, BoardItemDTO } from '../dtos/Board';
import { BoardSettingsDTO } from '../dtos/Board';
import { IBoardListQuery } from '../../interfaces/BoardListQuery';
import requests from './baseRequest';

export async function getAccess(id: string): Promise<BoardAccessDTO> {
  const result = await requests.get(`board/${id}/access`);
  return await result.json();
}

export async function getBoard(id: string): Promise<BoardDTO> {
  const result = await requests.get(`board/${id}`);
  return await result.json();
}

export async function listBoards(
  query: IBoardListQuery,
): Promise<{ boards: BoardDTO[] }> {
  let endpointPath = 'boards';

  if (query.params) {
    query.params.forEach((item, index) => {
      const symbol = index === 0 ? '?' : '&';
      endpointPath += `${symbol}${item.param}=${item.value}`;
    });
  }

  const result = await requests.get(endpointPath);
  return await result.json();
}

export async function addBoard(body: BoardSettingsDTO): Promise<BoardDTO> {
  const result = await requests.post('board', body);
  return await result.json();
}

export async function archiveBoard(id: string) {
  await requests.patch(`board/${id}/archive`);
}

export async function restoreBoard(id: string) {
  await requests.patch(`board/${id}/restore`);
}

export async function deleteBoard(id: string) {
  await requests.delete(`board/${id}`);
}

export async function setFavoriteBoard(
  id: string,
  favorite: boolean,
): Promise<void> {
  const endpointPath = favorite === true ? 'favorite' : 'unfavorite';
  await requests.patch(`board/${id}/${endpointPath}`);
}

export async function getBoardSettings(id: string): Promise<Partial<BoardDTO>> {
  const result = await requests.get(`board/${id}/settings`);
  return await result.json();
}

export async function updateBoardSettings(
  id: string,
  body: BoardSettingsDTO,
): Promise<Partial<BoardDTO>> {
  const result = await requests.put(`board/${id}/settings`, body);
  return result.json();
}

export async function getQuickAccess(): Promise<{ boards: BoardItemDTO[] }> {
  const result = await requests.get(`quickAccess`);
  return await result.json();
}

export async function moveQuickAccess(
  id: string,
  body: {
    index: number;
  },
) {
  await requests.patch(`quickAccess/${id}/move`, body);
}
