import React, { useState, useRef, useEffect } from 'react';
interface ElementScrollbarProps {
  absolute?: boolean;
}
const ElementScrollbar: React.FC<ElementScrollbarProps> = ({ absolute }) => {
  const [thumbSizeV, setThumbSizeV] = useState<number>(0);
  const [thumbPositionV, setThumbPositionV] = useState<number>(0);
  const [thumbSizeH, setThumbSizeH] = useState<number>(0);
  const [thumbPositionH, setThumbPositionH] = useState<number>(0);
  const [showVertical, setShowVertical] = useState<boolean>(false);
  const [showHorizontal, setShowHorizontal] = useState<boolean>(false);
  const [isDraggingV, setIsDraggingV] = useState(false);
  const [startY, setStartY] = useState(0);
  const [isDraggingH, setIsDraggingH] = useState(false);
  const [startX, setStartX] = useState(0);
  const parentHelperRef = useRef<HTMLDivElement>(null);
  const thumbRefV = useRef<HTMLDivElement>(null);
  const thumbRefH = useRef<HTMLDivElement>(null);

  const handleMouseDownV = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDraggingV(true);
    setStartY(e.clientY);
  };

  const handleMouseDownH = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDraggingH(true);
    setStartX(e.clientX);
  };

  useEffect(() => {
    const parentElement = parentHelperRef.current?.parentElement;

    if (!parentElement) return;

    const updateThumbs = () => {
      const {
        scrollHeight,
        clientHeight,
        scrollTop,
        scrollWidth,
        clientWidth,
        scrollLeft,
      } = parentElement;

      if (scrollHeight > clientHeight) {
        const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
        const thumbTop = (scrollTop / scrollHeight) * clientHeight;
        setThumbSizeV(thumbHeight);
        setThumbPositionV(thumbTop);
        setShowVertical(true);
      } else {
        setShowVertical(false);
      }

      if (scrollWidth > clientWidth) {
        const thumbWidth = (clientWidth / scrollWidth) * clientWidth;
        const thumbLeft = (scrollLeft / scrollWidth) * clientWidth;
        setThumbSizeH(thumbWidth);
        setThumbPositionH(thumbLeft);
        setShowHorizontal(true);
      } else {
        setShowHorizontal(false);
      }
    };

    const handleMutation = (mutations: MutationRecord[]) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes' || mutation.type === 'childList') {
          updateThumbs();
        }
      }
    };

    const observer = new MutationObserver(handleMutation);
    observer.observe(parentElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    const handleScroll = () => {
      updateThumbs();
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isDraggingV && parentElement) {
        const deltaY = e.clientY - startY;
        parentElement.scrollTop +=
          (deltaY / parentElement.clientHeight) * parentElement.scrollHeight;
        setStartY(e.clientY);
      }
      if (isDraggingH && parentElement) {
        const deltaX = e.clientX - startX;
        parentElement.scrollLeft +=
          (deltaX / parentElement.clientWidth) * parentElement.scrollWidth;
        setStartX(e.clientX);
      }
    };

    const handleMouseUp = () => {
      setIsDraggingV(false);
      setIsDraggingH(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    parentElement.addEventListener('scroll', handleScroll);
    updateThumbs();

    return () => {
      observer.disconnect();
      parentElement.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDraggingV, startY, isDraggingH, startX]);

  return (
    <>
      <div
        style={{ display: 'none' }}
        ref={parentHelperRef}
      ></div>
      {showVertical && (
        <div
          className="custom-scrollbar-track vertical"
          style={{
            transform: `
              translateX(${
                parentHelperRef.current?.parentElement?.clientWidth
              }px)`,
            position: absolute ? 'absolute' : 'sticky',
          }}
        >
          <div
            className="custom-scrollbar-thumb"
            style={{ height: `${thumbSizeV}px`, top: `${thumbPositionV}px` }}
            ref={thumbRefV}
            onMouseDown={handleMouseDownV}
          ></div>
        </div>
      )}
      {showHorizontal && (
        <div
          className="custom-scrollbar-track horizontal"
          style={{
            transform: `
              translateY(${
                (parentHelperRef.current?.parentElement?.clientHeight ?? 0) -
                (thumbRefH.current?.clientHeight ?? 0)
              }px)`,
          }}
        >
          <div
            className="custom-scrollbar-thumb"
            style={{ width: `${thumbSizeH}px`, left: `${thumbPositionH}px` }}
            ref={thumbRefH}
            onMouseDown={handleMouseDownH}
          ></div>
        </div>
      )}
    </>
  );
};

export default ElementScrollbar;
