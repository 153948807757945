import React, { Component, KeyboardEvent } from 'react';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import {
  DraggableProvided,
  DraggableStateSnapshot,
} from '../../../../vendors/wavemyth/react-beautiful-dnd/src';
import { getDropStyle } from '../../../../common/helpers/getDropStyle';
import { createSlug } from '../../../../common/helpers/createSlug';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import Thumbnail from '../../Thumbnail/Thumbnail';
import { BoardItemDTO } from '../../../../common/api/dtos/Board';
import AppContext from '../../../../common/contexts/AppContext';
import BoardListingCardExpanded from './BoardListingCardExpanded';

interface Props extends WithTranslation {
  board: BoardItemDTO;
  className?: string;
  setFavorite?: (
    ev: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
    id: string,
  ) => void;
  provided?: DraggableProvided;
  innerRef?: (element: HTMLElement | null) => void;
  snapshot?: DraggableStateSnapshot;
  showReorder?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  small?: boolean;
  subscription:
    | 'subscribed'
    | 'unsubscribed'
    | 'willCancel'
    | 'willDelete'
    | 'none';
}

interface State {
  isFavoriteDisabled: boolean;
  isExpanded: boolean;
  isAnimating: boolean;
}

class BoardListingCard extends Component<Props, State> {
  buttonRef = React.createRef<HTMLButtonElement>();
  expansionTimeout: NodeJS.Timeout | null = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      isFavoriteDisabled: false,
      isExpanded: false,
      isAnimating: false,
    };
  }

  componentDidMount(): void {
    if (this.buttonRef.current) {
      this.buttonRef.current.addEventListener(
        'mouseenter',
        this.handleMouseEnter,
      );
      this.buttonRef.current.addEventListener(
        'mouseleave',
        this.handleMouseLeave,
      );
    }
  }

  componentWillUnmount(): void {
    if (this.buttonRef.current) {
      this.buttonRef.current.removeEventListener(
        'mouseenter',
        this.handleMouseEnter,
      );
      this.buttonRef.current.removeEventListener(
        'mouseleave',
        this.handleMouseLeave,
      );
    }
  }

  handleMouseEnter = (ev: MouseEvent) => {
    if (ev.target instanceof HTMLElement) {
      this.expansionTimeout = setTimeout(() => {
        this.setState({ isExpanded: true, isAnimating: true });
      }, 400);
    }
  };

  handleMouseLeave = (ev: MouseEvent) => {
    if (ev.target instanceof HTMLElement) {
      if (this.expansionTimeout) {
        clearTimeout(this.expansionTimeout);
      }
      this.setState({ isExpanded: false, isAnimating: true });
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.board.favorite !== this.props.board.favorite) {
      this.setState({ isFavoriteDisabled: false });
    }
  }

  handleSetFavorite = (
    ev: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (ev.type === 'keydown' && (ev as KeyboardEvent).key !== 'Tab') {
      ev.preventDefault();
    }
    if (ev.type === 'keydown' && (ev as KeyboardEvent).key !== 'Enter') return;
    if (
      typeof this.props.setFavorite !== 'undefined' &&
      !this.state.isFavoriteDisabled
    ) {
      this.props.setFavorite(ev, this.props.board.id);
      this.setState({ isFavoriteDisabled: true });
    }
  };

  evaluateURL() {
    const cleanedName = this.props.board.name.replaceAll(/%/g, '');
    const boardSlug = createSlug(cleanedName);
    return `/board/${this.props.board.id}/view/${boardSlug}`;
  }
  render() {
    const flagEnabled = this.context.experimentFlags?.boardFlashcards;
    const expandable = !this.props.small && flagEnabled;

    return (
      <LinkButton
        higherRef={this.buttonRef}
        to={this.evaluateURL()}
        className={[
          'card-button flex-v-start',
          this.props.className,
          expandable ? 'expandable' : '',
          this.state.isExpanded ? 'expanded' : '',
          this.state.isAnimating ? 'animating' : '',
          this.props.board.color ? `accent-${this.props.board.color}` : '',
        ].join(' ')}
        disabled={this.props.showReorder || this.props.disabled}
      >
        <div className="fill">
          <div className="fill flex-v-center">
            {this.props.subscription == 'willDelete' && (
              <span
                className="fas fa-clock negative-text top-tight right-tight"
                style={{ pointerEvents: 'all' }}
                title="This board is scheduled for deletion"
              >
                <span className="sr-only">
                  This board is scheduled for deletion
                </span>
              </span>
            )}
            <div
              className={[
                'thumbnail-frame-component',
                this.props.board.color
                  ? `accent-${this.props.board.color}`
                  : '',
                this.props.small ? `small mr-xs` : `mr-sm`,
              ].join(' ')}
              ref={this.props.innerRef}
              {...this.props.provided?.draggableProps}
              {...this.props.provided?.dragHandleProps}
              style={getDropStyle(
                this.props.provided?.draggableProps.style,
                this.props.snapshot,
              )}
            >
              <Thumbnail
                thumbnailData={this.props.board.thumbnail}
                classes={`radius ${this.props.small ? 'size-48' : 'size-64'}`}
                title={this.props.board.name}
              />
            </div>
            <div className="fill">
              <div className="flex-row fill  py-0">
                <div className="column py-0">
                  <h3 className="text-400 text-sm normalcase multiline-ellipsis l1 mb-2xs">
                    {this.props.board.name}
                  </h3>
                  {this.props.board.description && (
                    <p className="text-300 faint-text text-xs multiline-ellipsis l1">
                      {this.props.board.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {flagEnabled && (
            <div className="expandable-container">
              <div className="expandable-body">
                {this.state.isExpanded && (
                  <BoardListingCardExpanded
                    boardId={this.props.board.id}
                    color={this.props.board.color}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </LinkButton>
    );
  }
}

export default withStyledTranslation('boardListingCard')(BoardListingCard);
BoardListingCard.contextType = AppContext;
