import React, { useState, useEffect } from 'react';

const CoinsOn: React.FC = () => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <svg
      // style={{
      //   transform: 'scale(20) translate(40%,50%)',
      // }}
      key={key}
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className="icon"
    >
      <rect
        width="100"
        height="100"
        fill="transparent"
      />
      <defs>
        <g id="coin_edge">
          <path
            d="
            M -30,0 a 30,10 0 1,0 60,0 l 0,5 a 30,10 0 1,1 -60,0 l 0,-5 Z"
            className="coin_on_side"
            stroke="transparent"
            strokeWidth="2"
          />
        </g>

        <g id="coin_face">
          <ellipse
            cx="0"
            cy="0"
            rx="30"
            ry="10"
            stroke="transparent"
            className="coin_on_top"
            strokeWidth="2"
          />
        </g>
      </defs>

      <defs>
        <g id="rendered_coin">
          <use
            y="0"
            href="#coin_edge"
            fill="currentColor"
            style={{
              filter: 'brightness(0.5)',
            }}
          />
          <use
            y={-5}
            href="#coin_edge"
            fill="currentColor"
            style={{
              filter: 'brightness(0.5)',
            }}
          />
          <use
            y={-5}
            href="#coin_face"
            fill="currentColor"
          />
        </g>
      </defs>

      <style>
        {`
        .coin_on_top {
          opacity: 0;
          stroke-dashoffset: 30;
          animation: 0.2s fillTopCoin 0.2s ease forwards, 0.6s drawCoin ease forwards;
        }
        
        .coin_on_side {
          opacity: 0;
          stroke-dashoffset: 30;
          animation: 0.1s fillSideCoin 0.2s ease forwards, 0.6s drawCoin ease forwards;
        }
    
        @keyframes fillTopCoin {
          to {
            opacity: 1;
          }
        }
    
        @keyframes fillSideCoin {
          to {
            opacity: 1;
          }
        }
    
        @keyframes drawCoin {
          to {
            stroke-dashoffset: 0;
          }
        }
        `}
      </style>

      <g>
        <use
          href="#rendered_coin"
          clipPath="url(#coin_mask_1)"
          x="45"
          y="70" // increased from 60 to 70
        >
          <animate
            attributeName="y"
            values="40; 70; 68; 70" // increased from 30; 60; 58; 60
            keyTimes="0; 0.6; 0.7; 1"
            dur="0.6s"
            begin="0s"
            fill="freeze"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="45 0 70; 0 45 70; 0 0 70" // increased from 45 0 60; 0 45 60; 0 0 60
            keyTimes="0; 0.5; 1"
            dur="0.6s"
            begin="0s"
            fill="freeze"
          />
        </use>

        <use
          href="#rendered_coin"
          x="50"
          y="55" // increased from 50 to 55
        >
          <animate
            attributeName="y"
            values="25; 55; 53; 55" // increased from 20; 50; 48; 50
            keyTimes="0; 0.6; 0.8; 1"
            dur="0.6s"
            begin="0.1s"
            fill="freeze"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 0 70; -5 0 70; 0 0 70" // increased from 0 0 60; -5 0 60; 0 0 60
            keyTimes="0; 0.5; 1"
            dur="0.6s"
            begin="0s"
            fill="freeze"
          />
        </use>

        <use
          href="#rendered_coin"
          x="45"
          y="35" // increased from 40 to 45
        >
          <animate
            attributeName="y"
            values="5; 35; 33; 35" // increased from 10; 40; 38; 40
            keyTimes="0; 0.6; 0.8; 1"
            dur="0.6s"
            begin="0.2s"
            fill="freeze"
          />
        </use>
      </g>
    </svg>
  );
};

export default CoinsOn;
