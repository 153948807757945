import { Component } from 'react';
import { BoardDTO } from '../../../../common/api/dtos/Board';
import { getBoard } from '../../../../common/api/endpoints/board';
import BorddoUser from '../../BorddoUser';

interface ExpandedProps {
  boardId: string;
  color: string;
}

interface ExpandedState {
  boardDetails: Partial<BoardDTO> | null;
  loading: boolean;
}

class BoardListingCardExpanded extends Component<ExpandedProps, ExpandedState> {
  constructor(props: ExpandedProps) {
    super(props);
    this.state = {
      boardDetails: null,
      loading: true,
    };
  }

  async componentDidMount() {
    setTimeout(async () => {
      const boardDetails = await getBoard(this.props.boardId);
      this.setState({ boardDetails, loading: false });
    }, 1000);
  }

  render() {
    const { boardDetails, loading } = this.state;
    if (loading) {
      return (
        <div className={`accent-text ${this.props.color}-accent`}>
          <span className={`loader`}></span>
        </div>
      );
    }
    if (!boardDetails) {
      return <div>No details available</div>;
    }
    return (
      <ul className="control-list-component py-0 my-0">
        {boardDetails.owner && (
          <li>
            <BorddoUser
              size={24}
              avatarData={boardDetails.owner.avatar}
              title={boardDetails.owner.name}
              email={
                'email' in boardDetails.owner
                  ? (boardDetails.owner.email as string)
                  : undefined
              }
              avatarOnly={true}
            />
          </li>
        )}
        {boardDetails.members && boardDetails.members.length > 0 && (
          <li className="flex-v-center">
            {boardDetails.members.slice(0, 2).map((member) => {
              return (
                <>
                  <div
                    style={{
                      marginRight: '-12px',
                      filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))',
                    }}
                  >
                    <BorddoUser
                      size={24}
                      avatarData={member.avatar}
                      title={member.name}
                      avatarOnly={true}
                    />
                  </div>
                </>
              );
            })}
            {boardDetails.members.length > 2 && (
              <div
                style={{
                  filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))',
                }}
              >
                <BorddoUser
                  size={24}
                  title={'…'}
                  avatarOnly={true}
                />
              </div>
            )}
          </li>
        )}
        {boardDetails.invitees && boardDetails.invitees.length > 0 && (
          <li
            className="flex-v-center"
            style={{
              filter: 'brightness(0.2)',
            }}
          >
            {boardDetails.invitees.slice(0, 2).map((invitee) => {
              return (
                <>
                  <div
                    style={{
                      marginRight: '-12px',
                      filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))',
                    }}
                  >
                    <BorddoUser
                      size={24}
                      avatarData={invitee.avatar}
                      title={invitee.name ?? invitee.email}
                      email={
                        'email' in invitee
                          ? (invitee.email as string)
                          : undefined
                      }
                      avatarOnly={true}
                    />
                  </div>
                </>
              );
            })}
            {boardDetails.invitees.length > 2 && (
              <div
                style={{
                  filter: ' drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))',
                }}
              >
                <BorddoUser
                  size={24}
                  title={'…'}
                  avatarOnly={true}
                />
              </div>
            )}
          </li>
        )}
      </ul>
    );
  }
}

export default BoardListingCardExpanded;
