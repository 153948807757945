import { EditorState } from 'lexical';
import { useContext, useEffect, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import CONDUIT_BRIDGE from '../LexicalControl/themes/LexicalTheme';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import Dialog from '../Dialog/Dialog';
import { copyToClipboard } from '../../../common/helpers/copyToClipboard';
import AppContext from '../../../common/contexts/AppContext';
import { InfoNotificationPayload } from '../../../common/helpers/infoNotificationPayload';
import { unite } from '../../../common/helpers/unite';

const theme = CONDUIT_BRIDGE;

interface EditorViewerProps {
  editorState?: string | null;
  onEditorStateChange?: (editorState: EditorState) => void;
  namespace?: string;
}

function onError(error: unknown) {
  console.error(error);
}

export default function EditorViewer({
  editorState,
  namespace,
}: EditorViewerProps) {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const initialConfig = editorState
    ? {
        namespace: namespace ?? 'EditorViewer',
        theme,
        onError,
        editorState: (editor: any) =>
          editor.parseEditorState(JSON.parse(editorState)),
        editable: false,
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode,
        ],
      }
    : {
        namespace: namespace ?? 'EditorViewer',
        theme,
        onError,
        editable: false,
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableNode,
          TableCellNode,
          TableRowNode,
          AutoLinkNode,
          LinkNode,
        ],
      };

  return (
    <div className="flex-row fill">
      <div className="column pb-2xs">
        <LexicalComposer initialConfig={initialConfig}>
          <EditorContent
            editorState={editorState}
            isDialogVisible={isDialogVisible}
            setIsDialogVisible={setIsDialogVisible}
          />
        </LexicalComposer>
      </div>
    </div>
  );
}

function EditorContent({
  editorState,
  isDialogVisible,
  setIsDialogVisible,
}: {
  editorState?: string | null;
  isDialogVisible: boolean;
  setIsDialogVisible: (visible: boolean) => void;
}) {
  const [editor] = useLexicalComposerContext();

  const setMessages = useContext(AppContext).notifications.setMessages as (
    messages: InfoNotificationPayload[],
  ) => void;

  useEffect(() => {
    if (editorState && editor) {
      editor.update(() => {
        const parsedState = editor.parseEditorState(JSON.parse(editorState));
        editor.setEditorState(parsedState);
      });
    }
  }, [editorState, editor]);

  return (
    <div className="lexical-control">
      <div className="editor-inner">
        {(() => {
          try {
            JSON.parse(editorState || '');
            return (
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    style={{
                      paddingTop: '1rem',
                    }}
                    className={unite(
                      'card',
                      'editor-input',
                      'opaque',
                      'soft-disabled',
                    )}
                    spellCheck={false}
                    disabled={true}
                  />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
            );
          } catch {
            return (
              <>
                <button
                  className="secondary-button"
                  onClick={() => setIsDialogVisible(true)}
                >
                  <span className="fas fa-info-circle icon accent-text-orange"></span>{' '}
                  <span
                    className="text accent-text-orange"
                    style={{
                      textShadow: '1px 1px 0px #662200, -1px -1px 0px #662200',
                    }}
                  >
                    View legacy lexical text
                  </span>
                </button>
                {isDialogVisible && (
                  <Dialog
                    title=""
                    onCancel={() => setIsDialogVisible(false)}
                    onConfirm={() => {
                      if (editorState) {
                        copyToClipboard(
                          editorState,
                          'Legacy lexical text copied to clipboard',
                          setMessages,
                        );
                        setIsDialogVisible(false);
                      }
                    }}
                    confirmText="Copy to clipboard"
                    cancelText="Close"
                    message={
                      <span>
                        <p
                          className="text-center mb-sm accent-text-orange"
                          style={{
                            marginTop: '-75px',
                            textShadow:
                              '1px 1px 0px #662200, -1px -1px 0px #662200',
                          }}
                        >
                          <p className="fas fa-exclamation-triangle fa-2x mb-xs"></p>
                          <small>
                            This text was created with an older version of
                            Lexical is not supported in the current editor. You
                            can copy the text to the clipboard and paste it into
                            the new editor to <strong>manually</strong> convert
                            it to the new format.
                          </small>
                        </p>
                        <small>Original Markdown:</small>
                        <hr />
                        {editorState}
                      </span>
                    }
                  ></Dialog>
                )}
              </>
            );
          }
        })()}
      </div>
    </div>
  );
}
