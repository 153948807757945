import { createContext } from 'react';
import { UserAccountSettingsDTO, UserDTO } from '../api/dtos/User';
import { IClientData } from '../interfaces/ClientData';
import { NotificationMessage } from './NotificationsContext';
import { getDefaultExperiments } from '../helpers/experiments';

export type AccountSettings = UserAccountSettingsDTO;

export interface LoggedUser extends UserDTO {
  setAccountSettings: (settings: Partial<AccountSettings>) => Promise<void>;
}

export interface IAppContext {
  color?: string;
  highContrast?: boolean;
  theme?: string;
  showPatterns?: boolean;
  pattern?: string;
  hour?: string;
  language?: string;
  loggedUser?: LoggedUser;
  appSettings: UserAccountSettingsDTO;
  setLoggedUser?: (user: UserDTO) => void;
  directionalButtonId?: string;
  setDirectionalButtonId?: (id: string) => void;
  updateLoggedUser?: (newUserData: Partial<UserDTO>) => void;
  setIsDragging?: (value: boolean) => void;
  updateClientData?: (clientData: Partial<IClientData>) => void;
  notifications: {
    messages: NotificationMessage[];
    setMessages?: (
      messages: NotificationMessage | NotificationMessage[],
    ) => void;
  };
  experimentFlags?: { [key: string]: boolean };
  setExperimentFlags?: (flags: {
    experiments: { [key: string]: boolean };
  }) => void;
}

/**
 * Create a new context object with updated account and app settings.
 */
export function updateAccountSettings(
  ctx: IAppContext,
  settings: Partial<UserAccountSettingsDTO>,
): IAppContext {
  if (!ctx.loggedUser) {
    return ctx;
  }

  const loggedUser = {
    ...ctx.loggedUser,
    accountSettings: {
      ...ctx.loggedUser.accountSettings,
      ...settings,
    },
  };

  return {
    ...ctx,
    loggedUser,
    appSettings: computeAppSettings(loggedUser),
  };
}

/**
 * Compute the application settings based on the existence of a user, the settings stored
 * locally on the device, and the settings stored on the server.
 */
export function computeAppSettings(
  loggedUser?: LoggedUser,
): UserAccountSettingsDTO {
  const defaultSettings = {
    theme: 'light',
    highContrast: false,
    showPatterns: false,
    reducedMotion: false,
    colorBlindHelpers: false,
    timeFormat: 12,
    language: 'en_US',
    experiments: {
      ...getDefaultExperiments(),
    },
  };

  if (!loggedUser) {
    return defaultSettings;
  }

  const accountSettings = loggedUser.accountSettings;

  return {
    theme: accountSettings.theme,
    highContrast: accountSettings.highContrast,
    showPatterns: false,
    reducedMotion: accountSettings.reducedMotion,
    colorBlindHelpers: accountSettings.colorBlindHelpers ?? false,
    // language: accountSettings.language ?? 'en_US',
    language: 'en_US',
    timeFormat: accountSettings.timeFormat ?? '12',
  };
}

// New function to get stored experiments
function getStoredExperiments(userId: string) {
  if (!userId) return {};

  const clientFlags = JSON.parse(localStorage.getItem('clientFlags') || '{}');
  return clientFlags[userId] || {};
}

// Context default values
const AppContext = createContext<IAppContext>({
  appSettings: computeAppSettings(), // Default app settings
  updateLoggedUser: (newUserData: Partial<UserDTO>) => {
    (AppContext as any).current.setLoggedUser?.((prevLoggedUser: UserDTO) => ({
      ...prevLoggedUser,
      ...newUserData,
    }));
  },
  notifications: {
    messages: [], // Provide an empty array as the default value for messages
    setMessages: () => {
      return false;
      // Default no-op function for setMessages
    },
  },
  experimentFlags: getStoredExperiments('defaultUserId'), // Default experiment flags
  setExperimentFlags: () => {
    return false;
    // Default no-op function for setExperimentFlags
  },
});

export default AppContext;
