import { Component } from 'react';
import Stepper from '../../../controls/Stepper/Stepper';
import Button from '../../../controls/Button/Button';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import StepMembers from './StepMembers';
import StepIntro from './StepIntro';
import StepSpace from './StepSpace';
import StepBoard from './StepBoard';
import StepColumns from './StepColumns';
import StepTasks from './StepTasks';
import StepSummary from './StepSummary';
import StepStripe from './StepStripe';
import {
  completeOnboard,
  getLoggedUser,
  onboardUser,
} from '../../../../common/api/endpoints/user';
import { OnboardDTO, UserDTO } from '../../../../common/api/dtos/User';
import { RouteComponentProps } from 'react-router-dom';
import { normalizeOnboardingData } from '../../../../common/helpers/normalizeOnboardingData';
import { listTeams } from '../../../../common/api/endpoints/team';
import AnimatedBackground from '../../../animations/AnimatedBackground/AnimatedBackground';

type TOnboarding = {
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  organizingWith: 'justMe' | 'team' | undefined;
  peopleCount: number;
  goal: 'personal' | 'work' | undefined;
  boardName: string;
  columns: string[];
  tasks: string[];
  emails: string[];
};

interface State {
  currentStep: string | undefined;
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  organizingWith: 'justMe' | 'team' | undefined;
  peopleCount: number;
  goal: 'personal' | 'work' | undefined;
  teamName: string;
  boardName: string;
  columns: string[];
  tasks: string[];
  emails: string[];
  uxStep: number;
  loggedUser: UserDTO;
}
type ExternalProps = RouteComponentProps<any>;

export interface Props extends ExternalProps, WithTranslation {
  unlockApp: () => void;
  handleLogout: () => void;
}

class Onboarding extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentStep: undefined,
      familiarity: undefined,
      organizingWith: undefined,
      peopleCount: 2,
      goal: undefined,
      teamName: '',
      boardName: '',
      columns: [],
      tasks: [],
      emails: [],
      uxStep: 1,
      loggedUser: {} as UserDTO,
    };
  }

  componentDidMount(): void {
    this.initOnboarding();
  }

  changeLanguage = (_: unknown, value: unknown) => {
    const newLang = String(value);
    this.props.i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  calculateUxStep = () => {
    const { currentStep, familiarity, organizingWith } = this.state;
    let stepIndex = 1; // Start with the first step (Intro)
    if (currentStep === 'stepIntro') return stepIndex;

    // Step 2: Stripe (if organizing with a team)
    if (organizingWith === 'team') {
      stepIndex++;
      if (currentStep === 'stepStripe') return stepIndex;
    }

    // Step 3: Space (if organizing with a team)
    if (organizingWith === 'team') {
      stepIndex++;
      if (currentStep === 'stepSpace') return stepIndex;
    }

    // Step 4: Board
    stepIndex++;
    if (currentStep === 'stepBoard') return stepIndex;

    // Step 5: Columns (if not an expert)
    if (familiarity !== 'expert') {
      stepIndex++;
      if (currentStep === 'stepColumns') return stepIndex;
    }

    // Step 6: Tasks (if not an expert)
    if (familiarity !== 'expert') {
      stepIndex++;
      if (currentStep === 'stepTasks') return stepIndex;
    }

    // Step 7: Members (if organizing with a team)
    if (organizingWith === 'team') {
      stepIndex++;
      if (currentStep === 'stepMembers') return stepIndex;
    }

    // Step 8: Summary
    stepIndex++;
    if (currentStep === 'stepSummary') return stepIndex;

    return stepIndex; // Default return in case the currentStep is undefined
  };

  getBaseStepOffset = (currentStep: string | undefined) => {
    switch (currentStep) {
      case 'stepIntro':
        return 1;
      case 'stepStripe':
        return 2;
      case 'stepSpace':
        return 3;
      case 'stepBoard':
        return 4;
      case 'stepColumns':
        return 5;
      case 'stepTasks':
        return 6;
      case 'stepMembers':
        return 7;
      case 'stepSummary':
        return 8;
      default:
        return 0;
    }
  };

  getTeamStepOffset = (organizingWith: 'justMe' | 'team' | undefined) => {
    return organizingWith === 'team' ? 1 : 0;
  };

  getFamiliarityStepOffset = (
    familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined,
    organizingWith: 'justMe' | 'team' | undefined,
  ) => {
    let offset = 0;
    if (familiarity === 'expert') {
      offset -= 2;
      if (organizingWith === 'justMe') {
        offset += 1;
      }
    }
    return offset;
  };

  initOnboarding = async () => {
    try {
      const data = await getLoggedUser();
      this.setState({ loggedUser: data.user });

      const onboarding: TOnboarding = normalizeOnboardingData(
        data.user.onboarding,
      );

      let currentStep = 'stepIntro';

      // Check if a team already exists and skip stepIntro and stepStripe if true
      const { teams } = await listTeams('1');
      const validTeam = teams.find((team) => team.subscription !== null);

      if (validTeam) {
        currentStep = 'stepSpace';
      } else if (
        onboarding.familiarity &&
        onboarding.organizingWith &&
        onboarding.goal
      ) {
        currentStep =
          onboarding.organizingWith === 'justMe' ? 'stepBoard' : 'stepStripe';
      }

      if (onboarding.boardName) {
        currentStep =
          onboarding.familiarity === 'expert'
            ? onboarding.organizingWith === 'justMe'
              ? 'stepSummary'
              : 'stepMembers'
            : 'stepColumns';
      }

      if (onboarding.columns.length) {
        currentStep =
          currentStep !== 'stepSummary' && currentStep !== 'stepMembers'
            ? 'stepTasks'
            : currentStep;
      }

      if (onboarding.tasks.length) {
        currentStep =
          currentStep !== 'stepSummary' && currentStep !== 'stepMembers'
            ? 'stepMembers'
            : currentStep;
      }

      if (onboarding.emails.length) {
        currentStep = 'stepSummary';
      }

      // Set the calculated step based on the current state
      this.setState(
        {
          ...onboarding,
          currentStep,
          uxStep: this.calculateUxStep(),
        },
        async () => {
          this.setState({ uxStep: this.calculateUxStep() }); // Ensure correct step calculation
          if (currentStep === 'stepStripe') {
            await this.handleStripeCompletion();
          }
        },
      );
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepIntroCompletion = async (
    familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined,
    organizingWith: 'justMe' | 'team' | undefined,
    goal: 'personal' | 'work' | undefined,
    peopleCount: number,
  ) => {
    const nextStep = organizingWith === 'justMe' ? 'stepBoard' : 'stepStripe';

    this.setState(
      {
        familiarity,
        organizingWith,
        peopleCount,
        goal,
        currentStep: nextStep,
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      const onboarding: Partial<OnboardDTO> = {
        question1:
          familiarity === 'exploring'
            ? 'a'
            : familiarity === 'usedBefore'
              ? 'b'
              : 'c',
        question2: organizingWith === 'justMe' ? 'a' : 'b',
        question3: goal === 'personal' ? 'a' : 'b',
        version: 1,
      };

      // Only add teamMemberCount if it's not 0 or undefined
      if (organizingWith !== 'justMe' && peopleCount) {
        onboarding.teamMemberCount = peopleCount;
      }

      await onboardUser({ ...onboarding });
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepSpaceCompletion = async (teamName: string) => {
    this.setState(
      {
        teamName,
        currentStep: 'stepBoard',
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      await onboardUser({ teamName, version: 1 });
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepBoardCompletion = async (boardName: string) => {
    let nextStep;

    if (this.state.organizingWith === 'justMe') {
      nextStep =
        this.state.familiarity === 'expert' ? 'stepSummary' : 'stepColumns';
    } else {
      nextStep =
        this.state.familiarity === 'expert' ? 'stepMembers' : 'stepColumns';
    }

    this.setState(
      {
        boardName,
        currentStep: nextStep,
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      await onboardUser({ boardName, version: 1 });
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepColumnsCompletion = async (columns: string[]) => {
    this.setState(
      {
        columns,
        currentStep: 'stepTasks',
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      await onboardUser({ columnNames: columns, version: 1 });
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepColumnsSkip = async () => {
    this.setState(
      {
        columns: [],
        currentStep: 'stepTasks',
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      await onboardUser({ columnNames: [], version: 1 });
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepTasksCompletion = async (tasks: string[]) => {
    this.setState(
      {
        tasks,
        currentStep:
          this.state.organizingWith == 'justMe' ? 'stepSummary' : 'stepMembers',
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      await onboardUser({ cardNames: tasks, version: 1 });
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepTasksSkip = async () => {
    this.setState(
      {
        tasks: [],
        currentStep:
          this.state.organizingWith == 'justMe' ? 'stepSummary' : 'stepMembers',
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      await onboardUser({ cardNames: [], version: 1 });
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepMembersCompletion = async (emails: string[]) => {
    this.setState(
      {
        emails,
        currentStep: 'stepSummary',
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      await onboardUser({ teamMemberEmails: emails, version: 1 });
    } catch (err) {
      console.debug(err);
    }
  };

  handleStepMembersSkip = async () => {
    this.setState(
      {
        emails: [],
        currentStep: 'stepSummary',
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );

    try {
      await onboardUser({ teamMemberEmails: [], version: 1 });
    } catch (err) {
      console.debug(err);
    }
  };

  handleNext = () => {
    const stepOrder = [
      'stepIntro',
      'stepStripe',
      'stepSpace',
      'stepBoard',
      'stepColumns',
      'stepTasks',
      'stepMembers',
      'stepSummary',
    ];

    const currentIndex = stepOrder.indexOf(this.state.currentStep!);
    const nextStep = stepOrder[currentIndex + 1];

    this.setState(
      {
        currentStep: nextStep,
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );
  };

  handleSkip = () => {
    this.handleNext();
  };

  handlePrev = () => {
    const stepOrder = [
      'stepIntro',
      'stepStripe',
      'stepSpace',
      'stepBoard',
      'stepColumns',
      'stepTasks',
      'stepMembers',
      'stepSummary',
    ];

    const currentIndex = stepOrder.indexOf(this.state.currentStep!);
    let prevStep = stepOrder[Math.max(currentIndex - 1, 0)];

    if (this.state.currentStep === 'stepBoard') {
      prevStep =
        this.state.organizingWith === 'justMe' ? 'stepIntro' : 'stepSpace';
    }

    this.setState(
      {
        currentStep: prevStep,
      },
      () => {
        this.setState({ uxStep: this.calculateUxStep() });
      },
    );
  };

  handleJumpToStep = (step: string) => {
    this.setState({ currentStep: step }, () => {
      this.setState({ uxStep: this.calculateUxStep() });
    });
  };

  handleBegin = async () => {
    try {
      const response = await completeOnboard();
      const boardId = response.board.id;
      this.props.unlockApp();
      this.props.history.push(`/board/${boardId}/view`);
    } catch (err) {
      console.debug(err);
    }
  };

  handleStripeCompletion = async () => {
    try {
      const { teams } = await listTeams('1');
      const validTeam = teams.find((team) => team.subscription !== null);

      if (validTeam) {
        this.setState(
          {
            currentStep: 'stepStripe',
          },
          () => {
            this.setState({ uxStep: this.calculateUxStep() });
          },
        );
      }
    } catch (err) {
      console.debug(err);
    }
  };

  renderSteps = () => {
    const {
      familiarity,
      organizingWith,
      peopleCount,
      goal,
      teamName,
      boardName,
      columns,
      tasks,
      emails,
      uxStep,
    } = this.state;

    const steps = {
      stepIntro: (
        <StepIntro
          uxStep={uxStep}
          key="stepIntro"
          onNext={this.handleStepIntroCompletion}
          initialData={{ familiarity, organizingWith, goal, peopleCount }}
        />
      ),
      stepStripe: (
        <StepStripe
          uxStep={uxStep}
          key="stepStripe"
          peopleCount={peopleCount}
          onNext={this.handleStripeCompletion}
          onPrev={this.handlePrev}
        />
      ),
      stepSpace: (
        <StepSpace
          uxStep={uxStep}
          key="stepSpace"
          onNext={this.handleStepSpaceCompletion}
          familiarity={familiarity}
          teamName={teamName}
        />
      ),
      stepBoard: (
        <StepBoard
          uxStep={uxStep}
          key="stepBoard"
          familiarity={familiarity}
          organizingWith={organizingWith}
          goal={goal}
          onNext={this.handleStepBoardCompletion}
          onPrev={this.handlePrev}
          boardName={boardName}
        />
      ),
      stepColumns:
        familiarity !== 'expert' ? (
          <StepColumns
            uxStep={uxStep}
            key="stepColumns"
            onNext={this.handleStepColumnsCompletion}
            onSkip={this.handleStepColumnsSkip}
            familiarity={familiarity}
            columns={columns}
          />
        ) : null,
      stepTasks:
        familiarity !== 'expert' ? (
          <StepTasks
            uxStep={uxStep}
            key="stepTasks"
            onNext={this.handleStepTasksCompletion}
            onSkip={this.handleStepTasksSkip}
            familiarity={familiarity}
            tasks={tasks}
          />
        ) : null,
      stepMembers: (
        <StepMembers
          uxStep={uxStep}
          key="stepMembers"
          onNext={this.handleStepMembersCompletion}
          onSkip={this.handleStepMembersSkip}
          onPrev={this.handlePrev}
          organizingWith={organizingWith}
          peopleCount={peopleCount}
          members={emails}
        />
      ),
      stepSummary: (
        <StepSummary
          uxStep={uxStep}
          key="stepSummary"
          onBegin={this.handleBegin}
          onEditStep={this.handleJumpToStep}
          organizingWith={organizingWith}
          familiarity={familiarity}
          choices={{ teamName, boardName, columns, tasks, emails }}
        />
      ),
    };

    return steps;
  };

  render() {
    const { t } = this.props;
    const { currentStep } = this.state;
    if (typeof currentStep == 'undefined')
      return (
        <div
          className="oobe-component"
          style={{ background: 'none' }}
        >
          <AnimatedBackground />
          <div className="oobe-inner">
            <Stepper
              initialStep={'loading'}
              steps={{
                loading: <span className="loader text-2xl"></span>,
              }}
              currentStep={'loading'}
              reverseFirstAnimation={true}
            />
          </div>
        </div>
      );

    return (
      <div
        className="oobe-component"
        style={{ background: 'none' }}
      >
        <AnimatedBackground />
        <div className="oobe-inner">
          <Stepper
            initialStep={currentStep}
            steps={this.renderSteps()}
            currentStep={currentStep}
            reverseFirstAnimation={true}
          />
          <div className="flex-row fill">
            <div className="column text-center">
              {/* <ComboBox
                id="language-select"
                label={t('language.chooseLanguage')}
                title={t('language.chooseLanguage')}
                value={localStorage.getItem('language') || 'en_US'}
                options={LANGUAGE_OPTIONS}
                onChange={this.changeLanguage}
                formGroupClassname="fill text-center pb-0 mt-sm"
                dropDownClasses="text-center"
                getValue={(option) => option.value}
                getLabel={(option) => option.label}
                srOnly={true}
              /> */}
              <p className="text-sm">
                <span>{t('loggedInAs')}</span>
                <br />
                <p className="mb-xs">
                  {this.state.loggedUser.name} ({this.state.loggedUser.email})
                </p>
                <Button
                  className="link-button"
                  onClick={() => {
                    this.props.handleLogout();
                    this.props.history.push('/login');
                  }}
                >
                  <span className="text">{t('logOut')}</span>
                </Button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('legalFooter')(Onboarding);
