import React from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';
import { NotificationMessage } from '../../../../common/contexts/NotificationsContext';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  cardId: string;
  disabled?: boolean;
  droppedAttachments: File[] | null;
  handleNewAttachment: (newAttachment: AttachmentDTO) => void;
  uploadClipboardData: boolean;
  clipboardData: File | null;
  setUploadClipboardData: (value: boolean) => void;
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  clearDroppedAttachments: () => void;
}

interface State {
  formStatus: TRequestStatus;
  loader: boolean;
  isLarge: boolean;
}

class FileInput extends React.Component<Props, State> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      formStatus: 'idle',
      loader: false,
      isLarge: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.uploadClipboardData && !prevProps.uploadClipboardData) {
      if (this.props.clipboardData) {
        this.uploadAttachment(this.props.clipboardData);
      }
      this.props.setUploadClipboardData(false);
    }
    if (this.props.droppedAttachments && !prevProps.droppedAttachments) {
      this.uploadDroppedAttachments(this.props.droppedAttachments);
      this.props.clearDroppedAttachments();
    }
  }

  handleClick = () => {
    this.inputRef.current?.click();
  };

  handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      this.setState({
        formStatus: 'loading',
      });

      Array.from(event.currentTarget.files).map((entry) => {
        this.uploadAttachment(entry);
      });

      event.currentTarget.value = '';
    }
  };

  uploadDroppedAttachments = async (files: File[]) => {
    Array.from(files).map((entry) => {
      this.uploadAttachment(entry);
    });
  };

  uploadAttachment = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = (await this.xmlHttpRequest(
        this.props.cardId,
        formData,
      )) as XMLHttpRequest;

      if (response.status === 413) {
        this.setState({ isLarge: true });
        showErrorNotifications(
          [{ message: 'File is too large' }],
          this.props.setMessages,
        );
      } else if (response.status === 400) {
        const responseJSON = JSON.parse(response.responseText);
        showErrorNotifications(responseJSON.errors, this.props.setMessages);
      } else if (response.status >= 200 && response.status < 300) {
        const newAttachment = JSON.parse(
          response.responseText,
        ) as AttachmentDTO;
        this.props.handleNewAttachment(newAttachment);
        this.setState({ isLarge: false });
      } else {
        showErrorNotifications(
          [{ message: 'An error occurred during the upload' }],
          this.props.setMessages,
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({
        formStatus: 'error',
      });
      showErrorNotifications(
        [{ message: (error as Error).message }],
        this.props.setMessages,
      );
    }

    this.setState({
      formStatus: 'success',
      loader: false,
    });
  };

  xmlHttpRequest(cardId: string, formData: FormData) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.withCredentials = true;

      req.addEventListener('progress', (event) => {
        console.debug('download progress', event);
      });
      req.addEventListener('load', (event) => {
        console.debug('download load', event);
        resolve(req);
      });
      req.addEventListener('error', (event) => {
        console.debug('download error', event);
        reject(event);
      });
      req.addEventListener('abort', (event) => {
        console.debug('download abort', event);
        reject(event);
      });

      // todo: use upload progress events to inform the user how much of his attachment
      // has been sent to the server.
      //
      // Note! When the upload is finished, the request is still in progress, until the
      // server returns a response.
      req.upload.addEventListener('progress', (event) => {
        console.debug('upload progress', event);
        this.setState({
          loader: true,
        });
      });
      req.upload.addEventListener('load', (event) => {
        console.debug('upload load', event);
      });
      req.upload.addEventListener('error', (event) => {
        console.debug('upload error', event);
        reject(event);
      });
      req.upload.addEventListener('abort', (event) => {
        console.debug('upload abort', event);
        reject(event);
      });

      req.open(
        'POST',
        `${process.env.REACT_APP_API_BASE_URL}/card/${cardId}/attachment`,
      );
      const conId = sessionStorage.getItem('borddo-wsconid');
      if (conId) {
        req.setRequestHeader('borddo-wsconid', conId);
      }
      req.send(formData);
    });
  }

  render() {
    const { formStatus, loader } = this.state;
    const { t, disabled } = this.props;

    return (
      <div className="fill">
        <input
          ref={this.inputRef}
          onChange={this.handleFileChange}
          style={{ display: 'none' }}
          type="file"
          multiple
        />
        <div className="flex-v-center ">
          {loader ? (
            <div>
              <span className="loader" />{' '}
              <small className="faint-text">{t('processing')}</small>
            </div>
          ) : (
            <div
              className="fill px-xs py-xs flex-v-center"
              style={{
                border: '1px dashed rgba(var(--primary), .5)',
                borderRadius: '6px',
              }}
            >
              <button
                className="primary-button mr-2xs"
                type="button"
                onClick={this.handleClick}
                disabled={disabled || formStatus === 'loading'}
              >
                <span className="fas fa-paperclip icon"></span>
                <span>{t('attachFiles')}</span>
              </button>
              {!this.props.disabled && (
                <>
                  <span className="faint-text text-sm">{t('dragFile')}</span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('fileInput')(FileInput);
