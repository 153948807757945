import { Component, ChangeEvent } from 'react';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Button from '../../../controls/Button/Button';
import TextBox from '../../../controls/TextBox/TextBox';

interface Props extends WithTranslation {
  uxStep: number;
  onNext: (columns: string[]) => void;
  onSkip: () => void;
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  columns: string[];
}

interface State {
  columns: string[];
}

class StepColumns extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      columns: this.props.columns.length
        ? [
            ...this.props.columns,
            ...Array(
              Math.max(
                this.props.familiarity == 'exploring' ? 3 : 4,
                this.props.columns.length,
              ) - this.props.columns.length,
            ).fill(''),
          ]
        : this.getInitialColumns(),
    };
  }

  getInitialColumns = () => {
    const { familiarity } = this.props;
    if (familiarity === 'exploring') {
      return ['', '', '']; // 3 input fields
    }
    return ['', '', '', '']; // 4 input fields
  };

  handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const newColumns = [...prevState.columns];
      newColumns[index] = value;
      return { columns: newColumns };
    });
  };

  handleAddColumn = () => {
    this.setState((prevState) => ({
      columns: [...prevState.columns, ''],
    }));
  };

  handleNext = () => {
    const nonEmptyColumns = this.state.columns.filter(
      (column) => column.trim() !== '',
    );
    this.props.onNext(nonEmptyColumns);
  };

  handleSkip = () => {
    this.props.onSkip();
  };

  render() {
    const { t, familiarity } = this.props;
    const { columns } = this.state;

    return (
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-line-columns"></span>
        </div>
        <div className="column large text-left flex-v-center">
          <div>
            <h2>{t('step4.title', { count: this.props.uxStep })}</h2>
            <div className="scrollable-content flex-v-center">
              <div>
                <p>
                  {t(
                    familiarity === 'exploring'
                      ? 'step4.exploring'
                      : 'step4.customizing',
                  )}
                </p>
                {columns.map((column, index) => (
                  <div
                    key={index}
                    className={`input-container ${
                      index > 0 &&
                      !columns[index - 1].trim() &&
                      !columns[index].trim()
                        ? 'hidden'
                        : 'reveal-up-1'
                    }`}
                  >
                    <TextBox
                      type="text"
                      value={column}
                      onChange={(event) => this.handleInputChange(index, event)}
                      placeholder={t('step4.placeholder')}
                      id={`column-${index}`}
                      name={`column-${index}`}
                      label=""
                    />
                  </div>
                ))}
                {familiarity !== 'exploring' &&
                  columns.filter((column) => column.trim() !== '').length >=
                    4 && (
                    <div className=" reveal-up-1">
                      <Button
                        className="primary-button"
                        onClick={this.handleAddColumn}
                        disabled={columns[columns.length - 1].trim() === ''}
                      >
                        {t('step4.addColumn')}
                      </Button>
                    </div>
                  )}
              </div>
            </div>
            <div className="button-container">
              <Button
                className="ghost-button mr-xs px-sm"
                onClick={this.handleSkip}
              >
                {t('step4.skip')}
              </Button>
              <Button
                className="primary-button"
                onClick={this.handleNext}
                disabled={
                  !columns.filter((column) => column.trim() !== '').length
                } // Disable button if there are no non-empty columns
              >
                {t('step4.next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('stepColumns')(StepColumns);
