import React, { Component, ChangeEvent, ReactNode } from 'react';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import Button from '../../controls/Button/Button';
import DirectionalButton from '../../controls/DirectionalButton/DirectionalButton';
import { Trans, WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import LinkButton from '../../controls/LinkButton/LinkButton';
import BoardContext from '../../../common/contexts/BoardContext';
import NumberControl from '../../controls/NumberControl/NumberControl';

interface State {
  limit?: number;
  formStatus: TRequestStatus;
  formData?: FormData;
}

interface FormData {
  limit?: string;
}

const MAX_LIMIT = 9999;
const MIN_LIMIT = 0;

interface Props extends WithTranslation {
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  onSetLimit: (value: number) => void;
  setLimit: (value: number) => void;
  totalCards: number;
  limit?: number;
  columnCardLimitState: string;
}

class ColumnLimit extends Component<Props, State> {
  static contextType = BoardContext;

  static defaultProps = {
    directionalButton: undefined,
    setSelectedContext: () => false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      formStatus: 'idle',
      limit: props.limit || 0,
      formData: { limit: (props.limit ?? 0).toString() },
    };
  }

  setLimit = (e: ChangeEvent<HTMLInputElement> | number): void => {
    const value = (
      typeof e === 'number' ? e : Number(e.target.value)
    ).toString();
    this.setState({ formData: { limit: value } });
  };

  saveLimit = (): void => {
    const value = Number(this.state.formData?.limit);
    this.setState({ limit: value }, () => this.props.setLimit(value));
    this.props.onSetLimit(Number(this.state.formData?.limit));
    this.props.setSelectedContext?.('manageColumn');
  };

  handleLimitChange = (e: ChangeEvent<HTMLInputElement> | number) => {
    this.setLimit(e);
    this.setState({ formStatus: 'idle' });
  };

  renderForm = (): ReactNode => (
    <form autoComplete="off">
      <NumberControl
        id="listLimit"
        name="limit"
        onChange={this.handleLimitChange}
        value={this.state.limit || 0}
        minValue={MIN_LIMIT}
        maxValue={MAX_LIMIT}
        label={this.props.t('editLimit')}
        br
        formGroupClassNames="mb-xs"
      />
      <ul className="control-list-component">
        <li>
          <Button
            className="primary-button"
            status={this.state.formStatus}
            onClick={this.saveLimit}
            disabled={
              Number(this.state.formData?.limit) === Number(this.state.limit)
            }
          >
            {this.props.t('saveLimitButton')}
          </Button>
        </li>
        <li>
          <Button
            className="ghost-button"
            title={this.props.t('closeFormButton')}
            onClick={() => this.props.setSelectedContext?.('manageColumn')}
          >
            <span className="fas fa-times pe-none" />
          </Button>
        </li>
      </ul>
      <hr />
      <div className="py-xs">
        <small>
          <Trans
            i18nKey="columnLimit:noteLimit"
            values={{
              columnCardLimitState: this.props.t(
                this.props.columnCardLimitState,
              ),
            }}
            components={{
              a: (
                <LinkButton
                  to={`/board/${this.context?.board?.id || ''}/board-settings`}
                  className="link-button"
                />
              ),
              span: <span className="text" />,
              strong: <strong />,
            }}
          />
        </small>
      </div>
    </form>
  );

  renderDisabledMessage = (): ReactNode => (
    <div className="pb-sm">
      <small style={{ whiteSpace: 'normal', maxWidth: '245px' }}>
        <Trans
          i18nKey="columnLimit:noteDisabled"
          values={{
            columnCardLimitState: this.props.t(this.props.columnCardLimitState),
          }}
          components={{
            a: (
              <LinkButton
                to={`/board/${this.context?.board?.id || ''}/board-settings`}
                className="link-button"
              />
            ),
            span: <span className="text" />,
            strong: <strong />,
          }}
        />
      </small>
    </div>
  );

  render(): ReactNode {
    const isDisabled = this.props.columnCardLimitState === 'disabled';

    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                ...this.props.directionalButton,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        <li>
          <hr />
        </li>
        <li>
          <div className="flex-row fill">
            <div className="column py-0">
              {isDisabled ? this.renderDisabledMessage() : this.renderForm()}
            </div>
          </div>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('columnLimit')(ColumnLimit);
