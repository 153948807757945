import React, { useCallback, useMemo } from 'react';
import Thumbnail from '../../../../partials/Thumbnail/Thumbnail';
import { InviteeDTO, MemberDTO } from '../../../../../common/api/dtos/Member';
import ContextMenu from '../../../../controls/ContextMenu/ContextMenu';
import MemberMenu from '../../../../menus/Member/MemberMenu';
import { DEFAULT_AVATAR } from '../../../../../common/configs/appDefaults';
import RemoveMemberMenu from './partial/RemoveMemberMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  members: (MemberDTO | InviteeDTO)[];
  cardId: string;
  assigneeIds: string[];
  isArchived?: boolean;
}

const BoardCardMembers: React.FC<Props> = ({
  members,
  cardId,
  assigneeIds,
  isArchived = false,
  t,
}) => {
  // Memoize member lookup to avoid recomputing on each render
  const getMember = useCallback(
    (memberId: string): MemberDTO | InviteeDTO | undefined => {
      return members.find((member) => member.id === memberId);
    },
    [members],
  );

  // Memoized list of assignees to prevent unnecessary renders
  const assignedMembers = useMemo(() => {
    return assigneeIds
      .map((memberId) => {
        const memberObj = getMember(memberId);
        if (!memberObj) return null;
        return {
          ...memberObj,
          avatar: memberObj.avatar || DEFAULT_AVATAR,
        };
      })
      .filter(Boolean); // Filter out null members
  }, [assigneeIds, getMember]);

  return (
    <div className="flex-row fill">
      <div className="column py-0 flex-v-center">
        <ul className="control-list-component icon-list-component align-h-end">
          {assignedMembers.map(
            (member) =>
              member && (
                <li
                  className="mb-0"
                  key={member.id}
                >
                  <ContextMenu
                    dept={0}
                    contextId={member.id}
                    contextMenuClassName="align-h-start"
                    triggerClassDefault="ghost-button"
                    triggerClassActive="secondary-button"
                    title={member.name}
                    isDisabled={isArchived}
                    triggerContent={
                      <Thumbnail
                        classes="thumbnail size-24 pe-none"
                        avatarData={member.avatar}
                        title={member.name || (member as InviteeDTO).email}
                      />
                    }
                    rightClickTrigger={true}
                  >
                    <RemoveMemberMenu
                      cardId={cardId}
                      memberId={member.id}
                      memberName={member.name}
                    />
                  </ContextMenu>
                </li>
              ),
          )}
          {!isArchived && (
            <li className="mb-0">
              <ContextMenu
                dept={0}
                contextId={cardId}
                contextMenuClassName="align-h-start"
                triggerClassDefault="ghost-button"
                triggerClassActive="secondary-button"
                title={t('assignUsers')}
                isDisabled={isArchived}
                triggerContent={<span className="fal fa-user-plus"></span>}
              >
                <MemberMenu
                  manage={false}
                  selected={assigneeIds}
                  cardId={cardId}
                  userSearchOff={true}
                />
              </ContextMenu>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default withStyledTranslation('boardCardMembers')(BoardCardMembers);
