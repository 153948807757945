import React, { useState, useCallback, useMemo, useEffect } from 'react';

export interface Props {
  thumbnailBackground?: string;
  classes?: string;
  avatarData?: string;
  thumbnailData?: string;
  title?: string;
  fileExtension?: string;
}

// In-memory image cache to store loaded image URLs
const imageCache: { [key: string]: string } = {};

const Thumbnail: React.FC<Props> = ({
  thumbnailBackground,
  classes = '',
  avatarData,
  thumbnailData,
  title = '',
  fileExtension = '',
}) => {
  const [hasError, setHasError] = useState(false);

  // Generate a fallback SVG for the thumbnail
  const generateSVG = useCallback(() => {
    if (!title) {
      return 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect width="100%" height="100%" fill="none"/></svg>';
    }

    const initial = getValidInitialCharacter(title);
    let bgColor = hashStringToColor(title);
    let textColor = '#ffffff';

    if (initial.length > 1) {
      bgColor = '#111111'; // Dark background for emojis
    } else if (isLightColor(bgColor)) {
      textColor = '#111111'; // Dark text for light backgrounds
    }

    const displayText = fileExtension ? '.' + fileExtension : initial;
    const svgContent = `
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" style="background-color:${bgColor};">
        <text x="50%" y="50%" font-size="${
          fileExtension ? '24' : '50'
        }" font-family="Arial, Helvetica, sans-serif" font-weight="700" dy=".35em" text-anchor="middle" fill="${textColor}">${displayText}</text>
      </svg>
    `;
    return `data:image/svg+xml,${encodeURIComponent(svgContent)}`;
  }, [title, fileExtension]);

  // Get valid initial character for display
  const getValidInitialCharacter = useCallback((str: string): string => {
    const firstChar = str.charAt(0).toUpperCase();
    const firstCodePoint = firstChar.codePointAt(0);

    if (
      firstCodePoint &&
      firstCodePoint >= 0xd800 &&
      firstCodePoint <= 0xdbff
    ) {
      const secondChar = str.charAt(1);
      const secondCodePoint = secondChar?.codePointAt(0);
      if (
        secondCodePoint &&
        secondCodePoint >= 0xdc00 &&
        secondCodePoint <= 0xdfff
      ) {
        return str.slice(0, 2); // Combine surrogate pair (for emojis, etc.)
      }
    }
    return firstChar;
  }, []);

  // Generate color from string
  const hashStringToColor = useCallback((str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).slice(-2);
    }
    return color;
  }, []);

  // Determine if color is light and adjust text color accordingly
  const isLightColor = useCallback((color: string): boolean => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 180;
  }, []);

  // Construct avatar or thumbnail URL, fallback to SVG if not available
  const imageURL = useMemo(() => {
    if (avatarData) {
      return `/assets/avatars/${avatarData}`;
    }
    if (thumbnailData) {
      return `/assets/thumbnails/${thumbnailData}`;
    }
    return generateSVG();
  }, [avatarData, thumbnailData, generateSVG]);

  useEffect(() => {
    // Check if the image is already cached and set isLoaded to true
    if (imageCache[imageURL] === 'loaded') {
      setIsLoaded(true);
    }
  }, [imageURL]);

  // Handle image load success
  const handleLoad = useCallback(() => {
    if (!imageCache[imageURL]) {
      imageCache[imageURL] = 'loaded'; // Cache this image URL
    }
    setIsLoaded(true); // Set the image as loaded when it's done
  }, [imageURL]);

  // Handle image load errors and fallback to SVG
  const handleError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (!hasError) {
        setHasError(true);
        event.currentTarget.src = generateSVG();
        event.currentTarget.style.transition = 'none'; // Disable transition
        imageCache[imageURL] = 'error'; // Cache this error state
        setIsLoaded(true); // Ensure opacity change even with fallback
      }
    },
    [hasError, imageURL, generateSVG],
  );

  const initialLoadedState = useMemo(
    () => imageCache[imageURL] === 'loaded',
    [imageURL],
  );
  const [isLoaded, setIsLoaded] = useState(initialLoadedState);

  return (
    <img
      className={`thumbnail ${classes}`} // Add fade-in class dynamically
      src={imageURL}
      onError={handleError}
      onLoad={handleLoad}
      loading="lazy" // Lazy load for performance optimization
      title={title}
      alt={`Thumbnail for ${title}`}
      style={{
        background: thumbnailBackground,
        opacity: isLoaded ? 1 : 0, // Set opacity only after loading
        transition: 'opacity 0.5s ease-in-out', // Ensure smooth transition
      }}
      draggable={false}
    />
  );
};

export default Thumbnail;
