import React from 'react';

const UserOffIcon: React.FC = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className="icon"
    >
      <path
        d="M6 20c0-3 4-5 6-5s6 2 6 5"
        stroke="currentColor"
        strokeWidth="2"
        className="user_off_line"
        strokeDasharray="30"
        strokeDashoffset="30"
      />
      <circle
        cx="12"
        cy="8"
        r="4"
        stroke="currentColor"
        strokeWidth="2"
        className="user_off_circle"
        strokeDasharray="30"
        strokeDashoffset="30"
      />
      <style>
        {`
          .user_off_circle {
            fill: transparent;
            stroke-dashoffset: 0;
          }

          .user_off_line {
            fill: transparent;
            stroke-dashoffset: 0;
          }
        `}
      </style>
    </svg>
  );
};

export default UserOffIcon;
