import React from 'react';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import dayjsHelper from '../../../../common/helpers/dayjsHelper';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import MessageBar from '../../../controls/MessageBar/MessageBar';

interface SubscriptionStatusProps {
  team: TeamDTO;
}

const SubscriptionStatus: React.FC<SubscriptionStatusProps> = ({ team }) => {
  const { t } = useTranslation('home');

  const trialEndsAt = new Date(team.subscription.trialEndsAt);
  const daysLeft = trialEndsAt
    ? dayjsHelper(trialEndsAt).diff(dayjsHelper(), 'day')
    : 0;

  if (
    team.subscription.trialEndsAt &&
    !team.subscription.hasPaymentMethods &&
    daysLeft > 0
  ) {
    return (
      <div className="flex-row fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="warning"
            icon="fa-exclamation-triangle"
            rightContent={
              <NavLink
                to={`/team/${team.id}/plan-and-billing`}
                className={'secondary-button translucent no-wrap'}
              >
                {t('addPaymentMethod')}
              </NavLink>
            }
          >
            <Trans
              i18nKey="home:trialEndsInDays"
              values={{
                teamName: team.name || '',
                count: daysLeft,
              }}
              components={{ strong: <strong /> }}
            />
          </MessageBar>
        </div>
      </div>
    );
  }

  if (team.subscription.deleteAt && team.owned) {
    return (
      <div className="flex-row pt-0 fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="danger"
            icon="fa-exclamation-circle"
            rightContent={
              <NavLink
                to={`/team/${team.id}/plan-and-billing`}
                className={'secondary-button translucent no-wrap'}
              >
                {t('renewPlan')}
              </NavLink>
            }
          >
            {t('planExpired', {
              teamName: team.name,
              deleteAt: dayjsHelper(team.subscription.deleteAt).format(
                'MMMM Do, YYYY',
              ),
            })}
          </MessageBar>
        </div>
      </div>
    );
  }

  if (team.subscription.cancelAt && team.owned) {
    return (
      <div className="flex-row pt-0 fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="warning"
            icon="fa-exclamation-circle"
            rightContent={
              <NavLink
                to={`/team/${team.id}/plan-and-billing`}
                className={'secondary-button translucent no-wrap'}
              >
                {t('reactivate')}
              </NavLink>
            }
          >
            {t('planExpires', {
              teamName: team.name,
              cancelAt: dayjsHelper(team.subscription.cancelAt).format(
                'MMMM Do, YYYY',
              ),
            })}
          </MessageBar>
        </div>
      </div>
    );
  }

  if (team.subscription.deleteAt && !team.owned) {
    return (
      <div className="flex-row pt-0 fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="danger"
            icon="fa-exclamation-circle"
          >
            {t('unownedTeamGracePeriod', {
              teamName: team.name,
              deleteAt: dayjsHelper(team.subscription.deleteAt).format(
                'MMMM Do, YYYY',
              ),
            })}
          </MessageBar>
        </div>
      </div>
    );
  }

  return null;
};

export default SubscriptionStatus;
