import React from 'react';

interface MessageBarProps {
  type: 'info' | 'success' | 'danger' | 'warning';
  icon: string;
  children: React.ReactNode;
  extraClasses?: string;
  rightContent?: React.ReactNode; // Add rightContent prop
}

const MessageBar: React.FC<MessageBarProps> = ({
  type,
  icon,
  children,
  rightContent,
  extraClasses = '',
}) => {
  const typeClass = {
    info: 'message-bar-info',
    success: 'message-bar-success',
    danger: 'message-bar-danger',
    warning: 'message-bar-warning',
  }[type];

  return (
    <>
      <div
        className={`message-bar-component card ${typeClass} ${extraClasses}`}
      >
        <div className="content">
          <p className="mb-0 flex-v-start">
            <span className={`message-icon fas px-xs pt-3xs ${icon}`}></span>
            <span className="message-text">{children}</span>
          </p>
          {rightContent && <div className="right-content">{rightContent}</div>}
        </div>
      </div>
    </>
  );
};

export default MessageBar;
