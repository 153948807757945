import React from 'react';
import RequestStatus from '../../partials/RequestStatus/RequestStatus';

interface Props {
  onClick?:
    | (() => void)
    | ((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
  onBlur?: (() => void) | ((ev: React.FocusEvent<HTMLElement>) => void);
  className: string;
  disabled?: boolean;
  type?: 'submit' | 'button';
  id?: string;
  tabIndex?: number;
  value?: string;
  higherRef?: React.RefObject<HTMLButtonElement>;
  styles?: React.CSSProperties;
  title?: string;
  status?: 'loading' | 'success' | 'error' | 'idle';
  idleIcon?: React.ReactNode;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
}

const Button: React.FC<Props> = ({
  onClick,
  onBlur,
  className,
  disabled,
  type = 'button', // default value
  id,
  tabIndex,
  value,
  higherRef,
  styles,
  title,
  status = 'idle', // default value
  idleIcon,
  onKeyDown,
  children,
}) => {
  return (
    <>
      <button
        title={title}
        className={className}
        id={id}
        onClick={onClick}
        onBlur={onBlur}
        disabled={disabled}
        type={type}
        tabIndex={tabIndex}
        value={value}
        ref={higherRef}
        style={styles}
        onKeyDown={onKeyDown}
      >
        {status === 'idle' ? (
          idleIcon && <span className={`icon ${idleIcon}`} />
        ) : (
          <RequestStatus status={status} />
        )}
        {children}
      </button>
    </>
  );
};

export default Button;
